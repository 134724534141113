import ApiExtramuralCtpStatement from 'ApiRest/Api/Account/Student/ExtramuralCtpStatement'
import ApiExtramuralCtpStatementAcademicYears from 'ApiRest/Api/Account/Student/ExtramuralCtpStatement/AcademicYears'
import ApiExtramuralCtpStatementLessons from 'ApiRest/Api/Account/Student/ExtramuralCtpStatement/Lessons'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchStatements({ commit }, payload) {
    const { data } = await ApiExtramuralCtpStatement.get(payload)

    commit('setStatements', data)
  },

  async fetchPeriodList({ commit }) {
    const { data } = await ApiExtramuralCtpStatementAcademicYears.get()

    commit('setPeriodList', data)
  },

  async fetchTheStatement({ commit }, payload) {
    const params = {
      ...payload,
      statementTests: true,
    }

    const { data } = await ApiExtramuralCtpStatementLessons.get(params)

    commit('setTheStatement', data)
  },
}
