<template>
  <LoaderFullScreen v-if="isLoading" />

  <LayoutBase
    v-else
    :is-loading-switch-student="isLoadingSwitchStudent"
    :main="main"
    :noticed="isCurrentStudentDemo"
  >
    <template #header>
      <TheHeaderClient
        v-if="isRendered.header"
        :abbreviation="nameAbbreviation"
        :avatar="avatar"
        :current-student="currentStudent"
        :full-name="fullNameWithShortLastName"
        :has-contracts-or-drafts="hasContracts"
        :has-enrolled-students="hasEnrolledStudents"
        :links="getLinks"
        :notice-additional-message="getCurrentStudentSchoolDemoMessage"
        :notice-text="demoAccessTimeText"
        :rating="studentRating"
        :credit="studentCredit"
        :school-name="schoolName"
        :students="enrolledStudents"
        @select-student="currentStudentChange"
      />

      <RateUs
        :profile="profile"
        :education-type="educationType"
        @change-frame-state="onChangeFrameRateUsState"
      />
    </template>

    <template #aside>
      <TheAside
        v-if="hasLinks"
        :links="getLinks"
        :user-settings="userSettings"
        @toggle-aside="toggleAside"
      />
    </template>

    <template #tabs-nav>
      <TabsNav
        :current-tab="currentTab"
        :tabs="mainTabsFiltered"
        @change="onChangeTabNav"
      />
    </template>

    <router-view v-slot="{ Component }" v-on="listeners">
      <transition mode="out-in" name="fade">
        <component :is="Component" />
      </transition>
    </router-view>

    <template #footer>
      <TheFooter :school="school" :user-id="userId" />
    </template>

    <template #bottom-notification>
      <BlockChooseEducationForm ref="BlockChooseEducationForm" />
    </template>

    <BtnInfoClient
      :is-btn-info-shifted="isBtnInfoShifted"
      @click="onClickBtnInfo"
    />

    <BtnUp @visibility-changed="onVisibilityChanged" />

    <ModalProfileUpdate
      ref="modalProfileUpdateDemoFeSimple"
      :student="profileUpdate.student"
      :form="demoFeSimpleForm"
      :set-form-prop="setDemoFeSimpleFormProp"
      :fetch-form="fetchDemoFeSimpleForm"
      :update-form="updateDemoFeSimpleForm"
      @success="onSuccessModalProfileUpdate"
    />

    <ModalWelcome ref="modalWelcome" />

    <ModalProductAd
      ref="modalProductAd"
      :ad="productAdModalData"
      @click:btn-buy="onClickBtnBuy"
      @click:btn-show-all-offers="onClickBtnShowAllOffers"
      @click:btn-not-interested="onClickBtnNotInterested"
    />

    <ModalConfirmEmail
      ref="modalConfirmEmail"
      :email="email"
      @send-confirm-email="onSendConfirmEmail"
    />
  </LayoutBase>
</template>

<script>
import ModalWelcome from '@/components/client/contract/ModalWelcome.vue'
import BlockChooseEducationForm from '@/components/client/main/BlockChooseEducationForm.vue'
import BtnInfoClient from '@/components/client/main/BtnInfoClient.vue'
import ModalProductAd from '@/components/client/productAd/ModalProductAd.vue'
import LoaderFullScreen from '@/components/common/LoaderFullScreen.vue'
import ModalConfirmEmail from '@/components/common/ModalConfirmEmail.vue'
import ModalProfileUpdate from '@/components/common/ModalProfileUpdate.vue'
import RateUs from '@/components/common/RateUs.vue'
import TabsNav from '@/components/common/TabsNav.vue'
import { useModalNotifyTimezone } from '@/composables/useModalNotifyTimezone'
import { ErrorHelper } from '@/helpers/ErrorHelper'
import { isMobileDomain } from '@/helpers/domain'
import getEstimatedTime from '@/helpers/getEstimatedTime'
import { getShowcaseDomain } from '@/helpers/getShowcaseDomain'
import { mainTabsClient } from '@/helpers/mainTabs'
import { showToastPersistent } from '@/helpers/toast'
import TheAside from '@/layouts/common/TheAside.vue'
import TheFooter from '@/layouts/common/TheFooter.vue'
import TheHeaderClient from '@/layouts/common/TheHeaderClient.vue'
import LayoutBase from '@/layouts/common/main/LayoutBase.vue'
import BtnUp from '@/ui/BtnUp.vue'
import { keyBy, pick, values } from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

const MOBILE_WIDTH = 991
const INTERVAL_TIME = 60000 // 1min

export default {
  name: 'ClientMain',

  components: {
    RateUs,
    ModalConfirmEmail,
    ModalProductAd,
    ModalWelcome,
    ModalProfileUpdate,
    BtnUp,
    BtnInfoClient,
    LayoutBase,
    LoaderFullScreen,
    TabsNav,
    TheAside,
    TheFooter,
    TheHeaderClient,
    BlockChooseEducationForm,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => vm.fetchActiveModules())
  },

  setup() {
    const modalNotifyTimezone = useModalNotifyTimezone()

    return {
      modalNotifyTimezone,
    }
  },

  data() {
    return {
      main: false,
      isLoading: false,
      demoAccessTimeText: '',
      demoTimer: null,
      demoTimerSecondsLeft: 0,
      isBtnInfoShifted: false,
      isFrameRateUsOpened: false,

      profileUpdate: {
        student: null,
      },
    }
  },

  computed: {
    ...mapState('client/main', [
      'currentTab',
    ]),

    ...mapState('client/profile', [
      'profile',
      'userSettings',
    ]),

    ...mapState('client/students', [
      'currentStudent',
      'isLoadingSwitchStudent',
      'school',
    ]),

    ...mapState('client/invoices', [
      'hasFamilyInvoice',
    ]),

    ...mapState('client/demoFeSimple', {
      demoFeSimpleForm: 'form',
    }),

    ...mapState('common/productAd', {
      productAdModalData: 'modalData',
      productAdPurchase: 'purchase',
    }),

    ...mapGetters('client/main', [
      'getLinks',
    ]),

    ...mapGetters('client/profile', [
      'fullNameWithShortLastName',
      'nameAbbreviation',
      'isAvailableAnyCommercialOffer',
    ]),

    ...mapGetters('client/students', [
      'hasStudentJustPaidContractFe',
      'hasStudentJustPaidContractDemoFeSimple',
      'studentJustPaidContractFe',
      'studentJustPaidContractDemoFeSimple',
    ]),

    ...mapGetters('courses/client/notifications', [
      'hasAwaitingDocuments',
      'awaitingDocuments',
    ]),

    ...mapGetters('client/students', [
      'educationType',
      'hasEducationType',
      'enrolledStudents',
      'getCurrentStudentDemoAccessTime',
      'getCurrentStudentSchoolDemoMessage',
      'hasEnrolledStudents',
      'isCurrentStudentDemo',
      'isStudentEnrolledAndHasDiagnostics',
    ]),

    ...mapGetters('client/contracts', [
      'hasContracts',
    ]),

    ...mapGetters('common/module', [
      'hasModules',
    ]),

    ...mapGetters('onlineSchool/common/tariff', [
      'hasCurrentStudentOnlineSchoolOrder',
    ]),

    userId() {
      return this.profile.id
    },

    email() {
      return this.profile.email
    },

    avatar() {
      return this.profile.photo?.thumbs?.small
    },

    schoolName() {
      return this.school.name
    },

    isMobile() {
      return window.innerWidth < MOBILE_WIDTH
    },

    isRendered() {
      return {
        header: !(this.isMobile && this.isFrameRateUsOpened),
      }
    },

    hasLinks() {
      return this.getLinks.length > 0
    },

    studentRating() {
      return this.currentStudent?.rating
    },

    studentCredit() {
      return this.currentStudent?.credit
    },

    mainTabsFiltered() {
      return keyBy(
        values(mainTabsClient).filter(
          (tab) =>
            tab.educationTypes?.includes(
              this.currentStudent?.educationType?.id,
            ) ?? true,
        ),
        'id',
      )
    },

    routeToCoursesPurchase() {
      return {
        name: 'client-courses-the-purchase',
        params: {
          purchaseId: this.productAdPurchase?.id,
        },
      }
    },

    listeners() {
      return {
        'contract-created': async () => {
          await this.fetchProfile()
          await this.fetchStudents()
          await this.checkProductAd()
        },
      }
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        const mainPaths = [
          'client-main',
        ]

        this.main = mainPaths.some((path) => this.$route.name === path)
      },
    },

    currentStudent() {
      if (this.isCurrentStudentDemo) {
        this.setTimerForDemoText()
      } else {
        this.releaseTimerForDemoText()
      }
    },
  },

  created() {
    this.init()
  },

  beforeUnmount() {
    this.stopPollingMessages()
  },

  methods: {
    ...mapActions({
      // beforeRouteEnter
      // eslint-disable-next-line
      fetchActiveModules: 'common/module/fetchActiveModules',
      fetchServerTimestamp: 'helper/fetchServerTimestamp',
      fetchCommonNotifications: 'common/notifications/fetchNotifications',
      fetchNotifications: 'client/profile/fetchNotifications',
      fetchNotificationDocuments:
        'courses/client/notifications/fetchNotificationDocuments',
      startPollingMessages: 'common/messages/startPollingMessages',
      stopPollingMessages: 'common/messages/stopPollingMessages',
      fetchUserSettings: 'client/profile/fetchUserSettings',
      updateUserSettings: 'client/profile/updateUserSettings',
      fetchSchoolPlatform: 'client/profile/fetchSchoolPlatform',
      fetchProfile: 'client/profile/fetchProfile',
      fetchStudents: 'client/students/fetchStudents',
      fetchTheStudent: 'client/students/fetchTheStudent',
      fetchContracts: 'client/contracts/fetchContracts',
      fetchDrafts: 'client/contracts/fetchDrafts',
      fetchInvoices: 'client/invoices/fetchInvoices',
      checkFamilyInvoice: 'client/invoices/checkFamilyInvoice',
      fetchPromoCampaigns: 'client/promoCampaign/fetchPromoCampaigns',
      fetchDemoFeSimpleForm: 'client/demoFeSimple/fetchForm',
      updateDemoFeSimpleForm: 'client/demoFeSimple/updateForm',
      fetchProductAdModalData: 'common/productAd/fetchModalData',
      sendProductAdOrder: 'common/productAd/sendOrder',
      logProductAdClickBtnShowAllOffers:
        'common/productAd/logClickBtnShowAllOffers',
      logProductAdClickBtnNotInterested:
        'common/productAd/logClickBtnNotInterested',
      sendConfirmEmail: 'auth/registration/sendConfirmEmail',
      addBottomNotification: 'client/notifications/addBottomNotification',
      fetchOnlineSchoolStudentList: 'onlineSchool/common/tariff/fetchStudents',
    }),

    ...mapMutations({
      setCurrentTab: 'client/main/setCurrentTab',
      setIsLoadingSwitchStudent: 'client/students/setIsLoadingSwitchStudent',
      setDemoFeSimpleFormProp: 'client/demoFeSimple/setFormProp',
    }),

    async init() {
      this.isLoading = true

      try {
        const requests = []

        requests.push(
          this.fetchServerTimestamp(),
          this.fetchUserSettings(),
          this.fetchStudents(),
          this.fetchInvoices(),

          this.fetchCommonNotifications(),
          this.fetchNotifications(),
          this.fetchSchoolPlatform(),
          this.fetchOnlineSchoolStudentList(),

          this.fetchServerTimestamp(),
        )

        if (this.$route.name !== 'client-contracts') {
          requests.push(this.fetchContracts(), this.fetchDrafts())
        }

        if (
          this.hasModules([
            'educational-courses',
          ])
        ) {
          requests.push(this.fetchNotificationDocuments())
        }

        await Promise.allSettled(requests)

        if (this.isAvailableAnyCommercialOffer) {
          await this.fetchProductAdModalData()

          if (this.currentStudent?.id) {
            await this.fetchPromoCampaigns()
          }
        }

        if (this.hasAwaitingDocuments) {
          showToastPersistent(this.awaitingDocuments, 'info')
        }

        if (
          this.hasModules([
            'chat',
          ])
        ) {
          await this.startPollingMessages()
        }

        if (
          !this.hasEnrolledStudents &&
          !this.educationType.fullTime &&
          !isMobileDomain
        ) {
          if (this.$route.name === 'client-main') {
            if (
              this.hasModules([
                'online-school',
              ]) &&
              !this.hasContracts
            ) {
              await this.$router.replace({
                name: 'client-tariffs',
              })
            } else {
              if (this.hasContracts) {
                await this.$router.replace({
                  name: 'client-contracts',
                })
              } else {
                await this.$router.replace({
                  name: 'client-request-new',
                })
              }
            }
          }
        }

        if (
          this.isStudentEnrolledAndHasDiagnostics &&
          this.$route.name === 'client-main' &&
          !isMobileDomain
        ) {
          await this.$router.replace({
            name: 'client-contracts',
          })
        }

        if (
          this.$route.name !== 'client-contracts' &&
          this.hasCurrentStudentOnlineSchoolOrder(this.currentStudent?.id) &&
          !this.hasEducationType
        ) {
          requests.push(
            this.addBottomNotification({
              blockName: 'BlockChooseEducationForm',
            }),
          )
        }
      } finally {
        this.isLoading = false
      }

      await this.checkIsEmailConfirmed()
      await this.checkProductAd()
      await this.checkJustPaidContract()

      this.modalNotifyTimezone.init()
    },

    async checkProductAd() {
      if (
        this.isAvailableAnyCommercialOffer &&
        this.profile.showModalProductAd &&
        !!this.productAdModalData
      ) {
        await this.$nextTick()

        this.$refs.modalProductAd.show()
      }
    },

    async checkIsEmailConfirmed() {
      if (!this.profile.isEmailConfirmed) {
        await this.checkFamilyInvoice()

        if (this.hasFamilyInvoice) {
          await this.$nextTick()

          this.$refs.modalConfirmEmail.show()
        }
      }
    },

    async checkJustPaidContract() {
      if (
        this.hasStudentJustPaidContractFe ||
        this.hasStudentJustPaidContractDemoFeSimple
      ) {
        switch (true) {
          case this.hasStudentJustPaidContractFe:
            this.profileUpdate.student = this.studentJustPaidContractFe
            break

          case this.hasStudentJustPaidContractDemoFeSimple:
            this.profileUpdate.student =
              this.studentJustPaidContractDemoFeSimple
            break

          default:
        }

        await this.$nextTick()

        this.$refs.modalProfileUpdateDemoFeSimple.show()
      }
    },

    getTextForDemo() {
      this.demoTimerSecondsLeft -= INTERVAL_TIME / 1000

      const estimatedTime = getEstimatedTime(this.demoTimerSecondsLeft)

      // TODO: i18n
      this.demoAccessTimeText = estimatedTime
        ? `Демо-доступ завершится через ${estimatedTime}.`
        : `Демо-доступ завершился.`
    },

    setTimerForDemoText() {
      this.demoTimerSecondsLeft = this.getCurrentStudentDemoAccessTime
      this.getTextForDemo()
      this.demoTimer = setInterval(this.getTextForDemo, INTERVAL_TIME)
    },

    releaseTimerForDemoText() {
      clearInterval(this.demoTimer)
      this.demoAccessTimeText = ''
      this.demoTimer = null
    },

    toggleAside(isAsideOpen) {
      this.updateUserSettings({
        isAsideOpen,
      })
    },

    async currentStudentChange(studentId) {
      this.setIsLoadingSwitchStudent(true)
      try {
        await this.fetchTheStudent(studentId)

        if (this.isAvailableAnyCommercialOffer) {
          await this.fetchPromoCampaigns()
        }
      } finally {
        this.setIsLoadingSwitchStudent(false)
      }
    },

    async onSendConfirmEmail() {
      try {
        await this.sendConfirmEmail(this.email)
      } catch (error) {
        new ErrorHelper(error).processError()
      }

      return true
    },

    onChangeTabNav(tabId) {
      this.setCurrentTab(tabId)
    },

    onChangeFrameRateUsState(isFrameOpened) {
      this.isFrameRateUsOpened = isFrameOpened
    },

    onSuccessModalProfileUpdate() {
      this.$refs.modalWelcome.show()
    },

    /**
     * @param {Object} payload
     * @param {Object} payload.ad
     * @param {Object[]} payload.products
     * @returns {Promise<void>}
     */
    async onClickBtnBuy(payload) {
      try {
        await this.sendProductAdOrder({
          productAdId: payload.ad.id,
          offers: payload.products.map((product) => pick(product.offer, 'id')),
        })

        this.$refs.modalProductAd.hide()

        if (payload.ad.isShowcase) {
          const url = `${getShowcaseDomain()}/client/purchases-ad/${this.productAdPurchase.id}`

          window.open(url, '_blank').focus()
        } else {
          await this.$router.push(this.routeToCoursesPurchase)
        }
      } catch (error) {
        new ErrorHelper(error).processError()
      }
    },

    async onClickBtnShowAllOffers() {
      await this.logProductAdClickBtnShowAllOffers()
      this.$refs.modalProductAd.hide()
    },

    async onClickBtnNotInterested() {
      await this.logProductAdClickBtnNotInterested()
      this.$refs.modalProductAd.hide()
    },

    onVisibilityChanged(value) {
      this.isBtnInfoShifted = value
    },

    onClickBtnInfo(blockName) {
      this.$refs[blockName].show()
    },
  },
}
</script>
