import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Список домашних заданий
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/student/ctp/homework-solution')
  }
})()
