import ApiTheFullTimeCtpStatement from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement'
import ApiAcademicYears from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement/AcademicYear/List'
import ApiFullTimeCtpStatements from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement/List'

export default {
  async fetchFullTimeCtpStatements({ commit, state, rootGetters }) {
    const params = {
      academicYearPreset: {
        id: state.theAcademicYear?.id,
      },
    }

    if (
      rootGetters['common/module/hasModules'](
        'intramural-student-extended-statement',
      )
    ) {
      params.includeExtraData = 1
    }

    const { data } = await ApiFullTimeCtpStatements.get(
      rootGetters['client/students/currentStudentId'],
      params,
    )

    commit('setFullTimeCtpStatements', data)
  },

  async fetchTheFullTimeCtpStatement({ commit, rootGetters }, { groupId }) {
    const parameters = {
      statementTests: true,
    }

    const { data } = await ApiTheFullTimeCtpStatement.get(
      rootGetters['client/students/currentStudentId'],
      groupId,
      parameters,
    )

    commit('setTheFullTimeCtpStatement', data)
  },

  async fetchAcademicYears({ commit, rootGetters }) {
    const { data } = await ApiAcademicYears.get(
      rootGetters['client/students/currentStudentId'],
    )

    commit('setAcademicYears', data)
  },
}
