import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementEventsTelegram = new (class extends ApiBase {
  /**
   * Привязка Telegram
   * @returns {Promise<?>}
   */
  get() {
    const url = `/product/telegram/link`

    return this._GET(url)
  }

  /**
   * Отвязка Telegram
   * @returns {Promise<?>}
   */
  delete() {
    const url = `/product/telegram/unlink`

    return this._DELETE(url)
  }
})()
