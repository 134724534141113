import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseProductOffers = new (class extends ApiBase {
  /**
   * @param {number} productId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(productId, parameters = {}) {
    const urlParams = UrlHelper.param(parameters)
    const url = `/showcase/product/${productId}/offers?${urlParams}`

    return this._GET(url)
  }
})()
