export default {
  statusData(state) {
    return {
      clientId: state.invoice?.customerId ?? null,
      step: 'Оформление договора',

      clientFirstname: state.form.clientFirstName,
      clientLastName: state.form.clientLastName,
      clientPhone: state.form.phone,
      clientEmail: state.form.clientEmail,

      studentFirstname: state.form.studentFirstName,
      studentLastname: state.form.studentLastName,
      studentEmail: state.form.studentEmail,

      requestGrade: state.form.grade?.id ?? null,
      invoiceId: state.invoice?.id ?? null,
      requestEducationTypeId: 3,
      requestSchoolId: 1,
    }
  },
}
