import { categorySymbolicNames } from '@/consts/common/showcase/categorySymbolicNames'
import { ApiShowcaseCategories } from 'ApiRest/Api/Showcase/Categories'
import { ApiShowcaseCategoriesLite } from 'ApiRest/Api/Showcase/CategoriesLite'
import { ApiShowcaseUpperCategories } from 'ApiRest/Api/Showcase/UpperCategories'

export default {
  async fetchCategories({ commit }) {
    const { data } = await ApiShowcaseCategories.get()

    commit('setCategories', data)
  },

  async fetchUpperCategories({ commit }) {
    const { data } = await ApiShowcaseUpperCategories.get()

    commit('setUpperCategories', data)
  },

  async fetchCategoriesLite({ commit }) {
    const { data } = await ApiShowcaseCategoriesLite.get({
      symbolicNames: [
        categorySymbolicNames.ONLINE_SCHOOL,
      ],
    })

    commit('setCategoriesLite', data)
  },
}
