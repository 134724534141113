import { StoreUserShowcase } from '@/StoreUserShowcase'
import { clientEducationRoutes } from '@/routes/showcase/client/clientEducationRoutes'
import { clientMainRoutes } from '@/routes/showcase/client/clientMainRoutes'
import { clientShowcaseRoutes } from '@/routes/showcase/client/clientShowcaseRoutes'
import { clientSupportRoutes } from '@/routes/showcase/client/clientSupportRoutes'
import { clientTestRoutes } from '@/routes/showcase/client/clientTestRoutes'

const checkRole = (to, from, next) => {
  if (StoreUserShowcase.store.getters['common/user/isClient']) {
    next()
  } else {
    next({
      name: 'guest-showcase',
    })
  }
}

export const clientRoutes = [
  {
    path: '/client',
    component: () => import('@/layouts/showcase/ShowcaseClientMain.vue'),
    meta: {
      isDisabledScroll: true,
    },

    children: [
      ...clientMainRoutes,
      ...clientShowcaseRoutes,
      ...clientSupportRoutes,
      ...clientEducationRoutes,
    ],
  },

  ...clientTestRoutes,

  {
    path: '/client/online-lessons/:onlineLessonId(\\d+)/video/:videoId(\\d+)',
    name: 'client-showcase-the-online-lesson-preview',
    component: () =>
      import('@/pages/showcase/common/TheLessonOnlinePreview.vue'),
  },
]

clientRoutes.forEach((route) => {
  route.beforeEnter = checkRole
})
