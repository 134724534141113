import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolPurchaseGenerateInvoices =
  new (class extends ApiBase {
    /**
     * Обновление данных клиента в заказе
     * @param {number} purchaseId
     * @returns {Promise}
     */
    put(purchaseId) {
      const url = `/online-school/purchase/${purchaseId}/generate-invoices`

      return this._PUT(url)
    }
  })()
