import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentQuestionTest = new (class extends ApiBase {
  /**
   * @param {Object} obj
   * @param {number} obj.questionId
   * @param {TestContextPayload} obj.context
   * @returns {Promise}
   */
  get({ questionId, context }) {
    let url = `/account/student/test/question/${questionId}/testing`

    if (context) {
      url += `/${context.typeId}/${context.objectId}`
    }

    return this._GET(url)
  }
})()
