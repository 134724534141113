import ApiBase from 'ApiRest/ApiBase'

export default new (class Retry extends ApiBase {
  /**
   * Сбросить Ответ
   * @param {number} answerId
   * @returns {Promise}
   */
  put(answerId) {
    return this._PUT(`/account/student/test/answers/${answerId}/retry`)
  }
})()
