import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountStudentDiagnosticCtpTestings =
  new (class extends ApiBase {
    /**
     * Получения testing при прохождении теста в ЛК ученика
     * @param {number} ctpId
     * @returns {Promise}
     */
    get(ctpId) {
      const url = `/account/student/diagnostics/ctp-tests/${ctpId}/testings`

      return this._GET(url)
    }
  })()
