<template>
  <ElModal v-bind="$attrs" :name="name" :width="500">
    <div class="vm--modal-content">
      <!-- TODO: i18n -->
      <h3 class="h3">Приветствуем вас на платформе IBLS!</h3>

      <!-- TODO: i18n -->
      <div class="mb-3 text-gray">
        Сейчас вы находитесь в личном кабинете клиента. Для начала обучения
        перейдите в личный кабинет ученика – это можно сделать, нажав на кнопку,
        расположенную на верхней синей панели. В кабинете ученика вы найдете
        видеоуроки, тесты и демо-версии контрольных работ.
      </div>

      <!-- TODO: i18n -->
      <div class="mb-5 text-gray">
        Из личного кабинета клиента можно отслеживать успеваемость обучающегося,
        а также подать заявление на промежуточную аттестацию. Данные для входа в
        личный кабинет ученика были отправлены на его электронную почту,
        указанную вами при регистрации.
      </div>

      <div>
        <!-- TODO: i18n -->
        <ElButton
          class="btn-primary"
          label="Приступить к обучению"
          @click="onClickBtnStartSchool"
        />
      </div>
    </div>
  </ElModal>
</template>

<script>
import ElButton from '@/ui/ElButton.vue'
import ElModal from '@/ui/ElModal.vue'
import { useVfm } from 'vue-final-modal'

export default {
  name: 'ModalWelcome',

  components: {
    ElModal,
    ElButton,
  },

  inheritAttrs: false,

  setup() {
    const vfm = useVfm()

    return {
      vfm,
    }
  },

  data() {
    return {
      name: 'modalContractPaymentSuccessWelcome',
    }
  },

  methods: {
    onClickBtnStartSchool() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.vfm.open(this.name)
    },

    /**
     * @public
     */
    hide() {
      this.vfm.close(this.name)
    },
  },
}
</script>
