import ApiBase from 'ApiRest/ApiBase'

export const ApiDemoFeSimpleRequestForm = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/auth/demo-fe-student-request-form')
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/auth/demo-fe-student-request-form', data)
  }
})()
