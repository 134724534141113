import ApiBase from 'ApiRest/ApiBase'

/**
 * @typedef {{
 * chatId:number,
 * clientCode:number,
 * }} EmailPayloadI
 */

export default new (class extends ApiBase {
  /**
   * Подписаться на Email-уведомления.
   * @param {number} mailId
   * @returns {Promise}
   */
  post(mailId) {
    const url = `/notification/email`
    const data = {
      options: {
        mailId,
      },
    }

    return this._POST(url, data)
  }

  /**
   * Получить информацию о состоянии Email-уведомлений.
   * @returns {Promise<NotificationChannelI<EmailPayloadI>>}
   */
  get() {
    const url = `/notification/email`

    return this._GET(url)
  }

  /**
   * Отписаться от Email-уведомлений.
   * @returns {Promise}
   */
  delete() {
    const url = `/notification/email`

    return this._DELETE(url)
  }
})()
