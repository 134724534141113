import { stringify } from 'qs'

export default class UrlHelper {
  /**
   * @param {{}} params
   * @returns {string}
   */
  static param(params) {
    return stringify(params, {
      encoder(str, defaultEncoder, charset, type) {
        const encodedValue = defaultEncoder(str)

        if (type === 'value') {
          switch (str) {
            case true:
              return 1

            case false:
              return 0

            default:
              return encodedValue
          }
        }

        return encodedValue
      },
    })
  }
}
