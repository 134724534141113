export default {
  resetState({ commit }) {
    commit('resetState')
  },

  addBottomNotification({ commit }, notification) {
    commit('addBottomNotification', notification)
  },

  popBottomNotification({ state, commit }) {
    const lastNotification =
      state.bottomNotifications[state.bottomNotifications.length - 1]

    commit('popBottomNotification')

    return lastNotification
  },
}
