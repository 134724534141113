import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseProduct = new (class extends ApiBase {
  /**
   * @param {Object} obj
   * @param {string} obj.category
   * @param {string} obj.product
   * @param {Object} obj.parameters
   * @returns {Promise}
   */
  get({ category, product, parameters }) {
    const url = `/showcase/products/${category}/${product}?${this.getUrlParams(parameters)}`

    return this._GET(url)
  }
})()
