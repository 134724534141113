import { educontSchoolId } from '@/helpers/const/AppData'

export default {
  isDemoAllowed(state) {
    return Boolean(state.schoolPlatform?.isDemoAllowed)
  },

  isDemoAllowedFe(state) {
    return Boolean(state.schoolPlatform?.isDemoAllowedFe)
  },

  isSchoolPlatformEducont(state) {
    return state.schoolPlatform?.id === educontSchoolId
  },
}
