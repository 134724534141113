export const clientSubjectRoutes = [
  {
    path: 'subjects',
    name: 'client-showcase-subjects',
    component: () => import('@/pages/showcase/common/subject/Subjects.vue'),
  },

  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)',
    name: 'client-showcase-subject',
    component: () => import('@/pages/showcase/common/subject/Subject.vue'),
  },

  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)/bibliography',
    name: 'client-showcase-subject-bibliography',
    component: () =>
      import('@/pages/showcase/common/subject/SubjectBibliography.vue'),
  },

  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)/lesson/:lessonId(\\d+)/:homeworkSolutionId(\\d+)?',
    name: 'client-showcase-subject-lesson',
    component: () => import('@/pages/showcase/common/subject/Lesson.vue'),
  },
]
