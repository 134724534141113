import ApiBase from 'ApiRest/ApiBase'

export const ApiTriggerNotification = new (class extends ApiBase {
  /**
   * Триггер перехода по ссылке
   * @param {number} notificationId
   * @returns {Promise}
   */
  post(notificationId) {
    return this._POST(`/trigger-notification/visit-link/${notificationId}`)
  }
})()
