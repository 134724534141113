import { getCookie } from '@/helpers/cookie'
import { getLocaleMessagesShowcase } from '@/helpers/getLocaleMessages'
import { i18n } from '@/i18n/setup'
import ApiOption from 'ApiRest/Api/Option'

export default {
  async fetchLangOptions({ commit }) {
    const { data } = await ApiOption.getLanguages()

    commit('setLangOptions', data)
  },

  setLangDefault({ commit }) {
    const lang = getCookie('lang') || 'ru'

    commit('setLang', lang)
  },

  resetLangOfHtml({ state }) {
    document.querySelector('html').setAttribute('lang', state.lang)
  },

  resetLocale({ getters, dispatch }) {
    i18n.global.locale = getters.localeName
    i18n.global.fallbackLocale = getters.localesFallback

    dispatch('resetLangOfHtml')
  },

  async changeLocale({ state, getters, commit, dispatch }) {
    if (!getters.isLoadedLocale) {
      for await (const locale of getters.localesFallback) {
        const messages = await getLocaleMessagesShowcase({
          lang: locale,
        })

        i18n.global.setLocaleMessage(locale, messages)

        commit('setLocalesLoaded', [
          ...state.localesLoaded,
          locale,
        ])
      }
    }

    dispatch('resetLocale')
  },
}
