<template>
  <button class="btn" :class="$style.btn" type="button">
    <span
      v-if="props.isLoading"
      class="spinner-border spinner-border-sm mr-2"
      role="status"
      aria-hidden="true"
    ></span>

    <span v-if="props.label">{{ props.label }}</span>

    <slot></slot>
  </button>
</template>

<script setup lang="ts">
defineOptions({
  name: 'ElButton',
})

const props = defineProps({
  label: {
    type: String,
    default: null,
  },

  isLoading: {
    type: Boolean,
    default: false,
  },
})
</script>

<style module lang="scss">
.btn:disabled {
  pointer-events: none;
}
</style>
