<template>
  <div :class="$style.promoBlock">
    <header class="b-header">
      <h2 class="h1">{{ t('header') }}</h2>
    </header>

    <div
      class="d-flex flex-wrap justify-content-sm-start justify-content-center"
    >
      <div class="product d-flex flex-wrap mr-0 mb-0 mb-md-4 mr-sm-4">
        <Product
          v-for="product in promoProducts"
          :key="product.id"
          :product="product"
        />
      </div>

      <div>
        <div
          class="mb-3 mb-sm-0 h-100 w-100 d-flex flex-column align-items-center justify-content-center"
        >
          <ElButton class="h-auto" @click="onClickBtnLogClicksPromoMaterials">
            <img
              class="d-block mx-auto mb-2 iconShowMore"
              :src="icons.showMore"
              :alt="t('show_more')"
            />

            <span class="font-weight-medium font-size-16">
              {{ t('show_more') }}
            </span>
          </ElButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from '@/components/common/promo/Product.vue'
import { promoActionsClick } from '@/helpers/promoActions'
import iconShowMore from '@/static/images/common-lk/icons/show-more.svg'
import ElButton from '@/ui/ElButton.vue'
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  name: 'PromoBlock',

  components: {
    ElButton,
    Product,
  },

  props: {
    promoProducts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      icons: {
        showMore: iconShowMore,
      },
    }
  },

  computed: {
    ...mapGetters('common/user', [
      'getRouteNameOnUser',
    ]),
  },

  methods: {
    ...mapActions('common/promoCampaign', [
      'logClicksPromoMaterials',
    ]),

    async onClickBtnLogClicksPromoMaterials() {
      await this.logClicksPromoMaterials({
        promoActionLogTypeId: promoActionsClick.buttonShowMoreMainPage,
      })

      await this.$router.push({
        name: this.getRouteNameOnUser('courses'),
      })
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`common_components.promo.${name}`)
    },
  },
})
</script>

<style lang="scss" module>
@import '~@/scss/variables';

.promoBlock {
  [class~='product'] {
    gap: 15px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  [class~='iconShowMore'] {
    width: 90px;
    height: 90px;

    @include media-breakpoint-down(sm) {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
