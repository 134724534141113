import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProductList = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params) {
    return this._GET(`/online-school/products`, {
      params,
    })
  }
})()
