<template>
  <ElModal v-model="show" :width="520">
    <div class="vm--modal-content-centered">
      <div class="vm--modal-content">
        <div class="h3-modal">
          {{ text('title') }}
        </div>

        <div class="mb-3">
          <div class="d-flex align-items-center mb-3">
            <div :class="$style.step">1</div>
            <div>{{ text('steps.primary') }}</div>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div :class="$style.step">2</div>
            <div>{{ text('steps.second') }}</div>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div :class="$style.step">3</div>
            <div v-html="text('steps.third')"></div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-md-row flex-column justify-content-between w-100">
        <ElButton
          class="btn-primary mb-3 mb-md-0"
          :label="text('btn_ok')"
          @click="onClickBtnOk"
        />

        <ElButton
          class="btn-outline-primary"
          :label="text('btn_cancel')"
          @click="onClose"
        />
      </div>
    </div>
  </ElModal>
</template>

<script setup>
import ElButton from '@/ui/ElButton.vue'
import ElModal from '@/ui/ElModal.vue'
import { computed, useCssModule } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const $style = useCssModule()
const { t } = useI18n()
const router = useRouter()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: undefined,
  },
})

const emit = defineEmits([
  'update:modelValue',
])

const show = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  },
})

/**
 * @param {string} name
 * @returns {string}
 */
function text(name) {
  return t(`components.main.modal_apply_request_block.${name}`)
}

/**
 *
 */
function onClose() {
  show.value = false
}

/**
 *
 */
function onClickBtnOk() {
  router.push({
    name: 'logout',
    query: {
      skipLogin: '1',
      returnUrl: '/registration-zo',
    },
  })

  onClose()
}
</script>

<style lang="scss" module>
@import '~@/scss/variables';

.step {
  display: flex;
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-right: 10px;
  font-size: 18px;
  color: $blue;
  border: 1px solid $blue;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}
</style>
