export const studentTypes = [
  {
    id: 1,
    name: 'Новый',
  },
  {
    id: 2,
    name: 'Существующий',
  },
]
