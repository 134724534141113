import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesProductRequests = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {number} payload.consumerId
   * @param {number} payload.offerId
   * @returns {Promise}
   */
  post(payload) {
    const url = '/courses/product/requests'

    return this._POST(url, payload)
  }
})()
