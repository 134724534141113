export const studentShowcaseRoutes = [
  {
    path: '',
    component: () => import('@/layouts/showcase/Showcase.vue'),

    children: [
      {
        path: '',
        name: 'student-showcase',
        component: () => import('@/pages/showcase/common/Home.vue'),
      },

      {
        path: 'catalog',
        name: 'student-showcase-catalog',
        component: () => import('@/pages/showcase/common/Catalog.vue'),
      },

      {
        path: 'category/:category',
        name: 'student-showcase-category',
        component: () => import('@/pages/showcase/common/Category.vue'),
      },

      {
        path: 'category/:category/:product',
        name: 'student-showcase-product',
        component: () => import('@/pages/showcase/common/Product.vue'),
      },

      {
        path: 'collections/:collectionId(\\d+)',
        name: 'student-showcase-collection',
        component: () => import('@/pages/showcase/common/Collection.vue'),
      },

      {
        path: 'cart',
        name: 'student-showcase-cart',
        component: () => import('@/pages/showcase/common/Cart.vue'),
      },

      {
        path: 'purchases/:purchaseId(\\d+)',
        name: 'student-showcase-the-purchase',
        component: () => import('@/pages/showcase/student/Purchase.vue'),
      },

      {
        path: 'purchases/:purchaseId(\\d+)/items/:purchaseItemId(\\d+)',
        name: 'student-showcase-product-purchased',
        component: () => import('@/pages/showcase/common/ProductPurchased.vue'),
        children: [
          {
            path: 'materials',
            name: 'student-showcase-product-purchased-materials',
            component: () =>
              import('@/pages/showcase/common/ProductMaterials.vue'),
          },
        ],
      },

      {
        path: '/cart/:offerId(\\d+)',
        name: 'student-showcase-product-link',
        component: () => import('@/pages/showcase/common/ProductLink.vue'),
      },

      {
        path: '/student/product-access-link/category/:category/:product',
        name: 'student-showcase-product-access-link',
        component: () => import('@/pages/showcase/common/Product.vue'),
      },
    ],
  },
]
