/**
 * @returns {Object}
 */
export default () => ({
  links: [
    {
      link: {
        name: 'student-showcase',
      },
      // TODO: i18n
      labelPath: 'Главная',
      class: 'm-showcase',
      notifications: 0,
    },
    {
      link: {
        name: 'student-showcase-education',
      },
      // TODO: i18n
      labelPath: 'Моё обучение',
      class: 'm-subjects',
      notifications: 0,
      children: [
        {
          link: {
            name: 'student-showcase-subjects',
          },
          // TODO: i18n
          labelPath: 'Обучение',
        },
        {
          link: {
            name: 'student-showcase-purchases',
          },
          // TODO: i18n
          labelPath: 'Документы',
        },
      ],
    },

    /**
     * @deprecated в рамках задачи https://redmine.ibls.tech/issues/8382
     * Удалить после 01.02.25, предварительно уточнив об актуальности
     */
    // {
    //   link: {
    //     name: 'student-showcase-category',
    //     params: {
    //       category: null,
    //     },
    //     query: null,
    //   },
    //   // TODO: i18n
    //   labelPath: 'Онлайн-школа',
    //   class: 'm-online-school',
    //   notifications: 0,
    // },
    {
      link: {
        name: 'student-showcase-online-lessons',
      },
      // TODO: i18n
      labelPath: 'Расписание',
      class: 'm-online',
      notifications: 0,
    },
    {
      link: {
        name: 'student-notifications',
      },
      // TODO: i18n
      labelPath: 'Уведомления',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'student-support',
      },
      // TODO: i18n
      labelPath: 'Поддержка',
      class: 'm-help',
      notifications: 0,
    },
  ],
})
