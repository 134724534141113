import ApiBase from 'ApiRest/ApiBase'

export default new (class TheInvoicePaymentUrl extends ApiBase {
  /**
   * @param {number} invoiceId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(invoiceId, parameters) {
    return this._GET(
      `/account/invoices/${invoiceId}/get-url?${this.getUrlParams(parameters)}`,
    )
  }
})()
