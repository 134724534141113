import {
  getFullName,
  getNameAbbreviation,
  getNameWithShortLastName,
} from '@/helpers/fullName'
import { isNull } from 'lodash'

export default {
  fullName(state) {
    return getFullName(state.profile)
  },

  fullNameWithShortLastName(state) {
    return getNameWithShortLastName(state.profile)
  },

  nameAbbreviation(state) {
    return getNameAbbreviation(state.profile)
  },

  isSchoolPlatformDemoAllowed(state) {
    return (
      Boolean(state.schoolPlatform.isDemoAllowed) ||
      Boolean(state.schoolPlatform.isDemoAllowedFe)
    )
  },

  isAvailableAnyCommercialOffer(state) {
    return Boolean(!state.profile.hideOffer)
  },

  timezonesFormatted(state) {
    return [
      ...state.options.timezones,
    ].sort((a, b) => b.offset - a.offset)
  },

  timezone(state) {
    return state.profile.timezone
  },

  timezoneDefault(state) {
    return state.options.timezones.find(({ isDefault }) => isDefault)
  },

  hasTimezone(state) {
    return !isNull(state.profile.timezone)
  },
}
