export const getFullName = function (data = {}) {
  return [
    data.lastName,
    data.firstName,
    data.middleName,
  ]
    .filter(Boolean)
    .join(' ')
}

export const getNameWithShortLastName = function (data = {}) {
  if (data.firstName) {
    const words = []

    words.push(data.firstName)

    if (data.lastName) {
      words.push(`${data.lastName[0].toUpperCase()}.`)
    }

    return words.join(' ')
  }

  return ''
}

export const getNameAbbreviation = function (data = {}) {
  return [
    data.firstName,
    data.lastName,
  ]
    .filter(Boolean)
    .map((word) => word[0])
    .join('')
    .toUpperCase()
}
