import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {{}} data
   * @returns {Promise}
   */
  post(data) {
    const url = '/account/client/simple-request'

    return this._POST(url, data)
  }

  /**
   * @param {{}} data
   * @param {number} requestId
   * @returns {Promise}
   */
  patch(data, requestId) {
    const url = `/account/client/simple-request/${requestId}`

    return this._PATCH(url, data)
  }
})()
