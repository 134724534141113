<template>
  <div v-if="isRendered.rateUs">
    <!-- TODO: i18n -->
    <button
      v-if="isRendered.btnOpenFrame"
      :class="$style.btnOpenFrame"
      @click="onClickBtnOpenFrame"
    >
      Оценить платформу
    </button>

    <div v-else :class="$style.frameContainer">
      <button :class="$style.btnClose" @click="onClickBtnCloseFrame">
        <img
          :class="$style.iconClose"
          :src="icons.close"
          alt="icon close"
          width="24"
        />
      </button>

      <iframe
        :class="$style.frame"
        :src="iframeLink"
        frameborder="0"
        name="ya-form-650ff452068ff0f9dc7a0a2f"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import iconClose from '@/static/images/common-lk/icons/close.svg'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'RateUs',

  props: {
    profile: {
      type: Object,
      required: true,
    },

    educationType: {
      type: Object,
      default: () => null,
    },
  },

  emits: [
    'change-frame-state',
  ],

  data() {
    return {
      icons: {
        close: iconClose,
      },

      isFrameOpened: false,
      isPathForbidden: false,
    }
  },

  computed: {
    ...mapState('client/students', [
      'students',
    ]),

    ...mapGetters('common/user', [
      'isClient',
      'isStudent',
    ]),

    isRendered() {
      return {
        rateUs: !this.isPathForbidden,
        btnOpenFrame: !this.isFrameOpened,
      }
    },

    forbiddenPaths() {
      return [
        'lessons/',
        'test/',
      ]
    },

    userId() {
      return this.profile.id
    },

    educationTypeStringValue() {
      let value = ''

      if (this.educationType?.extramural) {
        value = 'ЗО'
      }

      if (this.educationType?.family) {
        value = 'СО'
      }

      if (this.educationType?.fullTime) {
        value = 'ОО'
      }

      return value
    },

    userTypeStringValue() {
      let value = ''

      if (this.isClient) {
        value = 'Клиент'
      }

      if (this.isStudent) {
        value = 'Ученик'
      }

      return value
    },

    hasOnlineSchoolStringValue() {
      let value = ''

      if (this.isStudent) {
        if (this.profile.hasOnlineSchoolOrder) {
          value = 'Да'
        } else {
          value = 'Нет'
        }
      }

      if (this.isClient) {
        if (this.students.some((student) => student.hasOnlineSchool)) {
          value = 'Да'
        } else {
          value = 'Нет'
        }
      }

      return value
    },

    iframeLink() {
      return `https://forms.yandex.ru/u/650ff452068ff0f9dc7a0a2f/?iframe=1&user_id=${this.userId}&user_type=${this.userTypeStringValue}&education_type=${this.educationTypeStringValue}&online_school=${this.hasOnlineSchoolStringValue}`
    },
  },

  watch: {
    $route: {
      handler(newRoute) {
        this.isFrameOpened = false
        this.isPathForbidden = this.checkIfPathForbidden(newRoute.path)
      },

      immediate: true,
    },

    isFrameOpened() {
      this.$emit('change-frame-state', this.isFrameOpened)
    },
  },

  methods: {
    checkIfPathForbidden(path) {
      return this.forbiddenPaths.some((forbiddenPath) =>
        path.includes(forbiddenPath),
      )
    },

    onClickBtnOpenFrame() {
      this.isFrameOpened = true
    },

    onClickBtnCloseFrame() {
      this.isFrameOpened = false
    },
  },
}
</script>

<style lang="scss" module>
@import '~@/scss/variables';

.btnOpenFrame {
  position: static;
  top: 250px;
  right: -84px;
  z-index: 1000;
  width: 100%;
  height: 38px;
  font-size: 17px;
  font-weight: 500;
  color: gray;
  background-color: white;
  border: none;
  border-radius: 0 0 8px 8px;
  box-shadow: -1px -1px 20px 1px rgb(0 0 0 / 15%);
  transform: none;

  @media (min-width: $sm) {
    position: fixed;
    top: 50%;
    width: 203px;
    margin-top: -19px;
    font-weight: 400;
    border-radius: 8px 8px 0 0;
    transform: rotate(-90deg);
  }
}

.btnClose {
  position: absolute;
  top: 18px;
  right: 11px;
  background-color: white;
  border: none;

  @media (min-width: $sm) {
    top: 20px;
    right: 14px;
  }
}

.iconClose {
  width: 20px;
  height: 20px;

  @media (min-width: $sm) {
    width: 30px;
    height: 30px;
  }
}

.frameContainer {
  position: fixed;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 23px 0 0;
  background-color: white;
  border-radius: 0 0 8px 8px;
  box-shadow: -2px 5px 20px 1px rgb(0 0 0 / 15%);

  @media (min-width: $sm) {
    top: 50%;
    width: 674px;
    height: 465px;
    padding: 28px 20px 5px;
    margin-top: -233px;
    border-radius: 8px 0 0 8px;
  }
}

.frame {
  position: absolute;
  top: 50%;
  margin-top: -150px;

  @media (min-width: $sm) {
    position: unset;
    margin-top: 0;
  }
}
</style>
