import { numberToCurrency } from '@/helpers/numberToCurrency'
import Constants from 'Constants'
import { isAfter } from 'date-fns'
import { millisecondsInSecond } from 'date-fns/constants'
import { isNull } from 'lodash'

export default {
  hasVacantSeats(state) {
    return state.product?.vacantSeats > 0
  },

  hasUser(state) {
    return state.user !== null
  },

  dateStart(state) {
    if (state.product) {
      const { dateStart } = state.product.holding
      const timeStart = state.product.holding.timeStart ?? '00:00:00'
      const dateTime = [
        dateStart,
        timeStart,
      ].join('T')

      return new Date(dateTime)
    }

    return new Date()
  },

  dateServer(state) {
    return new Date(state.serverTimestamp * millisecondsInSecond)
  },

  isEnrollPaused(state, getters) {
    return isAfter(getters.dateServer, getters.dateStart)
  },

  hasPrice(state) {
    return state.offer?.price > 0
  },

  price(state, getters) {
    return getters.hasPrice ? numberToCurrency(state.offer.price) : 'Бесплатно'
  },

  dolyamePrice(state, getters) {
    if (getters.hasPrice) {
      return numberToCurrency(Math.ceil(state.offer.price / 4))
    }

    return ''
  },

  hasOfferSubscription(state) {
    return !isNull(state.offer?.subscription)
  },

  isOfferSubscriptionMonthly(state, getters) {
    if (!getters.hasOfferSubscription) {
      return false
    }

    const monthlySubscriptionIds = [
      Constants.paymentIntervals.MONTHLY,
      Constants.paymentIntervals.MONTHLY_FROM_FIRST,
      Constants.paymentIntervals.MONTHLY_PREPAID,
    ]

    return monthlySubscriptionIds.includes(
      state.offer?.subscription?.paymentInterval?.id,
    )
  },
}
