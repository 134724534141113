import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchases = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = `/purchases`

    return this._GET(url)
  }
})()
