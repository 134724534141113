import ApiBase from 'ApiRest/ApiBase'

export default new (class SubjectCtps extends ApiBase {
  /**
   * @param {number} subjectId
   * @param {number} groupId
   * @returns {Promise}
   */
  get(subjectId, groupId) {
    return this._GET(
      `/account/student/subjects/${subjectId}/groups/${groupId}/ctps`,
    )
  }
})()
