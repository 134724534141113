<template>
  <div>
    <a
      v-if="isRendered.externalUrl"
      :class="link.class"
      :target="link.isExternal ? '_blank' : false"
      class="link link-dark d-flex align-items-center"
      :href="link.externalUrl"
      :rel="link.isExternal ? 'noopener noreferrer' : false"
    >
      <span>{{ $t(link.labelPath) }}</span>

      <i v-if="isRendered.notification" class="notif ml-2">
        {{ link.notifications }}
      </i>

      <img
        v-if="isRendered.iconLinkExternal"
        class="ml-2"
        :src="icons.linkExternal"
        alt="external link"
      />
    </a>

    <template v-else>
      <template v-if="isRendered.children">
        <div
          :class="linkCssClass"
          class="link link-dark d-flex align-items-center justify-content-between"
          @click="onClickGroup"
        >
          <span>{{ $t(link.labelPath) }}</span>

          <div class="mr-2">
            <i v-if="isVisibleChild" class="fas fa-angle-down fa-lg"></i>
            <i v-else class="fas fa-angle-right fa-lg"></i>
          </div>
        </div>

        <TheHeaderMenuLink
          v-for="child in link.children"
          v-show="isVisibleChild"
          :key="child.label"
          :link="child"
          :class="$style.linkWithChildren"
        />
      </template>

      <router-link
        v-else
        :class="link.class"
        :to="link.link"
        class="link link-dark d-flex align-items-center"
      >
        {{ $t(link.labelPath) }}

        <i v-if="isRendered.notification" class="notif ml-2">
          {{ link.notifications }}
        </i>
      </router-link>
    </template>
  </div>
</template>

<script>
import iconLinkExternalDark from '@/static/images/common-lk/icons/link-external-dark.svg'
import { isUndefined } from 'lodash'

export default {
  name: 'TheHeaderMenuLink',

  props: {
    link: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      icons: {
        linkExternal: iconLinkExternalDark,
      },

      isVisibleChild: false,
    }
  },

  computed: {
    hasChildren() {
      return !isUndefined(this.link.children)
    },

    isRendered() {
      return {
        externalUrl: !isUndefined(this.link.externalUrl),
        iconLinkExternal: this.link.isExternal,
        notification: this.link.notifications > 0,
        children: this.hasChildren,
      }
    },

    hasActiveChild() {
      if (!this.hasChildren) {
        return false
      }

      return this.link.children.some(
        ({ link }) => this.$route.name === link.name,
      )
    },

    linkCssClass() {
      return [
        this.link.class,
        this.isVisibleChild ? this.$style.linkWithChildren : '',
      ]
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      if (this.hasActiveChild) {
        this.isVisibleChild = true
      }
    },

    onClickGroup() {
      this.isVisibleChild = !this.isVisibleChild
    },
  },
}
</script>

<style lang="scss" module>
.linkWithChildren {
  background-color: rgba(#f3f3f3, 0.5);
}
</style>
