export default () => ({
  educationType: null,
  educationTypes: [],
  email: '',
  firstName: '',
  grade: null,
  grades: [],
  isRegistrationSucceed: false,
  parentName: '',
  parentPhone: '',
})
