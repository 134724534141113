export default () => ({
  lessons: [],
  lessonsTestOnly: [],
  lessonsUnpassedOnly: [],
  lessonsFirstWeek: [],
  lessonsPagination: {
    all: {
      page: 1,
      pagesTotal: 1,
    },
    testOnly: {
      page: 1,
      pagesTotal: 1,
    },
    unpassedOnly: {
      page: 1,
      pagesTotal: 1,
    },
  },
  lessonsPreview: [],
  closestLessons: [],
  closestLessonsPeriod: {
    start: null,
    end: null,
  },
  currentLesson: null,
  currentContent: null,
})
