import ApiShowcasePurchaseUploadDoc from 'ApiRest/Api/Showcase/Purchase/UploadDoc'
import Constants from 'Constants'
import { isEmpty, isNull } from 'lodash'

export default {
  citizenship(state) {
    return state.customer.citizenship
  },

  passport(state) {
    return state.customer?.passport ?? null
  },

  clientDocumentUploadUrl(state) {
    return ApiShowcasePurchaseUploadDoc.getFileUploadUrl(state.purchaseId)
  },

  hasClientCitizenship(state, getters) {
    return !isNull(getters.citizenship)
  },

  hasClientPassport(state, getters) {
    return !isNull(getters.passport)
  },

  countriesFiltered(state) {
    return state.options.countryList.filter(
      (country) => country.id !== Constants.country.RUSSIA,
    )
  },

  hasPurchases(state) {
    return !isEmpty(state.purchases)
  },

  hasProductsExtracurricular(state, getters) {
    return !isEmpty(getters.productsExtracurricular)
  },

  hasProductsCommon(state, getters) {
    return !isEmpty(getters.productsCommon)
  },

  hasSignedDocuments(state) {
    return !isEmpty(state.purchase.signedDocuments)
  },

  hasAddress(state) {
    return !isEmpty(state.customer.address)
  },

  hasAgreements(state) {
    return !isEmpty(state.purchase.agreements)
  },

  hasClientDocuments(state) {
    return !isEmpty(state.purchase.clientDocuments)
  },

  hasCollectChildData(state) {
    return state.purchase.items.some((item) => item.collectChildData)
  },

  hasEveryCustomerRelationshipTypeOffer(state) {
    return state.purchase.items.some(
      (item) =>
        item.customerRelationshipType.id ===
        Constants.customerRelationshipType.OFFER,
    )
  },

  hasProductDocuments(state, getters) {
    return !isEmpty(getters.productDocuments)
  },

  hasProductsWithDocumentsUploadRequired(state) {
    return state.purchase.items.some(
      ({ isDocumentsUploadRequired }) => isDocumentsUploadRequired,
    )
  },

  hasRequiredDocuments(state, getters) {
    return !isEmpty(getters.requiredDocumentsList)
  },

  hasSomeCustomerRelationshipTypeContract(state) {
    return state.purchase.items.some(
      (item) =>
        item.customerRelationshipType.id ===
        Constants.customerRelationshipType.CONTRACT,
    )
  },

  hasStudents(state) {
    return state.students.length > 0
  },

  isAwaitingDocuments(state) {
    return state.purchase.items.some((item) => item.isAwaitingDocuments)
  },

  isCitizenshipRussia(state, getters) {
    return getters.citizenship.id === Constants.citizenship.RUSSIAN_FEDERATION
  },

  isCitizenshipOtherCountry(state, getters) {
    return getters.citizenship.id === Constants.citizenship.OTHER_COUNTRY
  },

  isPurchaseAwaitingPayment(state) {
    return state.purchase.status.id === Constants.orderStatus.AWAITING_PAYMENT
  },

  isPurchaseClearanceRequired(state) {
    return state.purchase.status.id === Constants.orderStatus.CLEARANCE_REQUIRED
  },

  isPurchasePaidOrConcluded(state) {
    const statusIds = [
      Constants.orderStatus.PAID,
      Constants.orderStatus.PARTIALLY_PAID,
      Constants.orderStatus.CONCLUDED,
    ]

    return statusIds.includes(state.purchase.status.id)
  },

  isPurchasePending(state) {
    const statusIds = [
      Constants.orderStatus.CLEARANCE_REQUIRED,
      Constants.orderStatus.PARTIALLY_PAID,
      Constants.orderStatus.AWAITING_PAYMENT,
    ]

    return statusIds.includes(state.purchase.status.id)
  },

  productsCommon(state) {
    return state.purchase.items.filter(
      ({ isExtracurricular }) => !isExtracurricular,
    )
  },

  productDocuments(state) {
    return state.purchase.items
      .filter(({ documents }) => !isEmpty(documents))
      .flatMap((item) =>
        item.documents.map((document) => ({
          document,
          productName: item.name,
          itemId: item.id,
        })),
      )
  },

  productsExtracurricular(state) {
    return state.purchase.items.filter(
      ({ isExtracurricular }) => isExtracurricular,
    )
  },

  requiredDocumentsList(state) {
    return state.purchase.items
      .filter((item) => item.requiredDocumentsList)
      .map((item) => ({
        itemName: item.name,
        requiredDocumentsList: item.requiredDocumentsList,
      }))
  },

  isAgreeWithConditions(state) {
    return state.purchase?.agreements?.every(({ checked }) => checked) ?? true
  },

  isEveryItemFree(state) {
    return state.purchase.items.every((item) => item.price === 0)
  },

  hasProductToBePaid(state) {
    const hasItemsWithoutInvoice = state.purchase.items.some(
      (item) => item.price !== 0 && item.invoiceStatus === null,
    )
    const hasItemsWithNewInvoice = state.purchase.items.some(
      (item) => item.invoiceStatus?.id === Constants.invoiceStatus.NEW,
    )

    return hasItemsWithoutInvoice || hasItemsWithNewInvoice
  },

  isPurchaseCancelled() {
    const invoiceStatuses = [
      Constants.invoiceStatus.CANCELLED,
      Constants.invoiceStatus.REFUNDED,
      Constants.invoiceStatus.ANNULLED,
    ]

    return (purchase) => {
      const { status, items } = purchase

      return (
        status.id === Constants.orderStatus.AWAITING_PAYMENT &&
        items.every((item) => invoiceStatuses.includes(item.invoiceStatus?.id))
      )
    }
  },

  hasProductsInactive(state) {
    return state.purchase.items.some(
      (item) => !item.isProductActive || !item.isProductHasVacantSeats,
    )
  },

  hasProducts(state) {
    return state.purchase.items.length > 0
  },
}
