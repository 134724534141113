import ApiNotificationList from 'ApiRest/Api/Notification/List'
import ApiNotification from 'ApiRest/Api/Notification/Read'

export default {
  updateGlobalCounter({ commit, getters, rootState, rootGetters }) {
    const count = getters.notificationsUnReadCount
    const { role } = rootState.common.user

    commit(
      `${role}/main/setNotificationsCount`,
      {
        target: rootGetters['common/user/getRouteNameOnUser']('notifications'),
        count,
      },
      {
        root: true,
      },
    )
  },

  async fetchNotifications({ commit, dispatch }) {
    const { data } = await ApiNotificationList.get()

    commit('notificationsSet', data)
    await dispatch('updateGlobalCounter')
  },

  async readNotification({ commit, dispatch }, notificationId) {
    const { data } = await ApiNotification.patch(notificationId)

    commit('readNotificationSet', data)
    await dispatch('updateGlobalCounter')
  },
}
