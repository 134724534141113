import ApiBase from 'ApiRest/ApiBase'

export default new (class PasswordRecoveryConfirm extends ApiBase {
  /**
   * @param {Object} data
   * @property {string} code
   * @returns {Promise}
   */
  patch(data) {
    return this._PATCH('/user/password-recovery-confirm', data)
  }
})()
