/**
 * @typedef {Object} ShowcaseCategory
 * @property {number} id
 * @property {string} name
 * @property {ShowcaseCategory[]} [subcategories]
 * @property {number} [parentCategoryId]
 */

export default () => ({
  /** @type {ShowcaseCategory[]} */
  categories: [],

  upperCategories: [],

  categoriesLite: [],
})
