import ApiBase from 'ApiRest/ApiBase'

export default new (class AskQuestionContent extends ApiBase {
  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  post(id, data) {
    return this._POST(`/account/student/ask-question/content/${id}`, data)
  }
})()
