import { mapActions, mapMutations, mapState } from 'vuex'

export const LangSwitchMixin = {
  computed: {
    ...mapState('common/locale', [
      'langOptions',
    ]),

    lang: {
      get() {
        return this.profile.language
      },

      async set(option) {
        await this.updateLang(option)
        this.setLang(option.code)
        await this.changeLocale()
      },
    },

    hasLangOptions() {
      return this.langOptions.length > 0
    },
  },

  methods: {
    ...mapMutations('common/locale', [
      'setLang',
    ]),

    ...mapActions('common/locale', [
      'changeLocale',
    ]),

    async initLang() {
      if (!this.lang) {
        this.lang = this.langOptions[0]
      } else {
        this.setLang(this.lang.code)
        await this.changeLocale()
      }
    },
  },

  watch: {
    hasLangOptions: {
      async handler(value) {
        if (value) {
          await this.initLang()
        }
      },

      immediate: true,
    },
  },
}
