import { ApiAccountPromoCtps } from 'Api/rest/Api/Account/Promo/Ctps'
import { ApiAccountPromoProducts } from 'Api/rest/Api/Account/Promo/Products'
import { ApiAccountPromoAction } from 'ApiRest/Api/Account/Promo/PromoAction'
import { ApiAccountPromoCampaign } from 'ApiRest/Api/Account/Promo/PromoCampaign'

export default {
  async fetchPromoProducts({ commit }) {
    const { data } = await ApiAccountPromoProducts.get()

    commit('setPromoProducts', data)
  },

  async fetchPromoCampaign({ commit }, promoCampaignId) {
    const { data } = await ApiAccountPromoCampaign.get(promoCampaignId)

    commit('setPromoCampaign', data)
  },

  async fetchPromoCtps({ commit }) {
    const { data } = await ApiAccountPromoCtps.get()

    commit('setPromoCtps', data)
  },

  logClicksPromoMaterials(context, data) {
    return ApiAccountPromoAction.post(data)
  },
}
