import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportIdeaComment = new (class extends ApiBase {
  /**
   * @param {number} ideaId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(ideaId, payload, parameters) {
    const url = `/support/ideas/${ideaId}/comment?${UrlHelper.param(parameters)}`

    return this._POST(url, payload)
  }

  /**
   * @param {number} ideaId
   * @param {number} commentId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  patch(ideaId, commentId, payload, parameters) {
    const url = `/support/ideas/${ideaId}/comments/${commentId}?${UrlHelper.param(parameters)}`

    return this._PATCH(url, payload)
  }
})()
