export default () => ({
  goals: [],
  theGoal: {
    title: '',
    startDateTime: '',
    endDateTime: '',
    isDaylong: true,
    subject: '',
    description: '',
  },
})
