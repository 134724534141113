import { RouterUser } from '@/RouterUser'
import storage from '@/helpers/storage'
import ApiSchool from 'ApiRest/Api/Account/Client/School'
import ApiActiveSubjectList from 'ApiRest/Api/Account/Client/Students/ActiveSubject/List'
import ApiStudentAnalytics from 'ApiRest/Api/Account/Client/Students/Analytics'
import { ApiAccountClientStudentAuth } from 'ApiRest/Api/Account/Client/Students/Auth'
import { ApiAcountClientStudentsExpelled } from 'ApiRest/Api/Account/Client/Students/Expelled'
import ApiStudentList from 'ApiRest/Api/Account/Client/Students/List'
import Constants from 'Constants'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  resetStatesOnStudentChange({ commit }) {
    commit('client/news/resetState', null, {
      root: true,
    })

    commit('client/familyCertification/resetState', null, {
      root: true,
    })

    commit('client/familyCertificationRequest/resetState', null, {
      root: true,
    })

    commit('client/extramuralCertification/resetState', null, {
      root: true,
    })

    commit('client/journal/resetState', null, {
      root: true,
    })

    commit('client/specialStatement/resetState', null, {
      root: true,
    })

    commit('setAnalytics', {})
    commit('setAnalyticsAcademicYear', null)
  },

  async fetchStudents({ state, commit, dispatch, getters }) {
    const { data } = await ApiStudentList.get()

    if (data.length) {
      commit('setStudents', data)

      const savedStudentId = storage.get('clientCurrentStudent')
      const savedStudent = state.students.find(
        ({ id }) => Number(id) === Number(savedStudentId),
      )
      const isSavedStudentEnrolled =
        savedStudent?.accountStatus.id === Constants.accountStatus.ACTIVE &&
        (savedStudent?.enrollmentStatus.id ===
          Constants.enrollmentStatus.ENROLLED ||
          savedStudent?.hasIncomeDiagnostics)

      if (savedStudent && isSavedStudentEnrolled) {
        await dispatch('fetchTheStudent', savedStudentId)
      } else {
        if (getters.hasEnrolledStudents) {
          await dispatch('fetchTheStudent', getters.enrolledStudents[0].id)
        }
      }
    }
  },

  async fetchAnalytics({ commit }, parameters) {
    const { data } = await ApiStudentAnalytics.get(parameters)

    commit('setAnalytics', data)
  },

  async fetchSchool({ commit }, schoolId) {
    const { data } = await ApiSchool.get(schoolId)

    commit('setSchool', data)
  },

  async fetchTheStudent(
    { state, commit, dispatch, getters, rootState, rootGetters },
    studentId,
  ) {
    storage.set('clientCurrentStudent', studentId)

    commit('setTheStudent', studentId)

    const schoolId = state.currentStudent.school?.id
    const isExtramural = getters.educationType.extramural
    const isFamily = getters.educationType.family
    const isFullTime = getters.educationType.fullTime
    const hasModules = rootGetters['common/module/hasModules']

    await dispatch('client/students/resetStatesOnStudentChange', null, {
      root: true,
    })

    await dispatch('fetchActiveSubjects', studentId)

    const requests = [
      dispatch('fetchSchool', schoolId),
      dispatch('client/specialStatement/fetchSpecialStatements', studentId, {
        root: true,
      }),
      dispatch('client/lessons/fetchLessonsFirstWeek', null, {
        root: true,
      }),
    ]

    if (
      hasModules([
        'school-library',
      ])
    ) {
      requests.push(
        dispatch('client/library/fetchItems', null, {
          root: true,
        }),
        dispatch('client/library/fetchServerTimestamp', null, {
          root: true,
        }),
      )
    }

    if (isExtramural) {
      await dispatch('client/statements/fetchPeriodList', null, {
        root: true,
      })

      commit(
        'client/statements/setPeriod',
        getters.currentStudentStreamAcademicYearPreset,
        {
          root: true,
        },
      )

      if (rootState.client.statements.period !== null) {
        requests.push(
          dispatch(
            'client/statements/fetchStatements',
            {
              academicYearPreset: {
                id: rootState.client.statements.period.id,
              },
              student: {
                id: studentId,
              },
            },
            {
              root: true,
            },
          ),
        )
      }

      if (RouterUser.router.currentRoute.value.name !== 'client-goals') {
        requests.push(
          dispatch('client/goals/fetchGoals', studentId, {
            root: true,
          }),
        )
      }
    }

    if (isExtramural) {
      if (
        RouterUser.router.currentRoute.value.name !== 'client-certification'
      ) {
        requests.push(
          dispatch(
            'client/extramuralCertification/fetchCertifications',
            {
              student: {
                id: studentId,
              },
            },
            {
              root: true,
            },
          ),
        )
      }
    }

    if (isFamily) {
      if (RouterUser.router.currentRoute.value.name !== 'client-goals') {
        requests.push(
          dispatch('client/goals/fetchGoals', studentId, {
            root: true,
          }),
        )
      }

      if (RouterUser.router.currentRoute.value.name !== 'client-lessons') {
        requests.push(
          dispatch('client/lessons/fetchLessons', studentId, {
            root: true,
          }),
        )
      }

      requests.push(
        dispatch(
          'client/familyCertificationRequest/fetchTheFamilyCertificationRequestIsAvailable',
          studentId,
          {
            root: true,
          },
        ),
      )

      if (
        RouterUser.router.currentRoute.value.name !== 'client-certification'
      ) {
        requests.push(
          dispatch(
            'client/familyCertification/fetchCertifications',
            studentId,
            {
              root: true,
            },
          ),
        )
      }
    }

    if (
      (isExtramural || isFamily) &&
      getters.getSubjectsExtracurricular.length > 0
    ) {
      await dispatch('client/statementsShowcase/fetchAcademicYears', null, {
        root: true,
      })

      if (rootState.client.statementsShowcase.academicYears.length > 0) {
        commit(
          'client/statementsShowcase/setAcademicYear',
          getters.currentStudentStreamAcademicYearPreset,
          {
            root: true,
          },
        )

        await dispatch('client/statementsShowcase/fetchStatements', null, {
          root: true,
        })
      }
    }

    if (isFullTime) {
      await dispatch('client/fullTimeStatement/fetchAcademicYears', null, {
        root: true,
      })

      if (
        RouterUser.router.currentRoute.value.name !== 'client-certification'
      ) {
        requests.push(
          dispatch(
            'client/fullTimeCertification/fetchCertifications',
            {
              student: {
                id: studentId,
              },
            },
            {
              root: true,
            },
          ),
        )
      }

      if (rootState.client.fullTimeStatement.academicYears.length > 0) {
        commit(
          'client/fullTimeStatement/setTheAcademicYear',
          getters.currentStudentStreamAcademicYearPreset,
          {
            root: true,
          },
        )

        requests.push(
          dispatch(
            'client/fullTimeStatement/fetchFullTimeCtpStatements',
            null,
            {
              root: true,
            },
          ),

          dispatch('client/homework/fetchHomeworkDates', studentId, {
            root: true,
          }),

          dispatch(
            'client/averageMark/fetchAverageMarksMonthly',
            {
              studentId,
              params: {
                academicYearPreset: {
                  id: rootState.client.fullTimeStatement.academicYears[0]?.id,
                },
              },
            },
            {
              root: true,
            },
          ),
        )
      }
    }

    // THE_FAMILY_CERTIFICATION_REQUEST_IS_AVAILABLE_FETCH
    // эндпоинт, вызываемый экшном
    // возвращает 404, если Аттестация не доступна
    await Promise.allSettled(requests)

    if (isFullTime) {
      dispatch('client/averageMark/prepareChartData', null, {
        root: true,
      })
    }
  },

  async fetchActiveSubjects({ commit }, studentId) {
    const { data } = await ApiActiveSubjectList.get(studentId)

    commit('setActiveSubjects', data)
  },

  excludeStudent(context, studentId) {
    return ApiAcountClientStudentsExpelled.put(studentId)
  },

  async fetchStudentToken({ getters, commit }) {
    const { data } = await ApiAccountClientStudentAuth.get(
      getters.currentStudentId,
    )

    commit('setStudentToken', data)
  },
}
