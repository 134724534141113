<template>
  <div>
    <ul v-if="isRendered.errorsList" class="mb-0">
      <li v-for="(error, i) in errorsList" :key="i">{{ error }}</li>
    </ul>

    <!-- TODO: i18n -->
    <span v-else>Неизвестная ошибка</span>
  </div>
</template>

<script setup>
import { flatten } from 'flat'
import { isArray, isPlainObject } from 'lodash'
import { computed } from 'vue'

const props = defineProps({
  errors: {
    type: [
      Object,
      Array,
    ],
    default: () => null,
  },
})

const isRendered = computed(() => ({
  errorsList: isArray(props.errors) || isPlainObject(props.errors),
}))

const errorsList = computed(() =>
  isPlainObject(props.errors)
    ? Object.values(flatten(props.errors))
    : props.errors,
)
</script>
