import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    const url = `/diagnostics/options/grades/${schoolId}`

    return this._GET(url)
  }
})()
