import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    return this._GET(`/account/client/school/${schoolId}/request/settings`)
  }
})()
