import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setInputValue(state, { name, value = '' }) {
    state[name] = value
  },

  setIsLoading(state, isLoading = false) {
    state.isLoading = isLoading
  },
}
