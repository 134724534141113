export default () => ({
  profile: {},
  passwords: {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  },
  userSettings: {},

  options: {
    timezones: [],
  },
})
