import { compareDesc, parseISO } from 'date-fns'

const _sortByDateDesc = (a, b) =>
  compareDesc(parseISO(a.createdAt.date), parseISO(b.createdAt.date))

export default {
  notificationsUnReadCount(state) {
    return state.notifications.filter((i) => !i.read).length
  },

  notifications(state) {
    return [
      ...state.notifications,
    ].sort(_sortByDateDesc)
  },
}
