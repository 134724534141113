import dateFormatHelper from '@/helpers/dateFormatHelper'
import { lessonsGetters } from '@/helpers/store/getters'
import { compareAsc, parseISO } from 'date-fns'
import { cloneDeep } from 'lodash'

const _sortByDate = (a, b) =>
  compareAsc(
    parseISO(dateFormatHelper.toIso(a.date)),
    parseISO(dateFormatHelper.toIso(b.date)),
  )

export default {
  ...lessonsGetters,

  getLessonsSortedByDate(state) {
    return cloneDeep(state.lessons).sort(_sortByDate)
  },

  getLessonNotPassed(state, getters) {
    return (lessonId, direction = 'next') => {
      const currentLesson = getters.getLessonsSortedByDate.find(
        (lesson) => Number(lesson.id) === Number(lessonId),
      )

      const currenIndex = getters.getLessonsSortedByDate.findIndex(
        (lesson) => Number(lesson.id) === Number(lessonId),
      )

      let targetLessons

      if (direction === 'next') {
        targetLessons = getters.getLessonsSortedByDate.slice(currenIndex + 1)
      } else {
        targetLessons = getters.getLessonsSortedByDate
          .slice(0, currenIndex)
          .reverse()
      }

      const lessonNotPassedInSameSubject = targetLessons.find(
        (lesson) =>
          lesson.subject.id === currentLesson.subject.id && !lesson.isPassed,
      )

      if (lessonNotPassedInSameSubject) {
        return lessonNotPassedInSameSubject
      }

      const lessonNotPassed = targetLessons.find((lesson) => !lesson.isPassed)

      if (lessonNotPassed) {
        return lessonNotPassed
      }

      return null
    }
  },

  getContents(state) {
    const lesson = state.currentLesson
    const contents = []

    if (lesson?.topics) {
      for (const topic of lesson.topics) {
        if (topic?.contents) {
          for (const content of topic.contents) {
            const hasMaterials = Boolean(content.materials?.length)

            if (hasMaterials) {
              contents.push({
                ...content,
                $type: 'content',
              })
            }
          }
        }

        if (topic?.miniTests) {
          for (const miniTest of topic.miniTests) {
            contents.push({
              ...miniTest,
              $type: 'miniTest',
            })
          }
        }
      }
    }

    return contents
  },

  hasContents(state, getters) {
    return Boolean(getters.getContents.length)
  },

  getContentsNotPassed(state, getters) {
    const contents = getters.getContents

    return contents.filter((i) => i.isPassed === false)
  },

  getContentsNotPassedCount(state, getters) {
    return getters.getContentsNotPassed.length
  },

  getCurrentContent(state, getters) {
    return (id) => {
      if (getters.hasContents) {
        if (id) {
          return getters.getContents.find((content) => content.id === id)
        }

        return getters.getContents[0]
      }

      return null
    }
  },

  isCurrentContentLastNotPassed(state, getters) {
    return (contentId) => {
      const hasLastContentToPass = getters.getContentsNotPassedCount === 1

      if (hasLastContentToPass && contentId) {
        const lastContent = getters.getContentsNotPassed.find(
          (content) => content.id === contentId,
        )

        return Boolean(lastContent)
      }

      return false
    }
  },

  /**
   * @param {Object} state
   * @returns {boolean}
   */
  hasLessonHomeworks(state) {
    return Boolean(
      state.currentLesson?.homeworkSolution !== null ||
        state.currentLesson?.personalHomeworkSolution !== null,
    )
  },

  /**
   * @param {Object} state
   * @returns {boolean}
   */
  isHomeworksChecked(state) {
    return Boolean(
      state.currentLesson?.homeworkSolution?.isChecked &&
        state.currentLesson?.personalHomeworkSolution?.isChecked,
    )
  },

  /**
   * @param {Object} state
   * @param {Object} getters
   * @returns {boolean}
   */
  isLessonComplete(state, getters) {
    return getters.getContentsNotPassedCount === 0 && getters.isHomeworksChecked
  },
}
