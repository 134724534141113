/**
 * @constant
 * @type {RegExp[]}
 */
const silentRequestList = JSON.parse(
  process.iblsEnv.IBLS_SILENT_REQUEST_LIST || '[]',
).map((mask) => new RegExp(mask))

/**
 * @param {string} url
 * @returns {boolean}
 */
export default function isRequestSilent(url) {
  const { pathname } = new URL(url)

  return silentRequestList.some((requestMask) => requestMask.test(pathname))
}
