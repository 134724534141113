import ApiBase from 'ApiRest/ApiBase'

export default new (class School extends ApiBase {
  /**
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    return this._GET(`/auth/schools/${schoolId}`)
  }
})()
