import actions from '@/store/courses/common/product/actions'
import getters from '@/store/courses/common/product/getters'
import mutations from '@/store/courses/common/product/mutations'
import state from '@/store/courses/common/product/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
