import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentOnlineSchoolCtpStatementAcademicYears =
  new (class extends ApiBase {
    /**
     * Список учебных годов для ведомостей КТП, приобретенных через онлайн-школу
     * @returns {Promise}
     */
    get() {
      return this._GET(
        `/account/student/online-school-ctp-statement/academic-years`,
      )
    }
  })()
