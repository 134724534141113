export const studentFullTimeSupportRoutes = [
  {
    path: 'support',
    name: 'student-support',
    component: () => import('@/pages/student/support/Support.vue'),
    children: [
      {
        path: 'questions',
        name: 'student-support-questions',

        component: () => import('@/pages/student/support/Questions.vue'),
      },
      {
        path: 'ideas',
        name: 'student-support-ideas',

        component: () => import('@/pages/student/support/Ideas.vue'),
      },
      {
        path: 'faq',
        name: 'student-support-faq',

        component: () => import('@/pages/student/support/FAQ.vue'),
      },
      {
        path: 'contacts',
        name: 'student-support-contacts',

        component: () => import('@/pages/student/support/Contacts.vue'),
      },
    ],
  },
  {
    path: 'support/questions/:questionId(\\d+)',
    name: 'student-support-the-question',
    component: () => import('@/pages/student/support/TheQuestion.vue'),
  },
  {
    path: 'support/ideas/:ideaId(\\d+)',
    name: 'student-support-the-idea',
    component: () => import('@/pages/student/support/TheIdea.vue'),
  },
]
