import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseItemDocument = new (class extends ApiBase {
  /**
   * Сгенерированный документ продукта (договор, оферта), заполненный данными клиента
   * @param {Object} params
   * @param {number} params.purchaseId
   * @param {number} params.purchaseItemId
   * @param {number} params.documentTemplateId
   * @returns {Promise}
   */
  get({ purchaseId, purchaseItemId, documentTemplateId }) {
    const url = `/purchase/${purchaseId}/item/${purchaseItemId}/document/${documentTemplateId}`

    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }

  getDocx({ purchaseId, purchaseItemId, documentTemplateId }) {
    const url = `/purchase/${purchaseId}/item/${purchaseItemId}/document/${documentTemplateId}/docx`

    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config).catch(async (error) => {
      try {
        const errorData = JSON.parse(await error.response?.data?.text())
        const errorFormatted = error

        errorFormatted.response.data = errorData

        throw errorFormatted
      } catch {
        throw error
      }
    })
  }
})()
