import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseProductOptionsStudents = new (class extends ApiBase {
  /**
   * @param {number} productId
   * @returns {Promise}
   */
  get(productId) {
    const url = `/product/options/students/${productId}`

    return this._GET(url)
  }
})()
