import ApiContentPreview from 'ApiRest/Api/Library/Section/Topic/Content/Preview'

export default {
  async fetchContentsPreview({ commit }, contentId) {
    const { data } = await ApiContentPreview.get(contentId)

    commit('setTopic', data)
  },

  async setLocale({ commit, dispatch }) {
    await dispatch('common/locale/fetchLangOptions', null, {
      root: true,
    })

    dispatch('common/locale/setLangDefault', null, {
      root: true,
    })

    commit('common/locale/setUserType', 'student', {
      root: true,
    })

    commit('common/locale/setInstitutionType', 'school', {
      root: true,
    })

    await dispatch('common/locale/changeLocale', null, {
      root: true,
    })
  },
}
