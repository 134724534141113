import ApiBase from 'ApiRest/ApiBase'

/**
 * @typedef {{
 * chatId:number,
 * clientCode:number,
 * }} TelegramPayloadI
 */

export default new (class extends ApiBase {
  /**
   * Подписаться на Telegram-уведомления.
   * @param {number} chatId
   * @returns {Promise}
   */
  post(chatId) {
    const url = `/notification/telegram`
    const data = {
      options: {
        chatId,
      },
    }

    return this._POST(url, data)
  }

  /**
   * Получить информацию о состоянии Telegram-уведомлений.
   * @returns {Promise<NotificationChannelI<TelegramPayloadI>>}
   */
  get() {
    const url = `/notification/telegram`

    return this._GET(url)
  }

  /**
   * Отписаться от Telegram-уведомлений.
   * @returns {Promise}
   */
  delete() {
    const url = `/notification/telegram`

    return this._DELETE(url)
  }
})()
