<template>
  <picture :class="$style.wrapper">
    <source v-if="isRendered.default" :srcset="url.default" :alt="alt" />

    <template v-if="isRendered.breakpoints">
      <source
        v-if="isRendered.mobile"
        :srcset="url.mobile"
        :alt="alt"
        media="(max-width: 576px)"
      />
      <source
        v-if="isRendered.tablet"
        :srcset="url.tablet"
        :alt="alt"
        media="(max-width: 992px)"
      />
      <source
        v-if="isRendered.desktop"
        :srcset="url.desktop"
        :alt="alt"
        media="(min-width: 993px)"
      />
    </template>

    <img :src="src" :class="$style.image" :alt="alt" :loading="loading" />
  </picture>
</template>

<script>
import { ApiImage } from 'ApiRest/Api/Image'
import { isNil } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ElImage',

  props: {
    imageId: {
      type: Number,
      default: null,
    },

    src: {
      type: String,
      default: null,
    },

    width: {
      type: Number,
      default: null,
    },

    alt: {
      type: String,
      default: 'image',
    },

    breakpoints: {
      type: Object,
      default: null,
    },

    loading: {
      type: String,
      default: '',
    },
  },

  computed: {
    isRendered() {
      return {
        breakpoints: this.breakpoints !== null && this.imageId,
        mobile: !isNil(this.breakpoints?.mobile),
        tablet: !isNil(this.breakpoints?.tablet),
        desktop: !isNil(this.breakpoints?.desktop),
        default: this.width !== null && this.imageId,
      }
    },

    url() {
      return {
        mobile: ApiImage.get(this.imageId, this.breakpoints?.mobile),
        tablet: ApiImage.get(this.imageId, this.breakpoints?.tablet),
        desktop: ApiImage.get(this.imageId, this.breakpoints?.desktop),
        default: ApiImage.get(this.imageId, this.width),
      }
    },
  },
})
</script>

<style lang="scss" module>
.wrapper {
  display: block;
  width: 100%;
}

.image {
  max-width: 100%;
}
</style>
