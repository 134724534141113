import ApiBase from 'ApiRest/ApiBase'

export const ApiHasFamilyInvoice = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/invoices/has-family-invoice`)
  }
})()
