import ModalNotifyTimezone from '@/components/common/ModalNotifyTimezone.vue'
import { isAfter } from 'date-fns'
import { isNil } from 'lodash'
import { computed } from 'vue'
import { useModal } from 'vue-final-modal'
import { useStore } from 'vuex'

const showModalNotifyTimezoneKey = 'showModalNotifyTimezone'
const endTime = '2024-10-01T00:00:00Z'

/**
 * TODO: Удалить после 01.10.24
 * @returns {{init: init}}
 */
export const useModalNotifyTimezone = () => {
  const store = useStore()

  const serverTimestamp = computed(() => store.state.helper.serverTimestamp)

  const isStudent = computed(() => store.getters['common/user/isStudent'])

  const showModalNotifyTimezone = computed(() => {
    let showModal

    if (isStudent.value) {
      showModal =
        store.state.student.profile.userSettings?.data?.[
          showModalNotifyTimezoneKey
        ]
    } else {
      showModal =
        store.state.client.profile.userSettings?.data?.[
          showModalNotifyTimezoneKey
        ]
    }

    return isNil(showModal) || showModal
  })

  const isUserTimezoneSameAsDefault = computed(
    () => store.getters['helper/isUserTimezoneSameAsDefault'],
  )

  /**
   *
   * @param {string} roteName
   * @returns {any}
   */
  const getRouteNameOnUser = (roteName) =>
    computed(() => store.getters['common/user/getRouteNameOnUser'](roteName))

  /**
   *
   * @param {boolean} value
   */
  async function updateUserSettings(value) {
    if (isStudent.value) {
      await store.dispatch('student/profile/updateUserSettings', {
        [showModalNotifyTimezoneKey]: !value,
      })
    } else {
      await store.dispatch('client/profile/updateUserSettings', {
        [showModalNotifyTimezoneKey]: !value,
      })
    }
  }

  const modalNotifyTimezone = useModal({
    component: ModalNotifyTimezone,
    attrs: {
      to: {
        name: getRouteNameOnUser('profile').value,
      },
      onChange(value) {
        updateUserSettings(value)
      },
    },
  })

  /**
   * Инициализация модального окна
   */
  function init() {
    if (
      !isUserTimezoneSameAsDefault.value &&
      !isAfter(serverTimestamp.value, new Date(endTime)) &&
      showModalNotifyTimezone.value
    ) {
      modalNotifyTimezone.open()
    }
  }

  return {
    init,
  }
}
