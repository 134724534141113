import client from '@/store/courses/client/store'
import common from '@/store/courses/common/store'
import student from '@/store/courses/student/store'

export default {
  namespaced: true,

  modules: {
    client,
    common,
    student,
  },
}
