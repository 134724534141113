import Constants from 'Constants'

export class StudentEducationType {
  static isFullTime(student) {
    return student.educationType?.id === Constants.educationType.FULL_TIME
  }

  static isExtramural(student) {
    return student.educationType?.id === Constants.educationType.EXTRAMURAL
  }

  static isFamily(student) {
    return student.educationType?.id === Constants.educationType.FAMILY
  }
}
