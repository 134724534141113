import actions from '@/store/courses/common/catalog/actions'
import getters from '@/store/courses/common/catalog/getters'
import mutations from '@/store/courses/common/catalog/mutations'
import state from '@/store/courses/common/catalog/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
