import ApiBase from 'ApiRest/ApiBase'

export const ApiIntramuralDiagnosticsAttachment = new (class extends ApiBase {
  /**
   *
   * @param {number} intramuralDiagnosticsId
   * @param {number} fileId
   * @returns {Promise}
   */
  get(intramuralDiagnosticsId, fileId) {
    const url = `/account/student/diagnostics/download-intramural-diagnostics-attachment/${intramuralDiagnosticsId}/${fileId}`

    return this._GET(url, {
      responseType: 'blob',
    })
  }
})()
