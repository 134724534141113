import {
  types as calendarItemTypes,
  formatItem as formatCalendarItem,
  formatCalendarItemDates,
} from '@/helpers/Calendar'
import { cloneDeep } from 'lodash'

export default {
  events(state, getters, rootState, rootGetters) {
    const timezone = rootGetters['helper/timezone']

    return cloneDeep(state.items)
      .filter(
        (item) =>
          !(
            Number(item?.type?.id) === calendarItemTypes.ONLINE_LESSON &&
            item?.name === ''
          ) &&
          // TODO: Временно скрыты контрольные работы (item.type.id === 1) в рамках задачи https://redmine.ibls.tech/issues/8720
          Number(item?.type?.id) !== 1,
      )
      .map((item) => formatCalendarItemDates(item, timezone))
      .map((item) => formatCalendarItem(item))
  },
}
