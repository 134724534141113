import ApiUpcomingEventsList from 'ApiRest/Api/Account/Student/UpcomingEvents/List'
import ApiUnpassedCount from 'ApiRest/Api/Account/Student/UpcomingEvents/UnpassedCount'

export default {
  async fetchEvents({ commit }, { student, page, types = [], date = null }) {
    const filters = {
      student,
      page,
    }

    if (types.length > 0) {
      filters.types = types
    }

    if (date !== null) {
      if (date.dateStart !== null) {
        filters.dateStart = date.dateStart
      }

      if (date.dateEnd !== null) {
        filters.dateEnd = date.dateEnd
      }
    }

    let data = []
    let headers = null

    try {
      const response = await ApiUpcomingEventsList.get(filters)

      data = response.data
      headers = response.headers
    } finally {
      commit('setEventsPagination', headers || {})
      commit('setEventsPage', page)
      commit('setEvents', data || [])
      commit('setLoading', {
        loadingFieldName: 'events',
        value: false,
      })
      commit('setLoading', {
        loadingFieldName: 'btnMore',
        value: false,
      })
    }
  },

  async fetchUnpassedCount({ commit }, { student }) {
    const params = {
      student,
    }

    const { data } = await ApiUnpassedCount.get(params)

    commit('setUnpassedCount', data)
  },
}
