export const studentSubjectRoutes = [
  {
    path: 'subjects',
    name: 'subjects',
    component: () => import('@/pages/student/subject/Subjects.vue'),
  },
  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)',
    name: 'subject',
    component: () => import('@/pages/student/subject/TheSubject.vue'),
  },
  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)/bibliography',
    name: 'subject-bibliography',
    component: () =>
      import('@/pages/student/subject/TheSubjectBibliography.vue'),
  },
  {
    path: 'subjects/:subjectId(\\d+)/groups/:groupId(\\d+)/lesson/:lessonId(\\d+)/:homeworkSolutionId(\\d+)?',
    name: 'subject-lesson',
    component: () => import('@/pages/student/TheLesson.vue'),
  },
]
