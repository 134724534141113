export default () => ({
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  grade: null,
  educationType: null,
  demoGrades: [],
  demoFormEducationTypes: [],
})
