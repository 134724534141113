import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/student/special-statements`)
  }
})()
