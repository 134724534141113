export const clientTestRoutes = [
  {
    name: 'client-showcase-test-hello-ctp',
    path: '/client/test/ctp/:ctpTestId(\\d+)',
    component: () => import('@/pages/showcase/common/test/TestHello.vue'),
  },

  {
    name: 'client-showcase-test-hello-fte-ctp',
    path: '/client/test/fte-ctp/:fteCtpTestId(\\d+)',
    component: () => import('@/pages/showcase/common/test/TestHello.vue'),
  },

  {
    name: 'client-showcase-test-hello-fte-preliminary',
    path: '/client/test/fte-preliminary/:ftePreliminaryTestId(\\d+)',
    component: () => import('@/pages/showcase/common/test/TestHello.vue'),
  },

  {
    name: 'client-showcase-test-hello-fte-final',
    path: '/client/test/fte-final/:fteFinalTestId(\\d+)',
    component: () => import('@/pages/showcase/common/test/TestHello.vue'),
  },

  {
    name: 'client-showcase-test-preview-hello',
    path: '/client/test/preview/:testId(\\d+)/:variantNumber(\\d+)',
    component: () =>
      import('@/pages/showcase/common/test/TestPreviewHello.vue'),
  },

  {
    name: 'client-showcase-test-preview-start',
    path: '/test/preview/:testId(\\d+)/:variantNumber(\\d+)/start',
    component: () =>
      import('@/pages/showcase/common/test/TestPreviewStart.vue'),
  },

  {
    name: 'client-showcase-test-start-ctp',
    path: '/client/test/ctp/:ctpTestId(\\d+)/start/:attemptId(\\d+)?',
    component: () => import('@/pages/showcase/common/test/TestStart.vue'),
  },
  {
    name: 'client-showcase-test-start-cert',
    path: '/client/test/cert/:certTestId(\\d+)/start',
    component: () => import('@/pages/showcase/common/test/TestStart.vue'),
  },
  {
    name: 'client-showcase-test-start-fe-cert',
    path: '/client/test/fe-cert/:feCertTestId(\\d+)/start',
    component: () => import('@/pages/showcase/common/test/TestStart.vue'),
  },
  {
    name: 'client-showcase-test-start-fte-ctp',
    path: '/client/test/fte-ctp/:fteCtpTestId(\\d+)/start',
    component: () => import('@/pages/showcase/common/test/TestStart.vue'),
  },
  {
    name: 'client-showcase-test-start-fte-preliminary',
    path: '/client/test/fte-preliminary/:ftePreliminaryTestId(\\d+)/start',
    component: () => import('@/pages/showcase/common/test/TestStart.vue'),
  },
  {
    name: 'client-showcase-test-start-fte-final',
    path: '/client/test/fte-final/:fteFinalTestId(\\d+)/start',
    component: () => import('@/pages/showcase/common/test/TestStart.vue'),
  },
]
