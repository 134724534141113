export default () => ({
  statements: [],
  theStatement: {
    subject: null,
    periods: [],
  },

  academicYears: [],
  theAcademicYear: null,

  itemsFilter: null,
})
