import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Список учебных годов у ученика очной формы обучения
   * @param {number} studentId - ID студента
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(
      `/account/client/students/${studentId}/full-time-ctp-statement/academic-years`,
    )
  }
})()
