import { ApiOnlineSchoolPurchaseMarkWithMaternityCapital } from 'ApiRest/Api/OnlineSchool/Invoice/MarkWithMaternityCapital'
import { ApiOnlineSchoolOfferDocList } from 'ApiRest/Api/OnlineSchool/Invoice/OfferDocList'
import { ApiOnlineSchoolPurchaseItemDocument } from 'ApiRest/Api/OnlineSchool/Purchase/Item/document/PurchaseItemDocument'

export default {
  async fetchOfferDocs({ commit }, invoiceId) {
    const { data } = await ApiOnlineSchoolOfferDocList.get(invoiceId)

    commit('setOfferDocs', data)
  },

  async fetchOfferDoc(
    { commit },
    { id: documentTemplateId, purchaseId, purchaseItemId },
  ) {
    const params = {
      purchaseId,
      purchaseItemId,
      documentTemplateId,
    }

    const { data } = await ApiOnlineSchoolPurchaseItemDocument.getDocx(params)

    commit('setOfferDocBlob', {
      id: documentTemplateId,
      payload: data,
    })
  },

  async markPurchaseWithMaternityCapital(context, purchaseId) {
    await ApiOnlineSchoolPurchaseMarkWithMaternityCapital.put(purchaseId)
  },
}
