import ApiGoalList from 'Api/rest/Api/Account/Client/Goal/List'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchGoals({ commit }, studentId) {
    const { data } = await ApiGoalList.get(studentId)

    commit('setGoals', data)
  },
}
