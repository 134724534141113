import actions from '@/store/showcase/common/purchase/actions'
import getters from '@/store/showcase/common/purchase/getters'
import mutations from '@/store/showcase/common/purchase/mutations'
import state from '@/store/showcase/common/purchase/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
