export const studentCourseRoutes = [
  {
    path: 'courses',
    component: () => import('@/pages/courses/common/Courses.vue'),

    children: [
      {
        path: '',
        name: 'student-courses',
        component: () => import('@/pages/courses/common/Home.vue'),
      },

      {
        path: 'catalog',
        name: 'student-courses-catalog',
        component: () => import('@/pages/courses/common/Catalog.vue'),
      },

      {
        path: 'products/:productId(\\d+)',
        name: 'student-courses-product',
        component: () => import('@/pages/courses/common/Product.vue'),
      },

      {
        path: 'collections/:collectionId(\\d+)',
        name: 'student-courses-collection',
        component: () => import('@/pages/courses/common/Collection.vue'),
      },

      {
        path: 'cart',
        name: 'student-courses-cart',
        component: () => import('@/pages/courses/common/Cart.vue'),
      },

      {
        path: 'purchases',
        name: 'student-courses-purchases',
        component: () => import('@/pages/courses/common/Purchases.vue'),
      },

      {
        path: 'purchases/:purchaseId(\\d+)',
        name: 'student-courses-the-purchase',
        component: () => import('@/pages/courses/student/Purchase.vue'),
      },

      {
        path: 'purchases/:purchaseId(\\d+)/items/:purchaseItemId(\\d+)',
        name: 'student-courses-product-purchased',
        component: () => import('@/pages/courses/common/ProductPurchased.vue'),
        children: [
          {
            path: 'materials',
            name: 'student-courses-product-purchased-materials',
            component: () => import('@/pages/courses/common/Materials.vue'),
          },
        ],
      },

      {
        path: '/cart/:offerId(\\d+)',
        name: 'student-courses-product-link',
        component: () => import('@/pages/courses/common/ProductLink.vue'),
      },
    ],
  },
]
