import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setHomeworkDates(state, payload) {
    state.homeworkDates = payload
  },
}
