export default {
  data() {
    return {
      opened: false,
      mobileMenuTopOffsetPx: 0,
    }
  },

  watch: {
    /**
     * @description для моб версии: закрывать меню при смене роута
     */
    $route() {
      this.closeMobileMenu()
    },
  },

  mounted() {
    this.setHeightNoticedMobileMenu()

    window.addEventListener('resize', this.setHeightNoticedMobileMenu)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.setHeightNoticedMobileMenu)
  },

  computed: {
    mobileMenuTopOffset() {
      return {
        top: `${this.mobileMenuTopOffsetPx}px`,
        height: `calc(100vh - ${this.mobileMenuTopOffsetPx}px)`,
      }
    },
  },

  methods: {
    setHeightNoticedMobileMenu() {
      this.mobileMenuTopOffsetPx = this.$refs.header.clientHeight
    },

    openMobileMenu() {
      this.opened = true
      window.scrollTo(0, 0)
      document.body.style.overflow = 'hidden'
    },

    closeMobileMenu() {
      this.opened = false
      document.body.style.overflow = ''
    },

    toggleMobileMenu() {
      this.opened ? this.closeMobileMenu() : this.openMobileMenu()
    },
  },
}
