import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSubjects(state, subjects) {
    state.subjects = subjects
  },

  setSubjectDetail(state, ctp) {
    state.subject = ctp
  },

  setClassBookUrl(state, url) {
    state.classBookUrl = url
  },

  setLastLesson(state, data) {
    state.lastLesson = data
  },
}
