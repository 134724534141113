import dateFormatHelper from '@/helpers/dateFormatHelper'
import { isValid, isWithinInterval, parseISO } from 'date-fns'

export default {
  getAvailableCertifications: (state) =>
    state.certifications.filter((attr) => {
      let withinInterval = false

      attr.periods.forEach((period) => {
        const startDateTime = parseISO(
          dateFormatHelper.toIso(period.startDateTime),
        )
        const endDateTime = parseISO(dateFormatHelper.toIso(period.endDateTime))

        if (
          isWithinInterval(new Date(), {
            start: startDateTime,
            end: endDateTime,
          }) &&
          !withinInterval &&
          [
            startDateTime,
            endDateTime,
          ].every(isValid)
        ) {
          withinInterval = true
        }
      })

      return withinInterval
    }),

  getCurrentCertification: (state, getters) => (id) => {
    if (id) {
      return getters.getAvailableCertifications.find(
        (i) => Number(i.id) === Number(id),
      )
    }

    if (getters.getAvailableCertifications[0]) {
      return getters.getAvailableCertifications[0]
    }

    return undefined
  },

  getCurrentCertificationStatus: (state, getters) => (id) => {
    const { tests } = getters.getCurrentCertification(id)
    const isCompleted = !tests.some((i) => i.testing?.isPassed !== true)

    if (isCompleted) {
      return {
        isCompleted: true,
      }
    }

    const testsTotal = tests.length
    let testsFailed = 0
    let testsNotPassed = 0
    let testsPassed = 0

    Object.keys(tests).forEach((test) => {
      if (
        test.testing?.attemptsUsed !== 0 &&
        test.testing?.isPassed === false
      ) {
        testsFailed += 1
      }

      if (
        test.testing?.attemptsUsed === 0 &&
        test.testing?.isPassed === false
      ) {
        testsNotPassed += 1
      }

      if (test.testing?.isPassed === true) {
        testsPassed += 1
      }
    })

    return {
      isCompleted: false,
      testsNotPassed,
      testsFailed,
      testsPassed,
      testsTotal,
    }
  },
}
