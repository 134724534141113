import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} contentId
   * @returns {Promise}
   */
  get(contentId) {
    return this._GET(`/library/contents/${contentId}/preview`)
  }
})()
