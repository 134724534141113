import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setGoals(state, goals) {
    state.goals = goals
  },

  addTheGoal(state, payload) {
    state.goals.push({
      ...payload,
      isReached: false,
    })
  },

  setTheGoalDeleted(state, id) {
    state.goals = state.goals.filter((goal) => goal.id !== id)
  },

  setTheGoalCompleted(state, { id, isReached = false }) {
    state.goals.find((goal) => goal.id === id).isReached = isReached
  },

  resetTheGoalFields(state) {
    Object.keys(state.theGoal).forEach((i) => {
      state.theGoal[i] = ''
    })
  },

  setTheGoalValue(state, { key, value }) {
    state.theGoal[key] = value
  },
}
