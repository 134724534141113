import { StudentEducationType } from '@/helpers/StudentEducationType'
import { StudentEnrollmentStatus } from '@/helpers/StudentEnrollmentStatus'

export const hasJustPaidContractDemoFeSimple = function (student) {
  return Boolean(
    StudentEducationType.isFamily(student) &&
      StudentEnrollmentStatus.isEnrolled(student) &&
      student.isSimpleFeDemo &&
      !student.isDemo &&
      !student.hasAwaitingPayment &&
      !student.birthDate,
  )
}
