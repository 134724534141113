import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesProduct = new (class extends ApiBase {
  /**
   * @param {number} productId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(productId, parameters) {
    const url = `/courses/products/${productId}?${this.getUrlParams(parameters)}`

    return this._GET(url)
  }
})()
