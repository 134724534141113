export default () => ({
  userSettings: {},
  profile: {},
  school: {},
  notifications: {},
  events: {
    telegramBot: {
      isLinked: false,
      link: null,
    },
  },

  options: {
    timezones: [],
  },
})
