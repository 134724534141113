import ApiHomeworkSolutions from 'ApiRest/Api/Account/Client/Students/HomeworkSolution/List'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchHomeworkDates({ commit }, studentId) {
    const { data } = await ApiHomeworkSolutions.get(studentId)

    commit('setHomeworkDates', data)
  },
}
