import { ApiEducontRegistration } from 'Api/rest/Api/Educont/Registration'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  register({ state }) {
    return ApiEducontRegistration.post(state.registerData)
  },
}
