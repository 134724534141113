import HttpHeaders from 'Api/const/HttpHeaders'
import { range } from 'lodash'

/**
 * @typedef {Record} PaginationParams
 * @property {number} page - Номер текущей страницы
 * @property {number} pagesTotal - Общее количество страниц
 * @property {number} rowsTotal - Общее количество элементов
 * @property {number} rowsOnPage - Количество элементов на странице (по факту)
 * @property {number} rowsPerPage - Количество элементов на страницу (выбранное)
 * @property {number[]} rowsPerPageOptions - Список опций для выбора количества элементов на страницу
 */

/**
 * Конфигурация CompositePagination
 */
export class Pagination {
  /**
   * @param {PaginationParams} params
   */
  constructor({
    page = 1,
    rowsPerPage = 10,
    rowsPerPageOptions = [
      10,
      20,
      40,
      60,
    ],
    pagesTotal = 0,
    rowsTotal = 0,
    rowsOnPage = 0,
  } = {}) {
    this.page = page
    this.rowsPerPage = rowsPerPage
    this.rowsPerPageOptions = rowsPerPageOptions
    this.pagesTotal = pagesTotal
    this.rowsTotal = rowsTotal
    this.rowsOnPage = rowsOnPage
  }

  get pageOptions() {
    return range(1, this.pagesTotal + 1)
  }

  /**
   * @param {Object} headers
   */
  updateFromHeaders(headers) {
    this.pagesTotal = Number(headers[HttpHeaders.PAGINATION_PAGES_TOTAL]) || 0
    this.rowsTotal = Number(headers[HttpHeaders.PAGINATION_ROWS_TOTAL]) || 0
    this.rowsOnPage = Number(headers[HttpHeaders.PAGINATION_ROWS_ON_PAGE]) || 0
  }

  /**
   * @param {number} value
   */
  setPage(value) {
    this.page = value
  }

  /**
   * @param {number} value
   */
  setRowsPerPage(value) {
    this.rowsPerPage = value
  }
}
