import ApiBase from 'ApiRest/ApiBase'

export const ApiSubscriptionCancel = new (class extends ApiBase {
  /**
   *
   * @param {number} subscriptionId
   * @returns {Promise}
   */
  put(subscriptionId) {
    return this._PUT(
      `/account/client/user-subscriptions/${subscriptionId}/cancel`,
    )
  }
})()
