import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportIdeaLike = new (class extends ApiBase {
  /**
   * @param {number} ideaId
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(ideaId, parameters) {
    const url = `/support/ideas/${ideaId}/like?${UrlHelper.param(parameters)}`

    return this._POST(url)
  }
})()
