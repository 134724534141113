import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentShowcaseCtpStatementAcademicYears =
  new (class extends ApiBase {
    /**
     * Список учебных годов для ведомостей КТП, приобретенных через внутреннюю/внешнюю витрину
     * @returns {Promise}
     */
    get() {
      return this._GET(`/account/student/showcase-ctp-statement/academic-years`)
    }
  })()
