import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolCheckout = new (class extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/online-school/purchase/checkout', data)
  }
})()
