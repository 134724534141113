export default {
  setCategories(state, payload) {
    state.categories = payload
  },

  setUpperCategories(state, payload) {
    state.upperCategories = payload
  },

  setCategoriesLite(state, payload) {
    state.categoriesLite = payload
  },
}
