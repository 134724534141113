import ApiGoal from 'Api/rest/Api/Account/Student/Goal'
import ApiGoalsAchieved from 'Api/rest/Api/Account/Student/Goal/Achieved'
import ApiGoalList from 'Api/rest/Api/Account/Student/Goal/List'
import ApiGoalsNotAchieved from 'Api/rest/Api/Account/Student/Goal/NotAchieved'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async completeTheGoal({ commit }, payload) {
    const { isReached, id } = payload
    const apiEndPoint = isReached ? ApiGoalsAchieved : ApiGoalsNotAchieved

    await apiEndPoint.put(id).then(() => {
      commit('setTheGoalCompleted', payload)
    })
  },

  async deleteTheGoal({ commit }, id) {
    await ApiGoal.delete(id).then(() => {
      commit('setTheGoalDeleted', id)
    })
  },

  async createTheGoal({ commit }, payload) {
    await ApiGoal.post(payload).then(({ data }) => {
      commit('addTheGoal', data)
      commit('resetTheGoalFields')
    })
  },

  async updateTheGoal(context, payload) {
    await ApiGoal.patch(payload.id, payload)
  },

  /**
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @returns {Promise<*|boolean>}
   */
  async fetchGoals({ state, commit }) {
    if (state.isLoading) {
      return false
    }

    const { data } = await ApiGoalList.get()

    commit('setGoals', data)

    return true
  },
}
