<template>
  <div class="loader-circle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150px"
      height="150px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke-width="1"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoaderCircle',
}
</script>

<style lang="scss">
@import '~@/scss/variables';

@keyframes loader-circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-circle {
  svg {
    display: block;
    margin: auto;
    shape-rendering: auto;
    animation: 1s linear 0s infinite loader-circle;

    circle {
      stroke: $blue;
    }
  }
}
</style>
