import actions from '@/store/courses/common/home/actions'
import getters from '@/store/courses/common/home/getters'
import mutations from '@/store/courses/common/home/mutations'
import state from '@/store/courses/common/home/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
