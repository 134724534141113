import { hasJustPaidContractDemoFeSimple } from '@/helpers/demoFeSimple/student'
import { isMobileDomain } from '@/helpers/domain'
import { hasJustPaidContractFe } from '@/helpers/fe/student'
import { feature } from '@/helpers/feature'
import Constants from 'Constants'
import { isEmpty, isNil } from 'lodash'

const filterTypes = {
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
  ADDITIONAL: 'ADDITIONAL',
  EXTRACURRICULAR: 'EXTRACURRICULAR',
  ONLINE_SCHOOL: 'ONLINE_SCHOOL',
}

const _filterBySubjectTypeExtracurricular = (subject) =>
  subject.showType === Constants.showType.PRODUCT_MANAGEMENT ||
  subject.showType === Constants.showType.COURSE_MANAGEMENT

const _filterBySubjectTypeOnlineSchool = (subject) =>
  subject.showType === Constants.showType.ONLINE_SCHOOL

export default {
  _filterSubjects() {
    return (filterType) => {
      switch (filterType) {
        case filterTypes.EXTRACURRICULAR:
          return _filterBySubjectTypeExtracurricular

        case filterTypes.ONLINE_SCHOOL:
          return _filterBySubjectTypeOnlineSchool

        default:
          return []
      }
    }
  },

  hasStudents(state) {
    return state.students.length > 0
  },

  hasStudentJustPaidContractFe(state, getters) {
    return getters.studentJustPaidContractFe !== null
  },

  hasStudentJustPaidContractDemoFeSimple(state, getters) {
    return getters.studentJustPaidContractDemoFeSimple !== null
  },

  hasEnrolledStudents(state, getters) {
    return getters.enrolledStudents.length > 0
  },

  studentJustPaidContractFe(state) {
    return state.students.find(hasJustPaidContractFe) ?? null
  },

  studentJustPaidContractDemoFeSimple(state) {
    return state.students.find(hasJustPaidContractDemoFeSimple) ?? null
  },

  enrolledStudents(state) {
    const isActive = (student) =>
      student.accountStatus?.id === Constants.accountStatus.ACTIVE
    const isEnrolled = (student) =>
      student.enrollmentStatus?.id === Constants.enrollmentStatus.ENROLLED

    return state.students.filter(
      (student) =>
        student.hasPaidOnlineSchool ||
        (isActive(student) &&
          (isEnrolled(student) || student.hasIncomeDiagnostics)),
    )
  },

  schoolPolicyFileUrl(state) {
    return state.school?.policyFile?.fileUrl ?? ''
  },

  schoolPDFileUrl(state) {
    return state.school?.personalDataFile?.fileUrl
  },

  /**
   * @param {Object} state
   * @returns {Object}
   */
  lastMonthStat(state) {
    return state.analytics?.lastMonthStat || {}
  },

  currentStudentStreamAcademicYearPreset(state) {
    return state.currentStudent?.streamAcademicYearPreset
  },

  isCurrentStudentDemo(state) {
    return Boolean(state.currentStudent?.isDemo)
  },

  getCurrentStudentDemoAccessTime(state) {
    return Number(state.currentStudent?.demoAccessTime)
  },

  getCurrentStudentSchoolDemoMessage(state) {
    let demoMessage = ''

    switch (state.currentStudent?.educationType?.id) {
      case Constants.educationType.EXTRAMURAL:
        demoMessage = isMobileDomain
          ? ''
          : state.currentStudent?.school?.demoMessage
        break
      case Constants.educationType.FAMILY:
        demoMessage = isMobileDomain
          ? ''
          : state.currentStudent?.school?.demoMessageFe
        break

      default:
        break
    }

    return demoMessage
  },

  currentStudentId(state) {
    return state.currentStudent.id
  },

  educationType(state) {
    const { EXTRAMURAL, FAMILY, FULL_TIME } = Constants.educationType
    let id = state.currentStudent?.educationType?.id

    const hasOnlineSchool = state.currentStudent?.hasPaidOnlineSchool

    if (
      hasOnlineSchool &&
      id !== FAMILY &&
      id !== EXTRAMURAL &&
      id !== FULL_TIME
    ) {
      id = EXTRAMURAL
    }

    return {
      extramural: id === EXTRAMURAL,
      family: id === FAMILY,
      fullTime: id === FULL_TIME,
    }
  },

  hasEducationType(state) {
    return !isNil(state.currentStudent?.educationType)
  },

  isStudentEnrolledAndHasDiagnostics(state) {
    return (
      feature.isIncomeDiagnostics &&
      state.currentStudent?.enrollmentStatus?.id !==
        Constants.enrollmentStatus.ENROLLED &&
      state.currentStudent?.hasIncomeDiagnostics
    )
  },

  canStudentsJoinLibrary(state) {
    return state.students.some((student) => student.canJoinLibrary)
  },

  getSubjectsExtracurricular(state, getters) {
    return state.activeSubjects.filter(
      getters._filterSubjects(filterTypes.EXTRACURRICULAR),
    )
  },

  getSubjectsOnlineSchool(state, getters) {
    return state.activeSubjects.filter(
      getters._filterSubjects(filterTypes.ONLINE_SCHOOL),
    )
  },

  hasSubjectsExtracurricular(state, getters) {
    return !isEmpty(getters.getSubjectsExtracurricular)
  },

  hasSubjectsOnlineSchool(state, getters) {
    return !isEmpty(getters.getSubjectsOnlineSchool)
  },

  hasPaidOnlineSchoolCurrentStudent(state) {
    return state.currentStudent?.hasPaidOnlineSchool
  },

  hasCurrentStudentOnlineSchool(state) {
    return state.currentStudent?.hasOnlineSchool
  },

  isCurrentSudentEnrolled(state) {
    return (
      state.currentStudent?.enrollmentStatus?.id ===
      Constants.enrollmentStatus.ENROLLED
    )
  },

  hasMainSubjects(state) {
    return state.activeSubjects.some(
      (subject) =>
        subject.showType !== Constants.showType.PRODUCT_MANAGEMENT &&
        subject.showType !== Constants.showType.COURSE_MANAGEMENT &&
        subject.showType !== Constants.showType.ONLINE_SCHOOL,
    )
  },
}
