import ApiBase from 'ApiRest/ApiBase'

export default new (class CommonContract extends ApiBase {
  /**
   * @param {number} contractId
   * @returns {Promise}
   */
  get(contractId) {
    return this._GET(`/account/client/common-contracts/${contractId}`)
  }
})()
