export default () => ({
  /** @type {CourseBanner[]} */
  banners: [],

  /** @type {CourseProduct[]} */
  productsLatest: [],

  /** @type {CourseProduct[]} */
  productsPopular: [],
})
