import { LangSwitchModuleMixin } from '@/mixins/LangSwitchModuleMixin'

export const LangSwitchModuleClientMixin = {
  mixins: [
    LangSwitchModuleMixin,
  ],

  computed: {
    isAvailableLangSwitchForCurrentStudent() {
      if (this.currentStudent?.languages) {
        return this.currentStudent.languages.showInClientAccount ?? false
      }

      return this.isAvailableLangSwitch
    },

    langOptionDefault() {
      const defaultLanguage =
        this.currentStudent?.languages?.defaultLanguage ?? null
      const defaultLanguageFromModule =
        this.getLangSwitchModuleValue('defaultLanguage') ?? null

      switch (true) {
        case !!defaultLanguage:
          return defaultLanguage

        case !!defaultLanguageFromModule:
          return defaultLanguageFromModule

        case this.langOptions.length > 0:
          return this.langOptions[0]

        default:
          return null
      }
    },

    langOptionsSecond() {
      const secondLanguage =
        this.currentStudent?.languages?.secondLanguage ?? []
      const secondLanguageFromModule =
        this.getLangSwitchModuleValue('secondLanguage') ?? []

      switch (true) {
        case secondLanguage.length > 0:
          return secondLanguage

        case secondLanguageFromModule.length > 0:
          return secondLanguageFromModule

        default:
          return []
      }
    },
  },

  methods: {
    /**
     * @public
     * @returns {Promise<void>}
     */
    async initLang() {
      if (
        !this.lang ||
        !this.isAvailableLangSwitchForCurrentStudent ||
        !this.isAvailableLang
      ) {
        this.lang = this.langOptionsAvailable[0]
      } else {
        this.setLang(this.lang.code)
        await this.changeLocale()
      }
    },
  },
}
