import { ApiAuthorize } from 'Api/rest/Api/Educont/Authorize'
import { ApiRedirectUrl } from 'Api/rest/Api/Educont/RedirectUrl'

export default {
  async fetchRedirectUrl({ commit }) {
    const { data } = await ApiRedirectUrl.post()

    commit('setRedirectUrl', data.url)
  },

  async authorize({ commit }, payload) {
    const { data } = await ApiAuthorize.post(payload)

    commit('setAuthorizeData', data)
  },
}
