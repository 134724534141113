<template>
  <div class="form-group" :class="[formGroupClass, wrapperClass]">
    <label class="checkbox mb-0">
      <input
        v-bind="$attrs"
        ref="input"
        v-model="model"
        :value="value"
        :disabled="disabled"
        type="checkbox"
      />

      <span class="check" :class="{ line: iconLine }"></span>

      <slot name="label">{{ label }}</slot>

      <span v-if="required" class="text-pink">&nbsp;*</span>
    </label>

    <div v-if="invalid" class="form-invalid mt-1">
      <slot name="invalid">
        <template v-if="hasErrors">
          <div v-for="error in errors" :key="error.$uid" class="mb-1">
            {{ error.$message }}
          </div>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ElCheckbox',

  inheritAttrs: false,

  props: {
    modelValue: {
      type: [
        Boolean,
        Array,
        Object,
      ],

      required: true,
    },

    value: {
      type: null,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    iconLine: {
      type: Boolean,
      default: false,
    },

    wrapperClass: {
      type: [
        String,
        Array,
      ],

      default: undefined,
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    model: {
      get() {
        return this.modelValue
      },

      set(value) {
        this.$emit('update:modelValue', value)
      },
    },

    formGroupClass() {
      return {
        'is-invalid': this.invalid,
      }
    },

    hasErrors() {
      return this.errors.length > 0
    },
  },
})
</script>
