import {
  BarChart,
  LineChart,
  PieChart,
  RadarChart,
  ScatterChart,
  SunburstChart,
} from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import ECharts from 'vue-echarts'

export const registerPluginEcharts = (app) => {
  use([
    BarChart,
    PieChart,
    CanvasRenderer,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    VisualMapComponent,
    LineChart,
    MarkLineComponent,
    MarkPointComponent,
    ScatterChart,
    SunburstChart,
    RadarChart,
  ])

  app.component('VChart', ECharts)
}
