/**
 * @typedef {Object} AvatarThumbs
 * @property {string} small - Путь до фотографии 48х48
 * @property {string} medium - Путь до фотографии 80x80
 */

/**
 * @typedef {AvatarThumbs} Avatar
 * @property {number} id - Ид файла
 * @property {string} url - Путь до оригинальной фотографии
 */

/**
 * @typedef {Object} SupportBaseUser
 * @property {number} id - Ид пользователя в системе IBLS
 * @property {string} firstName - Имя
 * @property {string} lastName - Фамилия
 * @property {string} middleName - Отчество
 * @property {Avatar|null} photo - Аватар
 */

/**
 * @typedef {SupportBaseUser} SupportCurator
 * @property {string|null} email - публичный email куратора
 * @property {string|null} phone - публичный телефон куратора
 */

/**
 * @typedef {SupportBaseUser} SupportTeacher
 * @property {string[]} subjects - Предметы
 */

/**
 * @typedef {Object} SupportContact
 * @property {SupportCurator|null} curator - Информация о кураторе
 * @property {SupportContacts[]} supportContacts - Дополнительные контакты
 * @property {SupportTeacher[]} teachers - Учителя
 */

export default () => ({
  /**
   * @type {SupportContact}
   */
  contact: {},
})
