import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class StatementLessons extends ApiBase {
  /**
   * Ведомость по предмету
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(
      `/account/student/extramural-ctp-statement/lessons?${UrlHelper.param(parameters)}`,
    )
  }
})()
