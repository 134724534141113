import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseReceive = new (class extends ApiBase {
  /**
   * Перевод заказа в статус получено
   * @param {number} purchaseId
   * @returns {Promise}
   */
  put(purchaseId) {
    const url = `/purchase/${purchaseId}/receive`

    return this._PUT(url)
  }
})()
