import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setCertifications(state, payload) {
    state.certifications = payload
  },

  setTheCertification(state, payload) {
    const index = state.certifications.findIndex(
      (cert) => cert.id === payload.id,
    )

    state.certifications[index] = payload
  },
}
