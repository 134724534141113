export const chartPeriods = [
  {
    id: 1,
    // TODO: i18n
    name: 'Месяц',
  },
  {
    id: 2,
    // TODO: i18n
    name: 'Неделя',
  },
  {
    id: 3,
    // TODO: i18n
    name: 'День',
  },
]

export const chartPeriodConstants = {
  MONTH: 1,
  WEEK: 2,
  DAY: 3,
}
