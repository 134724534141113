import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportIdeaOptionStatus = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    const url = `/support/ideas/options/statuses?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }
})()
