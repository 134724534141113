import ApiBase from 'ApiRest/ApiBase'

export default new (class Students extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/client/students`)
  }
})()
