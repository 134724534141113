import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportQuestionOnlineLesson = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(payload, parameters = {}) {
    const url = `/account/student/ask-question/online-lesson?${UrlHelper.param(parameters)}`

    return this._POST(url, payload)
  }
})()
