import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLessonSignUp = new (class extends ApiBase {
  /**
   * Записаться на онлайн-урок
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  put(onlineLessonId) {
    return this._PUT(
      `/showcase/account/online-lessons/${onlineLessonId}/signed-up`,
    )
  }
})()
