import ApiBase from 'ApiRest/ApiBase'

export default new (class LessonContentPassNoMaterials extends ApiBase {
  /**
   * @param {number} lessonId
   * @returns {Promise}
   */
  put(lessonId) {
    return this._PUT(`/account/student/lessons/${lessonId}/passed`)
  }
})()
