export const clientShowcaseRoutes = [
  {
    path: '',

    component: () => import('@/layouts/showcase/Showcase.vue'),

    meta: {
      isDisabledScroll: true,
    },

    children: [
      {
        path: '',
        name: 'client-showcase',
        component: () => import('@/pages/showcase/common/Home.vue'),
      },

      {
        path: 'catalog',
        name: 'client-showcase-catalog',
        component: () => import('@/pages/showcase/common/Catalog.vue'),
      },

      {
        path: 'category/:category',
        name: 'client-showcase-category',
        component: () => import('@/pages/showcase/common/Category.vue'),
      },

      {
        path: 'category/:category/:product',
        name: 'client-showcase-product',
        component: () => import('@/pages/showcase/common/Product.vue'),
      },

      {
        path: 'collections/:collectionId(\\d+)',
        name: 'client-showcase-collection',
        component: () => import('@/pages/showcase/common/Collection.vue'),
      },

      {
        path: 'cart',
        name: 'client-showcase-cart',
        component: () => import('@/pages/showcase/common/Cart.vue'),
      },

      {
        path: 'purchases/:purchaseId(\\d+)',
        name: 'client-showcase-the-purchase',
        component: () => import('@/pages/showcase/client/Purchase.vue'),
      },

      {
        path: 'purchases-ad/:purchaseId(\\d+)',
        name: 'client-showcase-the-purchase-ad',
        component: () => import('@/pages/showcase/client/PurchaseAd.vue'),
      },

      {
        path: 'purchases/:purchaseId(\\d+)/items/:purchaseItemId(\\d+)',
        name: 'client-showcase-product-purchased',
        component: () => import('@/pages/showcase/common/ProductPurchased.vue'),
        children: [
          {
            path: 'materials',
            name: 'client-showcase-product-purchased-materials',
            component: () =>
              import('@/pages/showcase/common/ProductMaterials.vue'),
          },
        ],
      },

      {
        path: '/cart/:offerId(\\d+)',
        name: 'client-showcase-product-link',
        component: () => import('@/pages/showcase/common/ProductLink.vue'),
      },

      {
        path: '/client/product-access-link/category/:category/:product',
        name: 'client-showcase-product-access-link',
        component: () => import('@/pages/showcase/common/Product.vue'),
      },
    ],
  },
]
