import { EnrollmentStatusHelper } from '@/helpers/EnrollmentStatusHelper'
import ApiCommonContractList from 'ApiRest/Api/Account/Client/CommonContract/List'
import ApiRequestsDraftList from 'ApiRest/Api/Account/Client/RequestDraft/List'

export default {
  async fetchContracts({ commit }) {
    let { data } = await ApiCommonContractList.get()

    data = EnrollmentStatusHelper.replaceStatusNameWithPath(data)

    commit('setContracts', data)
  },

  async fetchDrafts({ commit }) {
    const { data } = await ApiRequestsDraftList.get()

    commit('setDrafts', data)
  },
}
