export default () => ({
  promoCampaign: {
    description: '',
    name: '',
    product: {
      description: '',
      image: null,
      name: '',
    },
  },

  promoProducts: [],
  promoCtps: [],
})
