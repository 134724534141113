import ApiBase from 'ApiRest/ApiBase'

export const ApiImage = new (class extends ApiBase {
  /**
   *
   * @param {number} id
   * @param {number} width
   * @returns {string}
   */
  get(id, width) {
    return `${this.BASE_URL}/image/${id}/${width}`
  }
})()
