import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesCartCheckout = new (class extends ApiBase {
  /**
   * @typedef {Object} cartItem
   * @property {number} id
   * @property {Object} consumer
   * @property {number} consumer.id
   */

  /**
   * @param {Object} payload
   * @param {cartItem[]} payload.items
   * @param {boolean} payload.withMaternityCapital
   * @returns {Promise}
   */
  post(payload) {
    const url = '/courses/cart/checkout'

    return this._POST(url, payload)
  }
})()
