export default () => ({
  form: null,
  isAccepted: true,
  promocodeDetails: null,
  paymentType: null,
  offerText: null,
  serviceDetails: null,
  invoice: null,
  customerReceipt: null,
})
