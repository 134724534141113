export default {
  // defaults
  CONTENT_DISPOSITION: 'content-disposition',
  CONTENT_TYPE: 'content-type',

  // page
  PAGE_URL: 'X-Page-Url',

  // pagination
  PAGINATION_PAGE: 'x-pagination-page',
  PAGINATION_PAGES_TOTAL: 'x-pagination-pages-total',
  PAGINATION_ROWS_TOTAL: 'x-pagination-rows-total',
  PAGINATION_ROWS_PER_PAGE: 'x-pagination-rows-per-page',
  PAGINATION_ROWS_ON_PAGE: 'x-pagination-rows-on-page',

  // contracts, invoices
  OVERALL_PAYMENT_SUM: 'x-overall-payment-sum',
  OVERALL_DEBT_SUM: 'x-overall-debt-sum',
}
