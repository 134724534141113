import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentDemoFeSimpleProfile = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/student/simple-demo-fe-profile')
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  patch(data) {
    return this._PATCH('/account/student/simple-demo-fe-profile', data)
  }
})()
