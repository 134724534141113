import ApiBase from 'ApiRest/ApiBase'

export const ApiSudentIncomeDiagmocticsConfirmAcquaintance =
  new (class extends ApiBase {
    /**
     *
     * @returns {Promise}
     */
    put() {
      return this._PUT(`/account/student/diagnostics/confirm-acquaintance`)
    }
  })()
