import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Получить Очный Итоговой тест по ID
   * @param {number} fteFinalTestId
   * @returns {Promise}
   */
  get(fteFinalTestId) {
    return this._GET(`/account/student/test/final-tests/${fteFinalTestId}`)
  }
})()
