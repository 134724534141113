import '@/helpers/domains'
import 'tippy.js/dist/tippy.css'
import 'vue-toastification/dist/index.css'

import App from '@/App.vue'
import { RouterUser } from '@/RouterUser'
import { RouterUserShowcase } from '@/RouterUserShowcase'
import { StoreUser } from '@/StoreUser'
import { StoreUserShowcase } from '@/StoreUserShowcase'
import SentryHelper from '@/helpers/SentryHelper'
import { isShowcaseDomain } from '@/helpers/domain'
import { i18n } from '@/i18n/setup'
import { registerPluginEcharts } from '@/plugins/echarts'
import { registerPluginSlickSort } from '@/plugins/slicksort'
import { registerPluginToast } from '@/plugins/toast'
import { registerPluginUnhead } from '@/plugins/unhead'
import { initPubSub } from '@/pubsub/subscriber'
import VueCountdown from '@chenfengyuan/vue-countdown'
import PortalVue from 'portal-vue'
import { createApp } from 'vue'
import { createVfm } from 'vue-final-modal'
import { VueMasonryPlugin } from 'vue-masonry'
import { screens } from 'vue-screen-utils'
import VueTippy from 'vue-tippy'

initPubSub()

const init = async function () {
  let store
  let router

  if (isShowcaseDomain) {
    store = StoreUserShowcase.init()
    router = await RouterUserShowcase.init()

    await store.dispatch('locale/fetchLangOptions')
    store.dispatch('locale/setLangDefault')
    await store.dispatch('locale/changeLocale')
  } else {
    store = StoreUser.init()
    router = await RouterUser.init()
  }

  const app = createApp(App)
  const vfm = createVfm()

  app.use(PortalVue)
  app.use(VueMasonryPlugin)
  app.use(store)
  app.use(router)
  app.use(i18n)
  app.use(VueTippy, {
    onShow(options) {
      return Boolean(options.props.content)
    },
  })
  app.use(vfm)

  app.use(screens, {
    xs: '0px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
  })

  app.component(VueCountdown.name, VueCountdown)

  registerPluginToast(app)
  registerPluginEcharts(app)
  registerPluginUnhead(app)
  registerPluginSlickSort(app)

  SentryHelper.init(app, router)

  return app
}

/**
 * Обработка ошибки отсутвующего чанка
 * https://vitejs.dev/guide/build.html#load-error-handling
 */
const processViteChunkError = () => {
  window.addEventListener('vite:preloadError', (event) => {
    // eslint-disable-next-line no-console
    console.log('processViteChunkError', event)

    window.location.reload()
  })
}

if (process.iblsEnv.IS_DEVELOPMENT) {
  // eslint-disable-next-line no-console
  console.log(process.iblsEnv)
}

init().then((app) => {
  document.querySelector('#preload')?.remove()
  app.mount('#app')

  processViteChunkError()
})
