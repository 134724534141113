/**
 * TODO: перенести функцию в класс
 * @param {string} goalName
 * @param {string|null} customTrackerId
 */

const reachGoalYandexMetrika = function (goalName, customTrackerId = null) {
  let trackerId = window?.trackers?.metrika

  if (customTrackerId !== null) {
    trackerId = customTrackerId
  }

  if (window.ym && trackerId) {
    window.ym(trackerId, 'reachGoal', goalName)
  }
}

/**
 * TODO: перенести функцию в класс
 * @param {string} goalName
 */
const reachGoalGoogleTagManager = function (goalName) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: goalName,
    })
  }
}

/**
 * @param {string} goalName
 */
const reachGoal = function (goalName) {
  if (process.iblsEnv.IS_PRODUCTION) {
    reachGoalYandexMetrika(goalName)
    reachGoalGoogleTagManager(goalName)
  }
}

export class WebAnalyticsHelper {
  /**
   * @param {Object} payload
   * @param {number} payload.id
   * @param {string} payload.name
   * @param {string} payload.action
   * @param {string|null} payload.author
   * @param {float|null} payload.price
   * @param {string|null} payload.category
   */
  static reachGoalYandexMetrikaEcommerceAction({
    id,
    name,
    action,
    author = null,
    price = null,
    category = null,
  }) {
    if (window.dataLayer) {
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          [action]: {
            products: [
              {
                id,
                author,
                name,
                price,
                category,
              },
            ],
          },
        },
      })
    }
  }

  /**
   * @param {{
   *   id: number,
   *   name: string,
   *   price: float,
   *   category: string
   * }[]} products
   */
  static reachGoalYandexMetrikaEcommercePurchase(products) {
    if (window.dataLayer) {
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          purchase: {
            products,
          },
        },
      })
    }
  }

  /**
   * /registration
   */
  static goalRegistration() {
    reachGoal('registration')
  }

  /**
   * /demo-registration
   */
  static goalRegistrationDemo() {
    reachGoal('registrationdemo')
  }

  /**
   * /demo-registration-fe
   */
  static goalFamilyRegistrationDemo() {
    reachGoal('registrationdemoSo')
  }

  /**
   * /client/invoices
   */
  static goalPromocode() {
    reachGoal('promokod')
  }

  /**
   * /client/demo-access
   */
  static goalDemoAccessForm() {
    reachGoal('demodostupForm')
  }

  /**
   * /client/requests/new
   */
  static goalDataContractExtramural() {
    reachGoal('dannieDogovorZO')
  }

  /**
   * /client/requests/new
   */
  static goalDataClientExtramural() {
    reachGoal('dannieKlientZO')
  }

  /**
   * /client/requests/new
   */
  static goalDataStudentExtramural() {
    reachGoal('dannieYchenikaZO')
  }

  /**
   * /client/requests/new
   */
  static goalDataContractFamily() {
    reachGoal('dannieDogovorSem')
  }

  /**
   * /client/requests/new
   * TODO: typo in goal name?
   */
  static goalDataClientFamily() {
    reachGoal('dannieKlienSem')
  }

  /**
   * /client/requests/new
   */
  static goalDataStudentFamily() {
    reachGoal('dannieYchenikaSem')
  }

  /**
   * /client/requests/new
   * TODO: нужно вызывать на шаге оферта или проверка данных?
   */
  static goalDataCheckFamily() {
    reachGoal('dannieProvSem')
  }

  /**
   * /client/invoices/${invoiceId}
   */
  static goalGoToPayment() {
    reachGoal('perehodkOplateSem')
  }

  /**
   * /showcase/products/${productId}
   */
  static goalAddProductInCart() {
    reachGoal('addProductInBasket')
  }

  /**
   * /showcase/cart
   */
  static goalRemoveProductFromCart() {
    reachGoal('removeProductFromBasket')
  }

  /**
   * /showcase/products/${productId}
   */
  static goalViewDetailProduct() {
    reachGoal('viewDetailProduct')
  }

  /**
   * /invoices
   */
  static goalPurchasedProduct() {
    reachGoal('purchasedProduct')
  }
}
