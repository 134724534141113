import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseDeleteUnavailableItems =
  new (class extends ApiBase {
    /**
     * Удаление недоступных продуктов из заказа
     * @param {number} purchaseId
     * @returns {Promise}
     */
    put(purchaseId) {
      const url = `/purchase/delete-unavailable-items/${purchaseId}`

      return this._PUT(url)
    }
  })()
