import { flattenDeep } from 'lodash'

export const getCategoriesFlatten = (categories = []) =>
  flattenDeep([
    ...categories,
    ...categories.map(({ subcategories }) =>
      getCategoriesFlatten(subcategories),
    ),
  ])

export const categoriesFlattenBindParent = (categories = []) => {
  const categoriesSubcategories = []

  const gettingSubcategories = (subcategories) => {
    subcategories.forEach((category) => {
      categoriesSubcategories.push(category)

      if (category.subcategories.length > 0) {
        gettingSubcategories(category.subcategories)
      }
    })
  }

  gettingSubcategories(categories)

  return categoriesSubcategories
}
