import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setQuestionPreview(state, payload) {
    state.testPreviewQuestions = [
      payload,
    ]
  },

  setTestPreview(state, payload) {
    state.testPreview = payload
  },

  setTestPreviewQuestions(state, variantNumber) {
    const variant = state.testPreview.variants.find(
      ({ number }) => number === variantNumber,
    )

    state.testPreviewQuestions = variant.questions
  },

  setTest(state, payload) {
    state.test = payload
  },

  setTestPointsMarks(state) {
    state.test.pointMarkPairList.reverse()
  },

  setTestingList(state, payload) {
    state.testingList = payload
  },

  setTesting(state, payload) {
    state.testing = payload
  },

  setAttemptList(state, payload) {
    state.attemptList = payload
  },

  setAttempt(state, payload) {
    state.attempt = payload
  },

  setTestingMeta(state, { key, value }) {
    state.testingMeta[key] = value
  },

  setAnswerList(state, payload) {
    state.answerList = payload
  },

  /*
   * Список статусов ответа:
   * status - статус ответа
   * correctnessType - тип правильности ответа
   * content - ответ
   * canRetryAnswer - помечание вопроса, как "Можно переответить"
   * */
  setAnswerListMeta(state) {
    state.answerListMeta = state.answerList.map((answer) => ({
      status: answer.status.id,
      correctnessType: answer.correctnessType?.id ?? null,
      content: answer.content,
      canRetryAnswer: false,
      id: answer.id,
    }))
  },

  setAnswerMeta(state, { index, key, value }) {
    state.answerListMeta[index][key] = value
  },

  updateAnswerList(state) {
    state.answerList = state.answerList.map((answer) => {
      if (answer.id === state.answer.id) {
        return state.answer
      }

      return answer
    })
  },

  setAnswer(state, payload) {
    state.answer = payload
  },

  setAnswerContent(state, payload) {
    state.answer.content = payload
  },

  setEstimatedTime(state, payload) {
    state.estimatedTime = payload
  },

  setTestResultsPreview(state, payload) {
    state.testResultsPreview = payload
  },
}
