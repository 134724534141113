import ApiBase from 'ApiRest/ApiBase'

export default new (class FeTestingCreate extends ApiBase {
  /**
   * Создать Прохождение Теста для теста аттестации СО
   * @param {number} id
   * @returns {Promise}
   */
  post(id) {
    return this._POST(`/account/student/test/fe-cert-tests/${id}/testings`)
  }
})()
