export const clientSupportRoutes = [
  {
    path: 'support',
    name: 'client-support',
    component: () => import('@/pages/showcase/common/support/Support.vue'),

    children: [
      {
        path: 'questions',
        name: 'client-support-questions',
        component: () =>
          import('@/pages/showcase/common/support/Questions.vue'),
      },
    ],
  },

  {
    path: 'support/questions/:questionId(\\d+)',
    name: 'client-support-the-question',
    component: () => import('@/pages/showcase/common/support/TheQuestion.vue'),
  },
]
