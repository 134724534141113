import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseProductRequests = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {number} payload.consumerId
   * @param {number} payload.offerId
   * @returns {Promise}
   */
  post(payload) {
    const url = '/showcase/product/requests'

    return this._POST(url, payload)
  }
})()
