import ErrorList from '@/components/common/ErrorList.vue'
import { HttpStatusCode } from 'axios'
import { forIn, isArray, isPlainObject } from 'lodash'
import { useToast } from 'vue-toastification'

const toast = useToast()

/**
 * @param {string|Object|Array} message
 * @param {'success'|'error'|'info'} type
 * @param {number} duration
 */
export const showToast = (message, type = 'success', duration = 3000) => {
  const options = {
    timeout: duration,
    type,
  }

  let content = message

  if (isArray(message) || isPlainObject(message)) {
    content = {
      component: ErrorList,

      props: {
        errors: message,
      },
    }
  }

  toast(content, options)
}

/**
 * @param {string|Object|Array} message
 * @param {'success'|'error'|'info'} type
 */
export const showToastPersistent = (message, type) => {
  const options = {
    timeout: false,
    closeOnClick: false,
    draggable: false,
    type,
  }

  let content = message

  if (isArray(message) || isPlainObject(message)) {
    content = {
      component: ErrorList,

      props: {
        errors: message,
      },
    }
  }

  toast(content, options)
}

/**
 * @param {*} error
 */
export const showErrors = (error) => {
  let data = error?.response?.data
  const status = error?.response?.status

  if (status === HttpStatusCode.InternalServerError) {
    data = {
      default: 'BACKEND ERROR',
    }
  }

  if (!isPlainObject(data)) {
    data = {
      default: 'FRONTEND ERROR',
    }
  }

  forIn(data, (message) => showToast(message, 'error'))
}
