import ApiBase from 'ApiRest/ApiBase'

export const ApiDemoFeSimpleRegistration = new (class extends ApiBase {
  /**
   * @param {Object} data
   * @param {string} data.firstName
   * @param {string} data.email
   * @param {string} data.phone
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/auth/demo-fe-student-registration', data)
  }
})()
