import ApiBase from 'ApiRest/ApiBase'

export const ApiDemoFeSimpleSelectGrade = new (class extends ApiBase {
  /**
   * @param {Object} data
   * @param {Object} data.grade
   * @returns {Promise}
   */
  put(data) {
    return this._PUT('/auth/demo-fe-student-select-grade', data)
  }
})()
