import ApiBase from 'ApiRest/ApiBase'

/**
 * @typedef TestContextPayload
 * @property {number} typeId ID типа контекста
 * @property {number} objectId ID контекста
 */

export const ApiStudentQuestionReset = new (class extends ApiBase {
  /**
   * @param {Object} obj
   * @param {number} obj.questionId
   * @param {TestContextPayload} obj.context
   * @returns {Promise}
   */
  put({ questionId, context }) {
    let url = `/account/student/test/question/${questionId}/testing/reset`

    if (context) {
      url = `/account/student/test/question/${questionId}/testing/${context.typeId}/${context.objectId}/reset`
    }

    return this._PUT(url)
  }
})()
