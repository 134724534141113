import client from '@/store/onlineSchool/client/store'
import common from '@/store/onlineSchool/common/store'
import student from '@/store/onlineSchool/student/store'

export default {
  namespaced: true,

  modules: {
    client,
    common,
    student,
  },
}
