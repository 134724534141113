export default () => ({
  statements: {
    intramural: [],
    extramural: [],
  },
  academicYears: [],
  academicYear: null,

  statementIntramural: {
    itemsFilter: null,
  },
})
