import cart from '@/store/showcase/student/cart/store'
import main from '@/store/showcase/student/main/store'
import product from '@/store/showcase/student/product/store'
import profile from '@/store/showcase/student/profile/store'

export default {
  namespaced: true,

  modules: {
    cart,
    main,
    profile,
    product,
  },
}
