import { createColorsForUser, createYears } from '@/helpers/Calendar'

export default () => ({
  items: [],
  calendarItems: [],
  colors: createColorsForUser(),
  years: createYears(),
  isShownCalendar: true,
  serverTimestamp: 0,
})
