import actions from '@/store/showcase/common/cart/actions'
import getters from '@/store/showcase/common/cart/getters'
import mutations from '@/store/showcase/common/cart/mutations'
import state from '@/store/showcase/common/cart/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
