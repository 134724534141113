import DownloadHelper from '@/helpers/DownloadHelper'
import { ApiClientSudentsIncomeDiagmoctics } from 'Api/rest/Api/Account/Client/Students/IncomeDiagnostics'
import { ApiIntramuralDiagnosticsAttachment } from 'ApiRest/Api/Account/Client/Students/IntramuralDiagnosticsAttachment'

export default {
  async fetchIncomeDiagnostics({ commit, rootState }) {
    const { id } = rootState.client.students.currentStudent
    const { data } = await ApiClientSudentsIncomeDiagmoctics.get(id)

    commit('setTestingResults', data)
  },

  async getIntramuralDiagnosticsAttachment(
    { rootState },
    { intramuralDiagnosticsId, fileId },
  ) {
    const studentId = rootState.client.students.currentStudent.id

    const response = await ApiIntramuralDiagnosticsAttachment.get(
      studentId,
      intramuralDiagnosticsId,
      fileId,
    )

    DownloadHelper.downloadBlob(response)
  },
}
