import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseDeleteInvoiceUnavailableItems =
  new (class extends ApiBase {
    /**
     * Удаление недоступных продуктов из счета
     * @param {number} invoiceId
     * @returns {Promise}
     */
    put(invoiceId) {
      const url = `/purchase/delete-invoice-unavailable-items/${invoiceId}`

      return this._PUT(url)
    }
  })()
