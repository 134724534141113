import { ApiShowcaseCartIsAvailableForStudentAccess } from 'ApiRest/Api/Showcase/Product/IsAvailableForStudentAccess'

export default {
  async fetchIsAvailableForStudentOrder({ commit, rootState }) {
    const { data } = await ApiShowcaseCartIsAvailableForStudentAccess.get(
      rootState.common.product.product.id,
    )

    commit('setIsAvailableForStudentAccess', data)
  },
}
