export const clientPaymentRoutes = [
  {
    path: 'payment',
    name: 'client-payment',
    component: () => import('@/pages/client/payments/Payments.vue'),
    children: [
      {
        path: 'invoices',
        name: 'client-payment-invoices',
        component: () => import('@/pages/client/Invoices.vue'),
      },
      {
        path: 'subscriptions',
        name: 'client-payment-subscriptions',
        component: () => import('@/pages/client/payments/Subscriptions.vue'),
      },
    ],
  },
  {
    path: 'payment/invoices/:invoiceId(\\d+)',
    name: 'client-payment-the-invoice',
    component: () => import('@/pages/client/TheInvoice.vue'),
  },
]
