import {
  fetchTelegramBotLink,
  unlinkTelegramBot,
} from '@/helpers/profile/telegramBot'
import { ApiProfilePhoto } from 'ApiRest/Api/Account/Profile/Photo'
import { ApiEducontGrades } from 'ApiRest/Api/Account/Student/Educont/Grades'
import { ApiAccountStudentEducontStream } from 'ApiRest/Api/Account/Student/Educont/Stream'
import ApiPasswordChange from 'ApiRest/Api/Account/Student/PasswordChange'
import ApiProfile from 'ApiRest/Api/Account/Student/Profile'
import { ApiAccountStudentProfileLanguage } from 'ApiRest/Api/Account/Student/Profile/Language'
import ApiSchool from 'ApiRest/Api/Account/Student/School'
import { ApiAuthSwitchToClient } from 'ApiRest/Api/Auth/SwitchToClient'
import ApiNotification from 'ApiRest/Api/Notification'
import ApiNotificationEmail from 'ApiRest/Api/Notification/Email'
import ApiNotificationMobileDevice from 'ApiRest/Api/Notification/MobileDevice'
import ApiNotificationTelegram from 'ApiRest/Api/Notification/Telegram'
import ApiOption from 'ApiRest/Api/Option'
import ApiUserSettings from 'ApiRest/Api/UserSettings'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchProfile({ commit, dispatch }) {
    const { data } = await ApiProfile.get()

    commit('setProfile', data)
    dispatch('setUserIsDemo')
    dispatch('setUserEmail')
    await dispatch('setLocale')
  },

  setUserIsDemo({ state, commit }) {
    commit(
      'common/user/setUserValue',
      {
        key: 'isDemo',
        value: state.profile.isDemo,
      },
      {
        root: true,
      },
    )
  },

  setUserEmail({ state, commit }) {
    commit(
      'common/user/setUserValue',
      {
        key: 'email',
        value: state.profile.email,
      },
      {
        root: true,
      },
    )
  },

  async setLocale({ state, commit, dispatch }) {
    await dispatch('common/locale/fetchLangOptions', null, {
      root: true,
    })

    dispatch('common/locale/setLangDefault', null, {
      root: true,
    })

    commit('common/locale/setUserType', 'student', {
      root: true,
    })

    commit('common/locale/setInstitutionType', 'school', {
      root: true,
    })

    commit('common/locale/setInstitutionId', state.profile.school.id, {
      root: true,
    })

    await dispatch('common/locale/changeLocale', null, {
      root: true,
    })
  },

  async updateProfile({ state }) {
    await ApiProfile.patch(state.profile)
  },

  async updateLang({ commit }, payload) {
    const { data } = await ApiAccountStudentProfileLanguage.put({
      language: payload,
    })

    commit('setProfile', data)
  },

  async fetchNotifications({ commit }) {
    const { data } = await ApiNotification.get()

    commit('setNotifications', data)
  },

  async fetchTelegramNotifications({ state, commit }) {
    const { data } = await ApiNotificationTelegram.get()

    commit('setNotifications', {
      ...state.notifications,
      telegram: data,
    })
  },

  async sendTelegramChatId({ state, commit }, chatId) {
    const {
      data: { success, payload },
    } = await ApiNotificationTelegram.post(Number(chatId))

    if (payload) {
      commit('setNotifications', {
        ...state.notifications,
        telegram: {
          ...state.notifications.telegram,
          payload,
        },
      })
    }

    return success
  },

  async removeTelegramNotifications() {
    await ApiNotificationTelegram.delete()
  },

  async fetchTelegramBotLink({ commit }) {
    const { isLinked, link } = await fetchTelegramBotLink()

    commit('setEventsTelegramIsLinked', isLinked)
    commit('setEventsTelegramLink', link)
  },

  async unlinkTelegramBot({ commit }) {
    const { link } = await unlinkTelegramBot()

    commit('setEventsTelegramIsLinked', false)
    commit('setEventsTelegramLink', link)
  },

  subscribeEmailNotifications() {
    return ApiNotificationEmail.post()
  },

  unsubscribeEmailNotifications() {
    return ApiNotificationEmail.delete()
  },

  subscribeMobileDeviceNotifications() {
    return ApiNotificationMobileDevice.post()
  },

  unsubscribeMobileDeviceNotifications() {
    return ApiNotificationMobileDevice.delete()
  },

  async fetchSchool({ commit }, payload) {
    const { data } = await ApiSchool.get(payload)

    commit('setSchool', data)
  },

  updateProfilePasswords(context, payload) {
    return ApiPasswordChange.patch(payload)
  },

  async fetchUserSettings({ commit }) {
    const { data } = await ApiUserSettings.get({
      name: 'commonSettings',
    })

    commit('setUserSettings', data)
  },

  async updateUserSettings({ state, commit }, payload) {
    const body = {
      name: 'commonSettings',
      data: {
        ...state.userSettings?.data,
        ...payload,
      },
    }

    const { data } = await ApiUserSettings.put(body)

    commit('setUserSettings', data)
  },

  async uploadProfilePhoto({ commit }, payload) {
    const { data } = await ApiProfilePhoto.post(payload)

    commit('setProfileValue', {
      key: 'photo',
      value: data,
    })
  },

  async removeProfilePhoto({ commit }) {
    await ApiProfilePhoto.delete()

    commit('setProfileValue', {
      key: 'photo',
      value: null,
    })
  },

  async fetchEducontGrades({ commit }) {
    const { data } = await ApiEducontGrades.get()

    commit('setEducontGrades', data)
  },

  async updateGrade(context, gradeId) {
    await ApiAccountStudentEducontStream.patch(gradeId)
  },

  async fetchClientToken({ state, commit }) {
    const { data } = await ApiAuthSwitchToClient.post({
      password: state.clientPassword,
    })

    commit('setClientToken', data)
  },

  async fetchOptionTimezoneList({ commit }) {
    const { data } = await ApiOption.getTimezones()

    commit('setOptionValue', {
      path: 'timezones',
      value: data,
    })
  },
}
