import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiLibraryStudentItems } from 'ApiRest/Api/Account/Student/LibraryItems'

export default {
  /**
   * @param {Object} args
   * @param {Function} args.commit
   * @returns {Promise}
   */
  async fetchItems({ commit }) {
    const { data, request } = await ApiLibraryStudentItems.get()

    commit('setItems', request.status === HttpStatusCodes.NoContent ? [] : data)
  },

  /**
   * @param {Object} args
   * @param {Function} args.commit
   * @returns {Promise}
   */
  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setDateServer', data)
  },
}
