import { isString } from 'lodash'

/**
 *
 * @param {string} param
 * @returns {string|null}
 * @throws {Error}
 */
export const getSearchParam = (param) => {
  if (!isString(param)) {
    throw new Error(`Param must be a string. ${typeof param} passed`)
  }

  const searchParams = new URLSearchParams(window.location.search)

  return searchParams.get(param)
}
