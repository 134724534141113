<template>
  <BottomNotification ref="BottomNotification">
    <div :class="$style.wrapper">
      <div v-if="isRendered.mainText" :class="$style.text" class="mr-0 mr-lg-4">
        <div class="text-blue">
          {{ t('text_blue') }}
        </div>
        <div>{{ t('text') }}</div>
      </div>

      <div
        v-if="isRendered.secondaryText"
        :class="$style.text"
        class="mr-0 mr-lg-4"
      >
        {{ t('text_clarification') }}
      </div>

      <div :class="$style.btnWrapper">
        <ElButton
          v-if="isRendered.btnChooseForm"
          class="btn-primary mb-2 mb-lg-0 mr-lg-2"
          :label="t('btn_choose')"
          @click="onClickGoToChooseForm"
        />

        <ElButton
          class="btn-outline-primary"
          :label="btnLabel"
          @click="onClickBtnCancel"
        />
      </div>
    </div>
  </BottomNotification>
</template>

<script>
import BottomNotification from '@/components/common/BottomNotification.vue'
import ElButton from '@/ui/ElButton.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlockChooseEducationForm',

  components: {
    ElButton,
    BottomNotification,
  },

  data() {
    return {
      isBtnGoToChooseFormClicked: false,
    }
  },

  computed: {
    isRendered() {
      return {
        mainText: !this.isBtnGoToChooseFormClicked,
        secondaryText: this.isBtnGoToChooseFormClicked,
        btnChooseForm: !this.isBtnGoToChooseFormClicked,
      }
    },

    btnLabel() {
      return this.isBtnGoToChooseFormClicked
        ? this.t('btn_confirm')
        : this.t('btn_cancel')
    },
  },

  methods: {
    /**
     * @public
     */
    show() {
      this.$refs.BottomNotification.show()
    },

    hide() {
      this.$refs.BottomNotification.hide()
    },

    onClickGoToChooseForm() {
      this.isBtnGoToChooseFormClicked = true
    },

    onClickBtnCancel() {
      this.hide()
    },

    t(name) {
      return this.$t(`components.bottom_notification.choose_form.${name}`)
    },
  },
})
</script>

<style lang="scss" module>
@import '~@/scss/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $lg) {
    flex-direction: row;
  }
}

.btnWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;

  @media (min-width: $lg) {
    width: unset;
    align-items: center;
    flex-direction: row;
  }
}

.text {
  width: 100%;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;

  @media (min-width: $lg) {
    width: unset;
    margin-bottom: 0;
  }
}
</style>
