import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountPromoProducts = new (class extends ApiBase {
  /**
   *
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/promo-products')
  }
})()
