import ApiBase from 'ApiRest/ApiBase'

/**
 * @typedef {{
 * id: number,
 * name: string,
 * author: string,
 * publisher: string,
 * publicationYear: number,
 * receivedDate: string,
 * returnDate: string,
 * cost: float
 * }} LibraryStudentItemI
 */

export const ApiLibraryStudentItems = new (class extends ApiBase {
  /**
   * Список библиотечных книг ученика
   * @returns {Promise<{data: LibraryStudentItemI[]}>}
   */
  get() {
    const url = `/school-library/books`

    return this._GET(url)
  }
})()
