import {
  dateToZonedTime,
  formatDateInTimeZone,
} from '@/helpers/dateFormatHelper'

export default {
  timezone(state, getters, rootState, rootGetters) {
    if (rootGetters['common/user/isStudent']) {
      return rootGetters['student/profile/timezone']
    }

    return rootGetters['client/profile/timezone']
  },

  serverDateToZonedTime(state, getters) {
    return dateToZonedTime(state.serverTimestamp, getters.timezone)
  },

  formatServerDateInTimeZone: (state, getters) => (formatStr) =>
    formatDateInTimeZone(state.serverTimestamp, getters.timezone, formatStr),

  // TODO: Удалить после 01.10.24
  isUserTimezoneSameAsDefault(state, getters) {
    return getters.timezone.offset === -180
  },
}
