import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLesson = new (class OnlineLesson extends ApiBase {
  /**
   * @param {number} onlineLessonId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(onlineLessonId, parameters = {}) {
    const url = `/showcase/account/online-lessons/${onlineLessonId}?${this.getUrlParams(
      parameters,
    )}`

    return this._GET(url)
  }
})()
