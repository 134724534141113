import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolOfferDocList = new (class extends ApiBase {
  /**
   * @param {number} invoiceId
   * @returns {Promise}
   */
  get(invoiceId) {
    const url = `/online-school/invoice/${invoiceId}/purchase/offer-documents`

    return this._GET(url)
  }
})()
