export default {
  defaultParamForClient(state, getters, rootState, rootGetters) {
    const isClient = rootGetters['common/user/isClient']
    const currentStudentId = rootGetters['client/students/currentStudentId']

    if (isClient && currentStudentId) {
      return {
        student: {
          id: currentStudentId,
        },
      }
    }

    return {}
  },
}
