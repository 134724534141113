import { isEmpty } from 'lodash'

export default {
  hasAwaitingDocuments(state) {
    return !isEmpty(state.notifications.documents)
  },

  awaitingDocuments(state) {
    return state.notifications.documents
  },
}
