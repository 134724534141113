export default () => ({
  userSettings: {},
  profile: {},
  school: {},
  notifications: {},
  events: {
    telegramBot: {
      isLinked: false,
      link: null,
    },
  },
  educontGrades: [],
  selectedGrade: null,
  clientPassword: '',
  clientToken: null,

  options: {
    timezones: [],
  },
})
