<template>
  <a
    v-if="isRendered.externalUrl"
    :class="link.class"
    :target="link.isExternal ? '_blank' : false"
    class="s-aside__link"
    :href="link.externalUrl"
    :rel="link.isExternal ? 'noopener noreferrer' : false"
  >
    <span>{{ $t(link.labelPath) }}</span>

    <i v-if="isRendered.notification" class="notif">
      {{ link.notifications }}
    </i>

    <img
      v-if="isRendered.iconLinkExternal"
      :src="icons.linkExternal"
      :class="$style.iconLinkExternal"
      alt="external link"
    />
  </a>

  <router-link
    v-else
    :class="link.class"
    :to="routerLink"
    class="s-aside__link"
  >
    <span>{{ $t(link.labelPath) }}</span>

    <i v-if="isRendered.notification" class="notif">
      {{ link.notifications }}
    </i>
  </router-link>
</template>

<script>
import iconLinkExternalDark from '@/static/images/common-lk/icons/link-external-dark.svg'
import { isUndefined } from 'lodash'

export default {
  name: 'TheAsideLink',

  props: {
    link: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      icons: {
        linkExternal: iconLinkExternalDark,
      },
    }
  },

  computed: {
    isRendered() {
      return {
        externalUrl: !isUndefined(this.link.externalUrl),
        iconLinkExternal: this.link.isExternal,
        notification: this.link.notifications > 0,
      }
    },

    routerLink() {
      return this.link.link
    },
  },
}
</script>

<style lang="scss" module>
.iconLinkExternal {
  position: absolute;
  top: 11px;
  right: 11px;
}
</style>
