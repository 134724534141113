export default () => ({
  // Превью теста
  testPreview: null,
  testResultsPreview: {},
  testPreviewQuestions: [],

  // Вспомогательные сущности
  testingMeta: {
    testingCanContinue: false,
    testingCanStart: false,
  },

  answerListMeta: [],

  // Прохождение теста
  test: {
    id: null,
    attemptsNumber: null,
    testingTime: null,
    date: '',
    accessDateTime: '',
    isAvailable: false,
    criteria: '',
    demoTest: null,
    type: {
      id: null,
      name: '',
    },
    whenShowAnswerType: {
      id: null,
      name: '',
    },
    showAnswerType: {
      id: null,
      name: '',
    },
    markingType: {
      id: null,
      name: '',
    },
    test: {
      id: null,
      name: '',
      variantsNumber: null,
      questionNumber: null,
      maxPointsNumber: null,
      checkType: {
        id: null,
        name: '',
      },
      grade: {
        id: null,
        name: '',
      },
      subject: {
        id: null,
        name: '',
      },
    },
    pointMarkPairList: [],
  },

  testingList: [],
  testing: {},
  attemptList: [],
  attempt: {},
  answerList: [],
  answer: {},
  estimatedTime: null,
})
