export const trackActionTypes = {
  LOGIN: 1,
  LOGOUT: 2,
  VISIT: 3,
  VISIT_LESSON_PAGE: 4,
  VISIT_TEST_PAGE: 5,
  VISIT_TEST_ATTESTATION_PAGE: 6,
  SIGNUP_ONLINE_LESSON: 7,
  ENTER_ONLINE_LESSON: 8,
  CANCEL_ONLINE_LESSON: 9,
  CLICK_START_TEST_BTN: 10,
  CLICK_GO_TO_RESULTS_BTN: 11,
  CLICK_START_TEST_FE_CERT_BTN: 12,
}
