import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseCtps = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = `/showcase/materials/ctps`

    return this._GET(url)
  }
})()
