import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesPurchaseItem = new (class extends ApiBase {
  /**
   * @param {number} purchaseId
   * @param {number} itemId
   * @returns {Promise}
   */
  get(purchaseId, itemId) {
    const url = `/courses/purchase/${purchaseId}/item/${itemId}`

    return this._GET(url)
  }
})()
