import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseSupportQuestionsComment = new (class extends ApiBase {
  /**
   * @param {number} questionId
   * @param {Object} payload
   * @returns {Promise}
   */
  post(questionId, payload) {
    const url = `/showcase/questions/${questionId}/comment`

    return this._POST(url, payload)
  }
})()
