import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setStatements(state, payload) {
    state.statements = payload
  },

  setTheStatement(state, payload) {
    state.statement = payload
  },

  setPeriodList(state, payload) {
    state.periodList = payload
  },

  setPeriod(state, payload) {
    state.period = payload
  },

  setPromoCtps(state, payload) {
    const promoCtps = payload.map((item) => ({
      ...item,
      isActual: true,
      isPromo: true,
    }))

    state.statements[0].items.push(...promoCtps)
  },
}
