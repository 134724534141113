import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLessonRecording = new (class extends ApiBase {
  /**
   * Записи уроков
   * @param {Object} obj
   * @param {number} obj.onlineLessonId
   * @param {number} obj.videoId
   * @returns {Promise}
   */
  get({ onlineLessonId, videoId }) {
    return this._GET(
      `/showcase/account/online-lessons/${onlineLessonId}/video/${videoId}`,
    )
  }
})()
