export const clientSupportRoutes = [
  {
    path: 'support',
    name: 'client-support',
    component: () => import('@/pages/client/support/Support.vue'),
    children: [
      {
        path: 'questions',
        name: 'client-support-questions',
        component: () => import('@/pages/client/support/Questions.vue'),
      },
      {
        path: 'ideas',
        name: 'client-support-ideas',
        component: () => import('@/pages/client/support/Ideas.vue'),
      },
      {
        path: 'faq',
        name: 'client-support-faq',
        component: () => import('@/pages/client/support/FAQ.vue'),
      },
      {
        path: 'contacts',
        name: 'client-support-contacts',
        component: () => import('@/pages/client/support/Contacts.vue'),
      },
    ],
  },
  {
    path: 'support/questions/:questionId(\\d+)',
    name: 'client-support-the-question',
    component: () => import('@/pages/client/support/TheQuestion.vue'),
  },
  {
    path: 'support/ideas/:ideaId(\\d+)',
    name: 'client-support-the-idea',
    component: () => import('@/pages/client/support/TheIdea.vue'),
  },
]
