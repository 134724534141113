import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} homeworkId
   * @returns {Promise}
   */
  put(homeworkId) {
    return this._PUT(
      `/account/student/ctp/homework-solution/${homeworkId}/checked`,
    )
  }
})()
