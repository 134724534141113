import ApiBase from 'ApiRest/ApiBase'

export default new (class BaseRole extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/profile/get-base-role`)
  }
})()
