import { RouterUser } from '@/RouterUser'
import { mainSchool } from '@/helpers/const/AppData'
import ApiRequestStudent from 'ApiRest/Api/Account/Client/Request/Student'
import ApiSimpleRequestGrades from 'ApiRest/Api/Account/Client/SimpleRequest/Grades'
import ApiSimpleRequest from 'ApiRest/Api/Account/Client/SimpleRequest/SimpleRequest'
import ApiOption from 'ApiRest/Api/Option'

export default {
  fetchKinshipDegrees({ commit }) {
    return ApiOption.getKinshipDegrees().then(({ data }) => {
      commit('setKinshipDegrees', data)
    })
  },

  fetchCitizenships({ commit }) {
    return ApiOption.getCitizenships().then(({ data }) => {
      commit('setCitizenships', data)
    })
  },

  fetchCountries({ commit }) {
    return ApiOption.getCountries().then(({ data }) => {
      commit('setCountries', data)
    })
  },

  fetchMoscowRegistrationTypes({ commit }) {
    return ApiOption.getMoscowRegistrationTypes().then(({ data }) => {
      commit('setMoscowRegistrationTypes', data)
    })
  },

  fetchFamilyEducationGrades({ commit }) {
    return ApiSimpleRequestGrades.get(mainSchool.id).then(({ data }) => {
      commit('setGrades', data)
    })
  },

  async fetchExistingStudent({ commit }, studentId) {
    const {
      data: { firstName, lastName, middleName, email, phone, birthDate, id },
    } = await ApiRequestStudent.get(studentId)

    commit('setContractStudent', {
      firstName,
      lastName,
      middleName,
      email,
      phone,
      birthDate,
      id,
    })
  },

  fetchContractSubmissionTypes({ state, commit }) {
    return ApiOption.getEnrollmentContractSubmissionTypes().then(({ data }) => {
      commit('setContractSubmissionTypes', data)

      commit('setSubmissionType', state.submissionTypes[0])
    })
  },

  async createRequest({ state }) {
    await ApiSimpleRequest.post(state.contractSimplified)

    RouterUser.router.push({
      name: 'client-contracts',
    })
  },
}
