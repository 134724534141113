import ApiBase from 'ApiRest/ApiBase'

export const ApiClientOnlineSchoolCtpStatementAcademicYears =
  new (class extends ApiBase {
    /**
     * Список учебных годов для ведомостей КТП, приобретенных через онлайн-школу
     * @param {number} studentId
     * @param {Object} parameters
     * @returns {Promise}
     */
    get(studentId, parameters = {}) {
      return this._GET(
        `/account/client/students/${studentId}/online-school-ctp-statement/academic-years?${this.getUrlParams(
          parameters,
        )}`,
      )
    }
  })()
