import calendar from '@/store/student/calendar/store'
import demoFeSimple from '@/store/student/demoFeSimple/store'
import events from '@/store/student/events/store'
import extramuralCertification from '@/store/student/extramuralCertification/store'
import familyCertification from '@/store/student/familyCertification/store'
import fullTimeCertification from '@/store/student/fullTimeCertification/store'
import fullTimeStatement from '@/store/student/fullTimeStatement/store'
import goals from '@/store/student/goals/store'
import homework from '@/store/student/homework/store'
import incomeDiagnostics from '@/store/student/incomeDiagnostics/store'
import incomeDiagnosticsTest from '@/store/student/incomeDiagnosticsTest/store'
import journal from '@/store/student/journal/store'
import lessonPreview from '@/store/student/lessonPreview/store'
import lessons from '@/store/student/lessons/store'
import lessonsOnline from '@/store/student/lessonsOnline/store'
import library from '@/store/student/library/store'
import main from '@/store/student/main/store'
import news from '@/store/student/news/store'
import notifications from '@/store/student/notifications/store'
import payment from '@/store/student/payment/store'
import profile from '@/store/student/profile/store'
import scheduleLessons from '@/store/student/scheduleLessons/store'
import scheduleTests from '@/store/student/scheduleTests/store'
import specialStatement from '@/store/student/specialStatement/store'
import statements from '@/store/student/statements/store'
import statementsOnlineSchool from '@/store/student/statementsOnlineSchool/store'
import statementsShowcase from '@/store/student/statementsShowcase/store'
import subjects from '@/store/student/subjects/store'
import test from '@/store/student/test/store'
import upcomingEvents from '@/store/student/upcomingEvents/store'

export default {
  namespaced: true,

  modules: {
    specialStatement,
    calendar,
    events,
    extramuralCertification,
    familyCertification,
    fullTimeCertification,
    goals,
    lessonPreview,
    lessons,
    lessonsOnline,
    main,
    news,
    profile,
    statements,
    statementsShowcase,
    statementsOnlineSchool,
    subjects,
    test,
    homework,
    scheduleTests,
    notifications,
    journal,
    fullTimeStatement,
    scheduleLessons,
    incomeDiagnostics,
    incomeDiagnosticsTest,
    library,
    payment,
    demoFeSimple,
    upcomingEvents,
  },
}
