import ApiBase from 'ApiRest/ApiBase'

export default new (class List extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/client/common-contracts`)
  }
})()
