import { mainFont } from '@/helpers/styleOptions'

export const lightColor = 'rgba(51, 47, 62, 0.48)'
export const darkColor = 'rgba(245, 245, 245, 1)'

const tickOptions = {
  font: {
    family: mainFont,
    size: 14,
    lineHeight: 1,
  },
  color: lightColor,
}

export const datasetOptions = {
  // Цвет заливки площади
  backgroundColor: 'transparent',
  // Толщина линии
  borderWidth: 2,
  // Непрерывность графика
  spanGaps: true,
  tension: 0.3,
}

export const chartOptions = {
  plugins: {
    // Настройки тултипа
    tooltip: {
      titleFont: {
        family: mainFont,
      },
      bodyFont: {
        family: mainFont,
      },
      // Отображение данных в тултипе по индексу.
      // Например, если в массивах оценок по русскому и английскому
      // под индексом 1 будут оценки, то они будут в тултипе списком.
      mode: 'index',
    },
    // Настройки отображения названий списков
    legend: {
      display: false,
    },
  },

  responsive: true,
  // Сохранять пропорции при ресайзе
  maintainAspectRatio: false,

  // Настройка шкал
  scales: {
    x: {
      // Настройки шагов (тиков) по оси
      ticks: {
        ...tickOptions,
      },

      grid: {
        display: false,
      },
    },
    y: {
      // Настройки шагов (тиков) по оси
      ticks: {
        // Мин значение по оси Y - минимальная оценка
        min: 1,
        max: 5,
        // Шаг по оси Y
        stepSize: 1,
        ...tickOptions,
      },
    },
  },
}
