import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setLegalRepresentativeTypes(state, payload) {
    state.legalRepresentativeTypes = payload
  },

  setSubmissionTypes(state, payload) {
    state.submissionTypes = payload
  },

  setCitizenships(state, payload) {
    state.citizenships = payload
  },

  setCountries(state, payload) {
    state.countries = payload
  },

  setTheFamilyCertificationRequestIsAvailable(state, payload) {
    state.theRequestIsAvailable = payload
  },

  setTheFamilyCertificationRequest(state, payload) {
    state.theRequest = payload
  },

  setTheFamilyCertificationRequestValue(state, { key, value }) {
    // При создании state.theRequest === null.
    if (!state.theRequest) {
      state.theRequest = {}
    }

    state.theRequest[key] = value
  },

  // Шаги и состояния шагов при создании заявки
  setSteps(state, payload) {
    state.steps = payload
  },

  setActiveStep(state, { i, value }) {
    state.steps[i].isActive = value
  },

  setCompleteStep(state, { i, value }) {
    state.steps[i].isComplete = value
  },

  setValidStatus(state, { key, value }) {
    state.validStatus[key] = value
  },

  setIsEachInitialValueApplied(state, payload) {
    state.isEachInitialValueApplied = payload
  },
}
