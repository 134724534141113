import ApiBase from 'ApiRest/ApiBase'

export const ApiEducontLessonFeedback = new (class extends ApiBase {
  /**
   * @param {number} ctpId
   * @param {number} contentId
   * @param {string} message
   * @returns {Promise}
   */
  post(ctpId, contentId, message) {
    const url = `/educont/feedback/content/${ctpId}/${contentId}`
    const payload = {
      message,
    }

    return this._POST(url, payload)
  }
})()
