import { RESET_STATE } from '@/helpers/ResetState'
import { cloneDeep } from 'lodash'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProductChecked(state, { index, value }) {
    state.products[index].isSelected = value
  },

  setProducts(state, value) {
    state.products = value
  },

  setIsLoadingProducts(state, value) {
    state.isLoadingProducts = value
  },

  setCurrentOrder(state, value) {
    state.currentOrder = value

    if (value?.invoicePeriodType) {
      state.invoicePeriodType = state.options.paymentTypes.find(
        (type) => type.id === value.invoicePeriodType.id,
      )
    }
  },

  setStudent(state, value) {
    state.currentStudent = value
  },

  setStudentType(state, value) {
    state.currentStudentType = value
  },

  setInvoicePeriodType(state, value) {
    state.invoicePeriodType = value
  },

  setStudentConsumer(state, { key, value }) {
    state.studentConsumer[key] = value
  },

  setPurchase(state, value) {
    state.purchase = value
  },

  setInvoices(state, payload) {
    state.invoices = payload
  },

  setOptionsList(state, { path, value }) {
    state.options[path] = value
  },

  setInitialOrder(state, payload) {
    state.initialOrder = cloneDeep(payload)
  },

  setConsumer(state, value) {
    state.studentConsumer = value
  },
}
