import ApiBase from 'ApiRest/ApiBase'

export const ApiProductQuestionPurchased = new (class extends ApiBase {
  /**
   * Создание вопроса в карточке продукта (некупленного)
   * @param {Object} data
   * @param {number} productId
   * @returns {Promise}
   */
  post(data, productId) {
    return this._POST(`/product/purchase/${productId}/questions`, data)
  }
})()
