export const scrollTo = (coordinate = 0) => {
  const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style

  supportsNativeSmoothScroll
    ? window.scrollTo({
        top: coordinate,
        behavior: 'smooth',
      })
    : window.scrollTo(0, coordinate)
}

/**
 * Плавный скролл наверх
 */
export const scrollToTop = () => {
  scrollTo(0)
}
