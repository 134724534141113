import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setItems(state, payload) {
    state.items = payload
  },

  setCalendarItems(state, payload) {
    state.calendarItems = payload
  },

  showCalendar(state) {
    state.isShownCalendar = true
  },

  hideCalendar(state) {
    state.isShownCalendar = false
  },

  setServerTimestamp(state, payload) {
    state.serverTimestamp = payload
  },
}
