import ApiBase from 'ApiRest/ApiBase'

export default new (class RequestStudent extends ApiBase {
  /**
   * @description Получение подробной информации о студенте для заполнения заявки
   * @param {number} studentId
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(`/account/client/requests/students/${studentId}`)
  }
})()
