import ApiBase from 'ApiRest/ApiBase'

export const ApiTariffList = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/tariff')
  }
})()
