export const clientMainRoutes = [
  {
    path: 'profile',
    name: 'client-profile',
    component: () => import('@/pages/showcase/client/Profile.vue'),
  },

  {
    path: 'payment/success',
    name: 'client-showcase-payment-success',
    component: () => import('@/pages/showcase/client/Invoice.vue'),
    props: {
      isModalOpenSuccess: true,
    },
  },

  {
    path: 'payment/error',
    name: 'client-showcase-payment-error',
    component: () => import('@/pages/showcase/client/Invoice.vue'),
    props: {
      isModalOpenError: true,
    },
  },

  {
    path: 'notifications',
    name: 'client-notifications',
    component: () => import('@/pages/showcase/common/Notifications.vue'),
  },

  {
    path: 'online-lessons',
    name: 'client-showcase-online-lessons',
    component: () => import('@/pages/showcase/common/LessonsOnline.vue'),
  },

  {
    path: 'online-lessons/:lessonId(\\d+)',
    name: 'client-the-online-lesson',
    component: () => import('@/pages/showcase/common/LessonsOnline.vue'),
  },
]
