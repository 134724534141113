import ApiBase from 'ApiRest/ApiBase'

export default new (class Statement extends ApiBase {
  /**
   * Договор оферты в формате docx
   * @param {number} schoolId - id школы
   * @returns {Promise}
   */
  get(schoolId) {
    return this._GET(
      `/account/client/family-education-offer-for-school/${schoolId}`,
      {
        responseType: 'arraybuffer',
      },
    )
  }
})()
