export const clientOnlineSchoolRoutes = [
  {
    path: 'online-school',
    name: 'client-online-school',
    component: () => import('@/pages/client/OnlineSchool.vue'),
  },

  {
    path: 'online-school/purchases/:purchaseId(\\d+)',
    name: 'client-online-school-the-purchase',
    component: () => import('@/pages/client/TheOnlineSchoolPurchase.vue'),
  },
]
