import ApiBase from 'ApiRest/ApiBase'

export default new (class TheCertificationRequest extends ApiBase {
  /**
   * @param {number} studentId
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(
      `/account/client/students/${studentId}/fe-certification-request`,
    )
  }

  /**
   * @param {number} studentId
   * @param {Object} payload
   * @returns {Promise}
   */
  post(studentId, payload = {}) {
    return this._POST(
      `/account/client/students/${studentId}/fe-certification-request`,
      payload,
    )
  }

  /**
   * @param {number} studentId
   * @param {Object} payload
   * @returns {Promise}
   */
  patch(studentId, payload = {}) {
    return this._PATCH(
      `/account/client/students/${studentId}/fe-certification-request`,
      payload,
    )
  }

  /**
   * @param {number} studentId
   * @returns {Promise}
   */
  delete(studentId) {
    return this._DELETE(
      `/account/client/students/${studentId}/fe-certification-request`,
    )
  }

  /**
   * @param {number} studentId
   * @param {Object} payload
   * @returns {Promise}
   */
  put(studentId, payload = {}) {
    return this._PUT(
      `/account/client/students/${studentId}/fe-certification-request`,
      payload,
    )
  }
})()
