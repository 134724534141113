import ApiBase from 'ApiRest/ApiBase'

export const ApiOfferDocList = new (class extends ApiBase {
  /**
   * @param {number} invoiceId
   * @returns {Promise}
   */
  get(invoiceId) {
    return this._GET(`/courses/invoice/${invoiceId}/purchase/offer-documents`)
  }
})()
