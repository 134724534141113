import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesPurchaseClientData = new (class extends ApiBase {
  /**
   * Получение данных клиента в заказе
   * @param {number} purchaseId
   * @returns {Promise}
   */
  get(purchaseId) {
    const url = `/courses/purchase/${purchaseId}/client-data`

    return this._GET(url)
  }

  /**
   * Обновление данных клиента в заказе
   * @param {number} purchaseId
   * @param {Object} payload
   * @returns {Promise}
   */
  patch(purchaseId, payload) {
    const url = `/courses/purchase/${purchaseId}/client-data`

    return this._PATCH(url, payload)
  }
})()
