import ApiBase from 'ApiRest/ApiBase'

/**
 * @typedef NotificationChannelI<T>
 * ```
 * {{
 *  isActive:boolean,
 *  isSubscribed:boolean,
 *  payload:null|T,
 * }}
 */

export default new (class extends ApiBase {
  /**
   * @returns {Promise<{
   * telegram:NotificationChannelI<TelegramPayloadI>,
   * }>}
   */
  get() {
    const url = `/notification/map`

    return this._GET(url)
  }
})()
