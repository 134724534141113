import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesCartIsAvailableForStudentOrder =
  new (class extends ApiBase {
    /**
     * @returns {Promise}
     */
    get() {
      const url = '/courses/cart/is-available-for-student-order'

      return this._GET(url)
    }
  })()
