import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseCartIsAvailableForStudentAccess =
  new (class extends ApiBase {
    /**
     * @param {number} productId
     * @returns {Promise}
     */
    get(productId) {
      const url = `/showcase/product/${productId}/is-available-for-student-access`

      return this._GET(url)
    }
  })()
