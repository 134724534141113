import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentOnlineLessonVideoTrack = new (class extends ApiBase {
  /**
   * Трекинг просмотра видео
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload) {
    return this._POST(`/account/student/online-lessons/video/track`, payload)
  }
})()
