import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  homeworkDatesSet(state, payload) {
    state.homeworkDates = payload
  },

  theHomeworkSolutionSet(state, { solution, relatedData }) {
    state.homeworkDates[relatedData.dateIndex].subjects[
      relatedData.subjectIndex
    ].homeworkSolutions[relatedData.taskIndex] = solution
  },

  theHomeworkTaskCheckedSet(state, { checked, relatedData }) {
    state.homeworkDates[relatedData.dateIndex].subjects[
      relatedData.subjectIndex
    ].homeworkSolutions[relatedData.taskIndex].isChecked = checked
  },
}
