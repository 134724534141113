import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

const blocksAlreadyAdded = {}

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setNotifications(state, notifications) {
    state.notifications = notifications
  },

  addBottomNotification(state, notification) {
    if (notification.blockName in blocksAlreadyAdded) {
      return
    }

    state.bottomNotifications.push(notification)
    blocksAlreadyAdded[notification.blockName] = true
  },

  popBottomNotification(state) {
    state.bottomNotifications.pop()
  },
}
