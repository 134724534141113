import ApiBase from 'ApiRest/ApiBase'

export const ApiFeServiceCheckPromocode = new (class extends ApiBase {
  /**
   * @param {Object} data
   * @param {string} data.code
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/auth/fe-service-check-promocode', data)
  }
})()
