import { StoreUser } from '@/StoreUser'
import StudentMain from '@/layouts/StudentMain.vue'
import { studentCourseRoutes } from '@/routes/student/studentCourseRoutes'
import { studentEducontRoutes } from '@/routes/student/studentEducontRoutes'
import { studentExtramuralRoutes } from '@/routes/student/studentExtramuralRoutes'
import { studentFamilyRoutes } from '@/routes/student/studentFamilyRoutes'
import { studentFullTimeSupportRoutes } from '@/routes/student/studentFullTimeSupportRoutes'
import { studentMainRoutes } from '@/routes/student/studentMainRoutes'
import { onlineSchoolRoutes } from '@/routes/student/studentOnlineSchoolRoutes'
import { studentSubjectRoutes } from '@/routes/student/studentSubjectRoutes'
import { studentTestRoutes } from '@/routes/student/studentTestRoutes'

export const studentRoutes = () => {
  const educationType = StoreUser.store.getters['student/profile/educationType']

  const checkRole = (to, from, next) => {
    // TODO: проверить,нужно ли в каждом роуте это проверять или достаточно один раз при инициализации.
    const isStudent = StoreUser.store.getters['common/user/isStudent']

    if (isStudent) {
      next()
    } else {
      next({
        name: 'login',
      })
    }
  }

  const childRoutes = [
    ...studentCourseRoutes,
    ...studentEducontRoutes,
    ...studentMainRoutes,
    ...studentSubjectRoutes,
    ...studentFullTimeSupportRoutes,
    ...onlineSchoolRoutes,

    {
      path: 'payment',
      name: 'student-payment',
      component: () => import('@/pages/student/Payment.vue'),
    },
  ]

  const childRoutesExtramural = [
    ...studentCourseRoutes,
    ...studentExtramuralRoutes,
    ...onlineSchoolRoutes,

    {
      path: 'payment',
      name: 'student-payment',
      component: () => import('@/pages/student/Payment.vue'),
    },
  ]

  const childRoutesFamily = [
    ...studentCourseRoutes,
    ...studentFamilyRoutes,
    ...onlineSchoolRoutes,

    {
      path: 'payment',
      name: 'student-payment',
      component: () => import('@/pages/student/Payment.vue'),
    },
  ]

  const getStudentMainChildRoutes = () => {
    if (educationType.extramural) {
      return childRoutesExtramural
    }

    if (educationType.family) {
      return childRoutesFamily
    }

    return childRoutes
  }

  const routes = [
    {
      path: '/student',
      component: StudentMain,
      children: getStudentMainChildRoutes(),
    },

    ...studentTestRoutes,

    {
      path: '/student/online-lessons/:onlineLessonId(\\d+)/video/:videoId(\\d+)',
      name: 'student-the-online-lesson-preview',
      component: () => import('@/pages/student/TheLessonOnlinePreview.vue'),
    },

    {
      path: '/demo-registration-fe/grade-select',
      name: 'demo-fe-simple-grade-select',
      component: () => import('@/pages/auth/demoFeSimple/GradeSelect.vue'),
    },
  ]

  routes.forEach((route) => {
    route.beforeEnter = checkRole
  })

  return routes
}
