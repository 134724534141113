import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiEmailChecker } from 'ApiRest/Api/User/EmailChecker'
import { millisecondsInSecond } from 'date-fns/constants'

export default {
  async checkEmailExistence(context, params) {
    const { data } = await ApiEmailChecker.get(params)

    return data.emailExist
  },

  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setServerTimestamp', data * millisecondsInSecond)
  },
}
