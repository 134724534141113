import ApiMessages from 'ApiRest/Api/User/Messages'

export default {
  async fetchMessages({ commit, rootGetters }) {
    const isClient = rootGetters['common/user/isClient']
    const isStudent = rootGetters['common/user/isStudent']

    const { data } = await ApiMessages.get()

    switch (true) {
      case isClient:
        commit(
          'client/main/setNotificationsCount',
          {
            target: 'client-messages',
            count: data.countUnreadMessages,
          },
          {
            root: true,
          },
        )

        break

      case isStudent:
        commit(
          'student/main/setNotificationsCount',
          {
            target: 'student-messages',
            count: data.countUnreadMessages,
          },
          {
            root: true,
          },
        )

        break

      default:
    }
  },

  async startPollingMessages({ dispatch, commit }) {
    await dispatch('fetchMessages')

    const pollingId = setInterval(async () => {
      await dispatch('fetchMessages')
    }, 5000)

    commit('setPollingId', pollingId)
  },

  stopPollingMessages({ state, commit }) {
    clearInterval(state.pollingId)
    commit('unsetPollingId')
  },
}
