import { ApiAccountProductAdBanners } from 'ApiRest/Api/Account/ProductAd/Banners'
import { ApiAccountProductAdModalData } from 'ApiRest/Api/Account/ProductAd/ModalData'
import { ApiAccountProductAdLog } from 'ApiRest/Api/Account/ProductAd/ModalLog'
import { ApiAccountProductAdOrder } from 'ApiRest/Api/Account/ProductAd/Order'
import Constants from 'Constants'

export default {
  async fetchBanners({ commit }, studentId) {
    const { data } = await ApiAccountProductAdBanners.get(studentId)

    commit('setBanners', data)
  },

  async fetchModalData({ commit }) {
    const { data } = await ApiAccountProductAdModalData.get()

    commit('setModalData', data)
  },

  async sendOrder({ commit }, payload) {
    const { data } = await ApiAccountProductAdOrder.post(payload)

    commit('setPurchase', data)
  },

  async logClickBtnShowAllOffers({ state }) {
    await ApiAccountProductAdLog.post({
      productAdId: state.modalData.id,
      actionTypeId: Constants.productAdActionType.CLICK_BTN_SHOW_ALL_OFFERS,
    })
  },

  async logClickBtnNotInterested({ state }) {
    await ApiAccountProductAdLog.post({
      productAdId: state.modalData.id,
      actionTypeId: Constants.productAdActionType.CLICK_BTN_NOT_INTERESTED,
    })
  },
}
