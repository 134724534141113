import { RouterUser } from '@/RouterUser'
import { dateToDay, getDaysAfter } from '@/helpers/dateFormatHelper'
import { showToast } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiLesson from 'ApiRest/Api/Account/Client/Lesson'
import ApiLessonList from 'ApiRest/Api/Account/Client/Lesson/List'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchLessonsFirstWeek({ commit, rootGetters }) {
    const dates = {
      start: dateToDay(),
      end: getDaysAfter(dateToDay()),
    }

    const { data } = await ApiLessonList.get(
      rootGetters['client/students/currentStudentId'],
      {
        date: {
          startDate: dates.start,
          endDate: dates.end,
        },
      },
    )

    commit('setLessonsFirstWeek', data)
  },

  async fetchLessons(
    { commit, rootGetters },
    { page, mode = 'all', subject = null },
  ) {
    const filters = {
      page,
    }

    if (subject !== null) {
      filters.subject = subject
    }

    if (mode === 'testOnly') {
      filters.testOnly = true
    } else if (mode === 'unpassedOnly') {
      filters.unpassedOnly = true
    }

    const { data, headers } = await ApiLessonList.get(
      rootGetters['client/students/currentStudentId'],
      filters,
    )

    commit('setLessonsPagination', {
      mode,
      headers,
    })

    commit('setLessons', {
      mode,
      data,
    })
  },

  async fetchTheLesson({ commit, getters }, id) {
    const lesson = getters.getCurrentLesson(id)

    if (!lesson) {
      try {
        const { data } = await ApiLesson.get(id)

        commit('setTheLesson', data)
      } catch (error) {
        // TODO: -> component
        if (error?.response?.status === HttpStatusCodes.NotFound) {
          showToast(
            i18n.global.t('store.client.toasted_message.lesson_was_not_found'),
            'error',
          )

          RouterUser.router.push({
            name: 'not-found',
          })
        }
      }
    }
  },
}
