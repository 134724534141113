import ApiBase from 'ApiRest/ApiBase'

export default new (class LessonContentNotPass extends ApiBase {
  /**
   * @param {number} lessonId
   * @param {number} contentId
   * @returns {Promise}
   */
  put(lessonId, contentId) {
    return this._PUT(
      `/account/student/lessons/${lessonId}/content/${contentId}/not-passed`,
    )
  }
})()
