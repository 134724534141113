export default {
  tariffsAvailable(state, getters, rootState, rootGetters) {
    let { tariffs } = state
    const TARIFF_EXTRAMURAL_ID = 1

    if (!rootGetters['client/profile/isAvailableAnyCommercialOffer']) {
      tariffs = tariffs.filter((tariff) => tariff.id !== TARIFF_EXTRAMURAL_ID)
    }

    return tariffs
  },
}
