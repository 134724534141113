import DownloadHelper from '@/helpers/DownloadHelper'
import { ApiSudentIncomeDiagmocticsConfirmAcquaintance } from 'Api/rest/Api/Account/Student/Diagnostics/ConfirmAcquaintance'
import { ApiSudentIncomeDiagmoctics } from 'Api/rest/Api/Account/Student/Diagnostics/IncomeDiagnostics'
import { ApiIntramuralDiagnosticsAttachment } from 'ApiRest/Api/Account/Student/Diagnostics/IntramuralDiagnosticsAttachment'

export default {
  async fetchIncomeDiagnostics({ commit }) {
    const { data } = await ApiSudentIncomeDiagmoctics.get()

    commit('setTestingResults', data)
  },

  confirmAcquaintance() {
    return ApiSudentIncomeDiagmocticsConfirmAcquaintance.put()
  },

  async getIntramuralDiagnosticsAttachment(
    context,
    { intramuralDiagnosticsId, fileId },
  ) {
    const response = await ApiIntramuralDiagnosticsAttachment.get(
      intramuralDiagnosticsId,
      fileId,
    )

    DownloadHelper.downloadBlob(response)
  },
}
