import ApiBase from 'ApiRest/ApiBase'

export const ApiProductQuestion = new (class extends ApiBase {
  /**
   * Получение вопроса
   * @param {number} questionId
   * @returns {Promise}
   */
  get(questionId) {
    return this._GET(`/product/questions/${questionId}`)
  }

  /**
   * Создание вопроса
   * @param {Object} data
   * @param {number} productId
   * @returns {Promise}
   */
  post(data, productId) {
    return this._POST(`/product/${productId}/questions`, data)
  }

  /**
   * Изменение вопроса
   * @param {number} questionId
   * @param {Object} data
   * @returns {Promise}
   */
  patch(questionId, data) {
    return this._PATCH(`/product/questions/${questionId}`, data)
  }
})()
