import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  readNotificationSet(state, notification) {
    const index = state.notifications.findIndex(
      ({ id }) => id === notification.id,
    )

    if (index) {
      state.notifications[index] = notification
    }
  },

  notificationsSet(state, payload) {
    state.notifications = payload
  },
}
