import { ApiShowcaseMain } from 'ApiRest/Api/Showcase/Main'
import { ApiShowcaseNavigationBannerBlock } from 'ApiRest/Api/Showcase/NavigationBanner/Block'

export default {
  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   * @returns {Promise}
   */
  async fetchMainData({ commit }) {
    const { data } = await ApiShowcaseMain.get()

    commit('setBanners', data.banners)
    commit('setProductsLatest', data.latestProducts)
    commit('setProductsPopular', data.popularProducts)
    commit('setSeo', data.seo)
  },

  async fetchNavigationBlock({ commit }) {
    const { data } = await ApiShowcaseNavigationBannerBlock.get()

    commit('setNavigationBanner', data)
  },
}
