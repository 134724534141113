import SentryHelper from '@/helpers/SentryHelper'
import { WebAnalyticsHelper } from '@/helpers/WebAnalyticsHelper'

export const auth = {
  /**
   * Пользователь вошел в систему
   * @param {Object} params
   * @param {number} params.id
   * @param {string} params.email
   * @param {string} params.role
   */
  userLoggedIn({ id, email, role }) {
    // TODO: также передавать в Sentry данные менеджера,
    //  когда будет API получения данных текущего пользователя админки
    SentryHelper.setUser(id, email, role)
  },

  /**
   * Пользователь вышел из системы
   */
  userLoggedOut() {
    SentryHelper.resetUser()
  },

  /**
   * Принудительный разлогин после смены пароля
   */
  userLoggedOutAfterPasswordChange() {
    SentryHelper.resetUser()
  },

  /**
   * Клиент зарегистрировался
   */
  clientCreated() {
    WebAnalyticsHelper.goalRegistration()
  },

  /**
   * Клиент зарегистрировался как лид
   */
  leadCreated() {
    WebAnalyticsHelper.goalRegistrationDemo()
  },

  /**
   * Клиент нажал кнопку "Получить демо-доступ"
   * при получении бесплатного демо-доступа
   * для семейной формы обучения
   */
  demoFeCreated() {
    WebAnalyticsHelper.goalFamilyRegistrationDemo()
  },
}
