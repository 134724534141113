<template>
  <v-select
    v-model="lang"
    :options="options"
    :clearable="false"
    :searchable="false"
    dir="ltr"
    label="name"
  >
    <template #selected-option="option">
      <img :src="icons.language" :alt="$t('layout.common.language_icon')" />

      <span class="ml-2">{{ option.name }}</span>
    </template>
  </v-select>
</template>

<script>
import iconLanguageDark from '@/static/images/common-lk/icons/language-dark.svg'
import iconLanguage from '@/static/images/common-lk/icons/language.svg'
import { defineComponent } from 'vue'
import vSelect from 'vue-select'

export default defineComponent({
  name: 'LanguageSelect',

  components: {
    vSelect,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    iconDark: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
  ],

  data() {
    return {
      icons: {
        language: this.iconDark ? iconLanguageDark : iconLanguage,
      },
    }
  },

  computed: {
    lang: {
      get() {
        return this.modelValue
      },

      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
})
</script>
