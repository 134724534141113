import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesCartProductsCount = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = '/courses/cart/count'

    return this._GET(url)
  }
})()
