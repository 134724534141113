import ApiBase from 'ApiRest/ApiBase'

export const ApiFeServiceDetails = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/auth/fe-service-details')
  }
})()
