import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Список домашних заданий
   * @param {number} studentId
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(`/account/client/students/${studentId}/homework-solution`)
  }
})()
