import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/showcase/client/subscription/state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSubscriptions(state, payload) {
    state.subscriptions = payload
  },

  updateSubscription(state, payload) {
    const subscriptionIndex = state.subscriptions.findIndex(
      (subscription) => subscription.id === payload.id,
    )

    state.subscriptions[subscriptionIndex] = payload
  },
}
