import { chartPeriods } from '@/helpers/const/chartPeriod'

export default () => ({
  academicYear: null,
  academicYears: [],
  statements: [],
  averageMarksMonthly: [],
  averageMarksWeekly: [],
  chartData: null,
  chartPeriod: null,
  chartPeriods,
  subjects: [],
  subjectsSelected: [],
})
