import ApiAverageMarksMonthly from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement/AverageMark/List'
import ApiAverageMarksMonthlyTotal from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement/AverageMark/Total/List'
import ApiAverageMarksWeeklyTotal from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement/AverageMark/Total/WeeklyList'
import ApiAverageMarksWeekly from 'ApiRest/Api/Account/Client/Students/FullTimeCtpStatement/AverageMark/WeeklyList'

export default {
  async fetchAverageMarksMonthly({ commit }, { studentId, params }) {
    const payload = await Promise.all([
      ApiAverageMarksMonthly.get(studentId, params),
      ApiAverageMarksMonthlyTotal.get(studentId, params),
    ])
      .then((responses) => responses.map(({ data }) => data))
      .then(
        ([
          marks,
          marksTotal,
        ]) => ({
          marks,
          marksTotal,
        }),
      )

    commit('setAverageMarksMonthly', payload)
  },

  async fetchAverageMarksWeekly({ commit }, { studentId, params }) {
    const payload = await Promise.all([
      ApiAverageMarksWeekly.get(studentId, params),
      ApiAverageMarksWeeklyTotal.get(studentId, params),
    ])
      .then((responses) => responses.map(({ data }) => data))
      .then(
        ([
          marks,
          marksTotal,
        ]) => ({
          marks,
          marksTotal,
        }),
      )

    commit('setAverageMarksWeekly', payload)
  },

  prepareChartData({ commit, state, rootState }) {
    if (state.academicYear === null) {
      commit(
        'setAcademicYear',
        rootState.client.fullTimeStatement.theAcademicYear,
      )
    }

    commit('setAcademicYears', rootState.client.fullTimeStatement.academicYears)
    commit('setStatements', rootState.client.fullTimeStatement.statements)
    commit('setChartPeriod')
    commit('setSubjects')
    commit('setSubjectsSelected', state.subjects)
    commit('composeChartData')
  },
}
