import { eventBus } from '@/pubsub/bus'
import { eventHandlers } from '@/pubsub/handlers'
import { get, isFunction } from 'lodash'

export const initPubSub = function () {
  eventBus.$on('*', (type, e) => {
    const handler = get(eventHandlers, type)

    if (isFunction(handler)) {
      handler(e)
    }
  })
}
