export const onlineSchoolRoutes = [
  {
    path: '/student/online-school',
    name: 'student-online-school',
    component: () => import('@/pages/student/OnlineSchool.vue'),
  },
  {
    path: 'online-school/purchases/:purchaseId(\\d+)',
    name: 'student-online-school-the-purchase',
    component: () => import('@/pages/student/TheOnlineSchoolPurchase.vue'),
  },
]
