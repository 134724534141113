import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setFormProp(state, { key, value }) {
    state.form = {
      ...state.form,
      [key]: value,
    }
  },
}
