import { Pagination } from '@/helpers/CompositePagination'

export default () => ({
  events: [],
  unpassedCount: 0,
  pagination: new Pagination(),
  isLoading: {
    events: false,
    btnMore: false,
  },
})
