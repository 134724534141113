import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountPromoCampaign = new (class extends ApiBase {
  /**
   *
   * @param {number} promoCampaignId
   * @returns {Promise}
   */
  get(promoCampaignId) {
    return this._GET(`/account/promo-campaign/${promoCampaignId}`)
  }
})()
