export default () => ({
  routeParams: {
    product: null,
    category: null,
  },

  purchaseId: null,
  purchaseItemId: null,

  /** @type {ShowcaseProduct|null} */
  product: null,

  /** @type {User[]} */
  users: [],

  /** @type {User|null} */
  user: null,

  /** @type {Option[]} */
  offers: [],

  /** @type {Option|null} */
  offer: null,

  /** @type {number} */
  serverTimestamp: 0,

  /** @type {{isLinked:boolean,link:string|null}} */
  telegramBot: {
    isLinked: false,
    link: null,
  },
})
