import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesProductOptionsStudents = new (class extends ApiBase {
  /**
   * @param {number} productId
   * @returns {Promise}
   */
  get(productId) {
    const url = `/courses/product/options/students/${productId}`

    return this._GET(url)
  }
})()
