/**
 * @typedef {Object} CourseCategory
 * @property {number} id
 * @property {string} name
 * @property {CourseCategory[]} [subcategories]
 * @property {number} [parentCategoryId]
 */

export default () => ({
  /** @type {CourseCategory[]} */
  categories: [],

  upperCategories: [],
})
