import ApiBase from 'ApiRest/ApiBase'

/**
 * @typedef {{
 * id: number,
 * name: string,
 * author: string,
 * publisher: string,
 * publicationYear: number,
 * receivedDate: string,
 * returnDate: string,
 * cost: float
 * }} LibraryClientItemI
 */

export const ApiLibraryClientItems = new (class extends ApiBase {
  /**
   * Список библиотечных книг ученика клиента
   * @param {Object} args
   * @param {number} args.studentId
   * @returns {Promise<{data: LibraryClientItemI[]}>}
   */
  get({ studentId }) {
    const url = `/school-library/books/${studentId}`

    return this._GET(url)
  }
})()
