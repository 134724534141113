import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiClientJournalEvents = new (class extends ApiBase {
  /**
   * Дневник (список)
   * @param {Object} parameters
   * @param {string} parameters.studentId
   * @param {Object} parameters.datesRange
   * @returns {Promise}
   */
  get({ studentId, datesRange } = {}) {
    return this._GET(
      `/account/client/students/${studentId}/journal?${UrlHelper.param(datesRange)}`,
    )
  }
})()
