import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiModulatorActiveModules = new (class extends ApiBase {
  /**
   * Доступные модули школы (или школ) для юзера и их настройки
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  get(parameters = {}) {
    const url = `/modulator/active-modules?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }
})()
