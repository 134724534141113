import { RouterUser } from '@/RouterUser'
import { showToast } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import { eventBus } from '@/pubsub/bus'
import ApiDownload from 'Api/Download'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiHasFamilyInvoice } from 'ApiRest/Api/Account/Client/HasFamilyInvoice'
import ApiClientTheInvoice from 'ApiRest/Api/Account/Client/Invoice'
import ApiCancelDiscount from 'ApiRest/Api/Account/Client/Invoice/CancelDiscount'
import { ApiCustomerReceipt } from 'ApiRest/Api/Account/Client/Invoice/CustomerReceipt'
import { ApiDiagnosticsOfferDoc } from 'ApiRest/Api/Account/Client/Invoice/DiagnosticsOfferDoc'
import { ApiClientInvoices } from 'ApiRest/Api/Account/Client/Invoice/List'
import ApiPaymentUrl from 'ApiRest/Api/Account/Client/Invoice/PaymentUrl'
import { ApiAccountInvoiceReceive } from 'ApiRest/Api/Account/Client/Invoice/Receive'
import { ApiOfferDocList } from 'ApiRest/Api/Courses/Invoice/OfferDocList'
import { ApiCoursesPurchaseItemDocument } from 'ApiRest/Api/Courses/Purchase/Item/document/PurchaseItemDocument'
import { ApiCoursesPurchaseMarkWithMaternityCapital } from 'ApiRest/Api/Courses/Purchase/MarkWithMaternityCapital'
import Constants from 'Constants'
import { millisecondsInSecond } from 'date-fns/constants'

const globalMessageOnApplyPromocode = function () {
  eventBus.$emit('frontend.client.promocode.applied')
}

export default {
  /**
   * Список счетов клиента
   * @param {{commit}} context
   * @returns {Promise<void>}
   */
  async fetchInvoices({ commit }) {
    const params = {
      showcase: 0,
    }

    const { data } = await ApiClientInvoices.get(params)

    commit('setInvoices', data)
  },

  /**
   * Счет клиента
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoice({ commit }, invoiceId) {
    try {
      const { data } = await ApiClientTheInvoice.get(invoiceId)

      commit('setTheInvoice', data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.global.t('store.client.toasted_message.account_not_found'),
          'error',
        )

        RouterUser.router.push({
          name: 'client-payment-invoices',
        })
      }

      throw error
    }
  },

  async fetchPaymentUrl({ commit }, { invoiceId, parameters }) {
    const { data } = await ApiPaymentUrl.get(invoiceId, parameters)

    commit('setTheInvoicePaymentUrl', data)
  },

  async checkFamilyInvoice({ commit }) {
    const { data } = await ApiHasFamilyInvoice.get()

    commit('setHasFamilyInvoice', data)
  },

  /**
   * Ссылка на url счета
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoicePaymentUrl({ dispatch }, { invoiceId, isRecurrent }) {
    await dispatch('fetchPaymentUrl', {
      invoiceId,
      parameters: {
        driver: Constants.invoicePaymentDriver.TINKOFF,
        recurrent: isRecurrent,
      },
    })
  },

  /**
   * Ссылка на url счета Долями
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoiceDolyameUrl({ dispatch }, invoiceId) {
    await dispatch('fetchPaymentUrl', {
      invoiceId,
      parameters: {
        driver: Constants.invoicePaymentDriver.DOLYAME,
      },
    })
  },

  /**
   * Применить промокод
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {number} payload.invoiceId
   * @param {Object} payload.data
   * @returns {Promise<void>}
   */
  async applyPromocode({ commit }, { invoiceId, data }) {
    const response = await ApiClientTheInvoice.put(invoiceId, data)

    commit('setTheInvoice', response.data)

    globalMessageOnApplyPromocode()
  },

  /**
   * Отменить промокод
   * @param {{}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async discardPromocode({ commit }, invoiceId) {
    const { data } = await ApiCancelDiscount.put(invoiceId)

    commit('setTheInvoice', data)
  },

  receiveFreeProduct(context, invoiceId) {
    return ApiAccountInvoiceReceive.post(invoiceId)
  },

  fetchCustomerReceipt(context, invoiceId) {
    return ApiCustomerReceipt.get(invoiceId)
  },

  async fetchDiagnosticsOfferDoc({ commit }, diagnosticsOfferId) {
    const { data } = await ApiDiagnosticsOfferDoc.get(diagnosticsOfferId)

    commit('setDiagnosticsOfferDoc', data)
  },

  async markPurchaseWithMaternityCapital(context, purchaseId) {
    await ApiCoursesPurchaseMarkWithMaternityCapital.put(purchaseId)
  },

  async fetchOfferDocs({ commit }, invoiceId) {
    const { data } = await ApiOfferDocList.get(invoiceId)

    commit('setOfferDocs', data)
  },

  async fetchOfferDoc(
    { commit },
    { id: documentTemplateId, purchaseId, purchaseItemId },
  ) {
    const params = {
      purchaseId,
      purchaseItemId,
      documentTemplateId,
    }

    const { data } = await ApiCoursesPurchaseItemDocument.getDocx(params)

    commit('setOfferDocBlob', {
      id: documentTemplateId,
      payload: data,
    })
  },

  async fetchRecurrentPaymentsDoc({ commit }, documentId) {
    const { data } = await ApiDownload.getFileBlob(documentId)

    commit('setRecurrentPaymentsDocBlob', {
      id: documentId,
      payload: data,
    })
  },

  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setServerTimestamp', data * millisecondsInSecond)
  },
}
