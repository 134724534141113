/**
 * @typedef {Object} SupportQuestionPreview
 * @property {number} id - Ид вопроса
 * @property {string} number - Номер вопроса
 * @property {string} question - Превью вопроса
 * @property {SupportStatus} status - Статус вопроса
 * @property {number} newCommentsCount - Кол-во новых ответов/комментариев
 */

/**
 * @typedef {Object} QuestionAuthor
 * @property {number} id - Ид вопроса
 * @property {string} firstName - Имя
 * @property {string} lastName - Фамилия
 * @property {string} middleName - Отчество
 * @property {Object|null} photo - Фото профиля
 * @property {('Клиент'|'Ученик')} role - Роль
 */

/**
 * @typedef {Object} SupportQuestionComment
 * @property {number} id - Ид комментария
 * @property {QuestionAuthor} author - Автор вопроса
 * @property {Array} files - Список прикрепленных файлов
 * @property {string} text - Текст вопроса
 */

/**
 * @typedef {Object} SupportQuestion - Объект обращения в поддержку
 * @property {number} id - Ид обращения
 * @property {string} number - Номер обращения
 * @property {SupportStatus} status - Статус вопроса
 * @property {QuestionAuthor} author - Автор вопроса
 * @property {string} createdAt - Дата время в формате ISO8601
 * @property {string} text - Текст вопроса
 * @property {Array} files - Список прикрепленных файлов
 * @property {SupportQuestionComment[]} comments - Комментарии к вопросу
 */

/**
 * @typedef {Object} SupportStatus
 * @property {number} id - Ид статуса
 * @property {('Новый'|'В работе'|'Вопрос решен')} name - Название статуса
 */

export default () => ({
  /**
   * @type {SupportQuestionPreview[]}
   */
  questions: [],

  /**
   * @type {SupportQuestion}
   */
  theQuestion: {
    id: null,
    number: '',
    status: {
      id: null,
      name: '',
    },
    author: {
      id: null,
      firstName: '',
      lastName: '',
      middleName: '',
      role: '',
      photo: null,
    },
    files: [],
    createdAt: '',
    text: '',
    comments: [],
  },

  newQuestion: {
    text: '',
    files: [],
  },

  newComment: {
    text: '',
    files: [],
  },

  questionsInWorkCount: null,

  isLoading: {
    content: false,
    question: false,
  },
})
