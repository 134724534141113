import { getDate as getDateHelper } from '@/helpers/dateFormatHelper'
import { format, formatDistanceStrict, getDate, parseISO } from 'date-fns'
import ru from 'date-fns/locale/ru'
import { capitalize } from 'lodash'

const getMonthName = function (date) {
  return capitalize(getDateHelper(date, 'LLLL'))
}

// TODO: i18n
export const getProductDateInfoText = function (product) {
  const { startDate, endDate, timeStart, timeEnd } = product

  if (product.displayOnlyMonth && startDate) {
    const start = getMonthName(startDate)

    if (endDate) {
      const end = getMonthName(endDate)

      return start === end ? start : `${start} - ${end}`
    }

    return start
  }

  if (startDate) {
    const startDateDay = getDate(parseISO(startDate))

    const startDateTextPrefix = startDateDay === 2 ? 'со' : 'c'
    const startDateText = format(parseISO(startDate), 'd MMMM', {
      locale: ru,
    })

    let text = `${startDateTextPrefix} ${startDateText}`

    if (endDate) {
      const getDateWithDefaultTime = (date) => parseISO(`${date} 00:00`)

      const startDateTime = timeStart
        ? parseISO(`${startDate} ${timeStart}`)
        : getDateWithDefaultTime(startDate)

      const endDateTime = timeEnd
        ? parseISO(`${endDate} ${timeEnd}`)
        : getDateWithDefaultTime(endDate)

      const durationText = formatDistanceStrict(startDateTime, endDateTime, {
        locale: ru,
      })

      text = `${durationText}, ${text}`
    }

    return text
  }

  return null
}
