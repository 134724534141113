import ApiBase from 'ApiRest/ApiBase'

export const ApiClassBookUrl = new (class extends ApiBase {
  /**
   * @param {number} subjectId
   * @param {number} groupId
   * @param {number} classBookId
   * @returns {Promise}
   */
  get(subjectId, groupId, classBookId) {
    return this._GET(
      `/account/student/subjects/${subjectId}/groups/${groupId}/class-book/${classBookId}/url`,
    )
  }
})()
