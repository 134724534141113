import ApiBase from 'ApiRest/ApiBase'

export default new (class Started extends ApiBase {
  /**
   * Начинает Попытку Прохождения Теста.
   * @param {number} id
   * @returns {Promise}
   */
  put(id) {
    return this._PUT(`/account/student/test/attempts/${id}/started`)
  }
})()
