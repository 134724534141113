import Constants from 'Constants'

export class StudentEnrollmentStatus {
  static isNotEnrolled(student) {
    return (
      student.enrollmentStatus?.id === Constants.enrollmentStatus.NOT_ENROLLED
    )
  }

  static isEnrolled(student) {
    return student.enrollmentStatus?.id === Constants.enrollmentStatus.ENROLLED
  }

  static isExpelled(student) {
    return student.enrollmentStatus?.id === Constants.enrollmentStatus.EXPELLED
  }
}
