import ApiBase from 'ApiRest/ApiBase'

export default new (class ActiveSubjects extends ApiBase {
  /**
   * @param {number} studentId - ID студента
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(`/account/client/students/${studentId}/active-subjects`)
  }
})()
