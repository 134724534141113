import Constants from 'Constants'
import { isAfter, isEqual, parseISO } from 'date-fns'

const sortInvoicesNewestFirst = (invoices) =>
  invoices.sort((a, b) => {
    const aCreatedDate = parseISO(a.createdDate)
    const bCreatedDate = parseISO(b.createdDate)

    if (isEqual(aCreatedDate, bCreatedDate)) {
      return a.id - b.id
    }

    if (isAfter(aCreatedDate, bCreatedDate)) {
      return -1
    }

    return 0
  })

export default {
  hasInvoices(state) {
    return state.invoices.length > 0
  },

  paidInvoices(state) {
    const paidStatuses = [
      Constants.invoiceStatus.PAID,
      Constants.invoiceStatus.CANCELLED,
      Constants.invoiceStatus.REFUNDED,
    ]

    return sortInvoicesNewestFirst(
      state.invoices.filter((invoice) =>
        paidStatuses.includes(invoice.status.id),
      ),
    )
  },

  hasPaidInvoices(state, getters) {
    return getters.paidInvoices.length > 0
  },

  newInvoices(state) {
    return sortInvoicesNewestFirst(
      state.invoices.filter(
        (invoice) => invoice.status.id === Constants.invoiceStatus.NEW,
      ),
    )
  },

  hasNewInvoices(state, getters) {
    return getters.newInvoices.length > 0
  },

  offerDocs(state) {
    return state.offerDocs.filter((offerDoc) => offerDoc.viewAgreementCheckbox)
  },

  hasProductsInactive(state) {
    return state.invoice.products.some(
      (item) => !item.isActive || !item.hasVacantSeats,
    )
  },

  hasProducts(state) {
    return state.invoice.products.length > 0
  },
}
