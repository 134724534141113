import { ApiCoursesMain } from 'ApiRest/Api/Courses/Main'

export default {
  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   * @param {Object} payload
   * @param {number} payload.cardsMax
   * @returns {Promise}
   */
  async fetchMainData(
    { commit },
    { cardsMax } = {
      cardsMax: 8,
    },
  ) {
    const { data } = await ApiCoursesMain.get({
      cardsMax,
    })

    commit('setBanners', data.banners)
    commit('setProductsLatest', data.latestProducts)
    commit('setProductsPopular', data.popularProducts)
  },
}
