import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} requestId
   * @returns {Promise}
   */
  put(requestId) {
    const url = `/account/client/requests/${requestId}/set-conclude-rejected`

    return this._PUT(url)
  }
})()
