import ApiBase from 'ApiRest/ApiBase'

export default new (class ApiShowcasePurchaseUploadDoc extends ApiBase {
  /**
   * URL загрузки документов
   * @param {number} purchaseId
   * @returns {string}
   */
  getFileUploadUrl(purchaseId) {
    return `${this.BASE_URL}/purchase/${purchaseId}/upload-doc`
  }
})()
