import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolPurchaseUploadDoc = new (class extends ApiBase {
  /**
   * URL загрузки документов
   * @param {number} purchaseId
   * @returns {string}
   */
  getFileUploadUrl(purchaseId) {
    return `${this.BASE_URL}/online-school/purchase/${purchaseId}/upload-doc`
  }
})()
