import { isObjectLike } from 'lodash'

/**
 *
 * @description Заполняет данные согласно модели.
 * Кейс: с бэка приходит health: null, на фронте есть поле health.hasRestriction.id (2 и более глубины вложенности)
 * @returns {Object}
 */

const _getData = (dataValue, modelValue) => {
  if (!dataValue) {
    return modelValue
  }

  if (isObjectLike(dataValue) && isObjectLike(modelValue)) {
    Object.keys(dataValue).forEach((key) =>
      _getData(dataValue[key], modelValue[key]),
    )
  }

  return dataValue || modelValue
}

const fillDataAccordingModel = (data, model = {}) => {
  const result = model

  if (!data) {
    return result
  }

  if (isObjectLike(data)) {
    Object.keys(data).forEach((key) => {
      result[key] = _getData(data[key], model[key])
    })
  }

  return result
}

export default fillDataAccordingModel
