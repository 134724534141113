import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/courses/common/home/state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setBanners(state, payload) {
    state.banners = payload
  },

  setProductsLatest(state, payload) {
    state.productsLatest = payload
  },

  setProductsPopular(state, payload) {
    state.productsPopular = payload
  },
}
