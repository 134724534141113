import { getSearchParam } from '@/helpers/searchParams'

/**
 * Редирект на страницу микросервиса авторизации
 * @param {Object} payload
 * @param {string} payload.url
 * @param {string} payload.targetUrl
 * @param {string} payload.email
 * @param {string} payload.skipLogin
 */
export const redirectToAuth = ({
  url,
  targetUrl = '',
  email = '',
  skipLogin = '',
}) => {
  const redirectUrl = new URL(url)

  if (targetUrl !== '') {
    redirectUrl.searchParams.append('targetUrl', targetUrl)
  }

  if (email !== '') {
    redirectUrl.searchParams.append('email', email)
  }

  if (skipLogin !== '') {
    redirectUrl.searchParams.append('skip_login', skipLogin)
  }

  window.location.href = redirectUrl.href
}

/**
 * Редирект на админку
 * @param {string} path
 */
export const redirectToAdminArea = (path) => {
  window.location.href = path
    ? `//${window.domains.admin}/${path}`
    : `//${window.domains.admin}`
}

/**
 * Редирект на ЛК
 */
export const redirectToUserArea = () => {
  window.location.href = `//${window.domains.main}`
}

/**
 * Редирект на витрину
 * @param {string} path
 */
export const redirectToShowcase = (path) => {
  let resultStr = `//${window.domains.showcase}`

  if (path) {
    resultStr += path
  }

  window.location.href = resultStr
}

/**
 * Редирект на origin
 */
export const redirectToOrigin = () => {
  window.location.href = window.location.origin
}

// TODO: Удалить после перехода на МСА
export const redirectAfterLogin = () => {
  const targetUrl = getSearchParam('targetUrl')

  if (targetUrl !== null) {
    window.location.href = targetUrl
  } else {
    redirectToOrigin()
  }
}
