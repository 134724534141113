/**
 * Сброс локального стейта до исходного состояния
 * @param {Object} state
 * @param {function():Object} initState
 */
export function RESET_STATE(state = {}, initState = () => ({})) {
  const stateInitial = initState()

  Object.keys(stateInitial).forEach((key) => {
    if (key in state) {
      state[key] = stateInitial[key]
    }
  })
}
