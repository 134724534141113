import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseCategoriesLite = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    const url = `/showcase/categories-lite?${this.getUrlParams(parameters)}`

    return this._GET(url)
  }
})()
