import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProfile(state, payload) {
    state.profile = payload
  },

  setProfileValue(state, { key, value }) {
    state.profile[key] = value
  },

  setProfilePassword(state, payload) {
    state.passwords = payload
  },

  setUserSettings(state, payload) {
    state.userSettings = payload
  },

  setOptionValue(state, { path, value }) {
    state.options[path] = value
  },
}
