import ApiBase from 'ApiRest/ApiBase'

export const ApiClientShowcaseCtpStatement = new (class extends ApiBase {
  /**
   * Ведомости КТП, приобретенные через внутреннюю/внешнюю витрину
   * @param {number} studentId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(studentId, parameters = {}) {
    return this._GET(
      `/account/client/students/${studentId}/showcase-ctp-statement?${this.getUrlParams(
        parameters,
      )}`,
    )
  }
})()
