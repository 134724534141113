import DownloadHelper from '@/helpers/DownloadHelper'
import { fetchTelegramBotLink } from '@/helpers/profile/telegramBot'
import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiProductQuestion } from 'ApiRest/Api/Products/Question/Question'
import { ApiProductQuestionPurchased } from 'ApiRest/Api/Products/Question/QuestionsPurchased'
import { ApiShowcaseProduct } from 'ApiRest/Api/Showcase/Product'
import { ApiShowcaseProductOffers } from 'ApiRest/Api/Showcase/Product/Offer'
import { ApiShowcaseProductRequests } from 'ApiRest/Api/Showcase/Product/Requests'
import { ApiShowcaseProductOptionsStudents } from 'ApiRest/Api/Showcase/Product/Students'
import { ApiShowcaseProductsByIds } from 'ApiRest/Api/Showcase/ProductsByIds'
import { ApiShowcasePurchaseItem } from 'ApiRest/Api/Showcase/Purchase/Item'
import { ApiShowcasePurchaseItemCertificate } from 'ApiRest/Api/Showcase/Purchase/Item/Certificate'

export default {
  async fetchProduct({ state, commit }) {
    const { data } = await ApiShowcaseProduct.get({
      ...state.routeParams,
      parameters: {
        expand: 'canBePaidDolyame, contents',
      },
    })

    commit('setProduct', data)
  },

  async fetchProductPurchased({ state, commit }) {
    const { data } = await ApiShowcasePurchaseItem.get(
      state.purchaseId,
      state.purchaseItemId,
    )

    commit('setProduct', data)
  },

  async fetchProductOptions({ commit, state }) {
    const { data } = await ApiShowcaseProductOffers.get(state.product.id, {
      user: {
        id: state.user.id,
      },
    })

    commit('setOffers', data)

    if (data.length > 0) {
      commit('setOffer', data[0])
    }
  },

  async fetchProductOptionsGuest({ commit, state }) {
    const { data } = await ApiShowcaseProductOffers.get(state.product.id)

    commit('setOffers', data)

    if (data.length > 0) {
      commit('setOffer', data[0])
    }
  },

  async fetchUsers({ commit, state }) {
    const { data } = await ApiShowcaseProductOptionsStudents.get(
      state.product.id,
    )

    commit('setUsers', data)

    if (data.length > 0) {
      commit('setUser', data[0])
    }
  },

  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setServerTimestamp', data)
  },

  createRequest({ state }) {
    return ApiShowcaseProductRequests.post({
      offerId: state.offer.id,
      consumerId: state.user.id,
    })
  },

  async fetchPurchaseItemCertificate({ state }) {
    const response = await ApiShowcasePurchaseItemCertificate.get({
      purchaseId: state.purchaseId,
      purchaseItemId: state.purchaseItemId,
    })

    DownloadHelper.downloadBlob(response)
  },

  async fetchTelegramBotLink({ commit }) {
    const data = await fetchTelegramBotLink()

    commit('setTelegramBot', data)
  },

  async fetchProductsByIds(context, payload) {
    const { data } = await ApiShowcaseProductsByIds.get(payload)

    return data
  },

  async sendQuestion({ state }, payload) {
    await ApiProductQuestion.post(payload, state.product.id)
  },

  async sendQuestionPurchased({ state }, payload) {
    await ApiProductQuestionPurchased.post(payload, state.product.id)
  },
}
