import ApiCertificationList from 'Api/rest/Api/Account/Client/Certification/List'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} params
   * @returns {Promise<*>}
   */
  async fetchCertifications({ commit }, params) {
    const { data } = await ApiCertificationList.get(params)

    commit('setCertifications', data)
  },
}
