import ApiBase from 'ApiRest/ApiBase'

export default new (class FamilyCertificationList extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/student/test/fe-certs')
  }
})()
