import { StoreUser } from '@/StoreUser'
import { educationTypeIds } from '@/helpers/onlineSchool/educationTypeIds'

const checkAuth = (to, from, next) => {
  const isAuthorized = StoreUser.store.getters['common/user/isAuthorized']

  if (isAuthorized) {
    next('/')
  } else {
    next()
  }
}

/**
 * Некоторые страницы авторизации были перенесены в отдельную репу (ibls-widgets).
 * Следующие роуты больше не обрабатываются приложением:
 * - login
 * - logout
 * - password-reset
 * - password-reset-confirm
 */

export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/auth/Login.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
  },
  {
    path: '/create-password',
    // TODO: В e-mail указывается ссылка с этим путем.
    //  При изменении согласовать с бэком.
    name: 'password-create',
    component: () => import('@/pages/auth/PasswordCreate.vue'),
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('@/pages/auth/PasswordReset.vue'),
  },
  {
    path: '/change-password',
    // TODO: В e-mail указывается ссылка с этим путем.
    //  При изменении согласовать с бэком.
    name: 'password-reset-confirm',
    component: () => import('@/pages/auth/PasswordResetConfirm.vue'),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/pages/auth/Registration.vue'),
  },
  {
    path: '/registration-zo',
    name: 'registration-zo',
    props: {
      educationTypeId: educationTypeIds.EXTRAMURAL,
    },
    component: () => import('@/pages/auth/Registration.vue'),
  },
  {
    path: '/registration-so',
    name: 'registration-so',
    props: {
      educationTypeId: educationTypeIds.FAMILY,
    },
    component: () => import('@/pages/auth/Registration.vue'),
  },
  {
    path: '/registration-os',
    name: 'registration-os',
    props: {
      educationTypeId: educationTypeIds.ONLINE_SCHOOL,
    },
    component: () => import('@/pages/auth/Registration.vue'),
  },
  {
    path: '/demo-registration',
    name: 'demo-registration',
    component: () => import('@/pages/auth/DemoRegistration.vue'),
  },
  {
    path: '/demo-registration-fe',
    name: 'demo-fe-simple-registration',
    component: () => import('@/pages/auth/demoFeSimple/Registration.vue'),
  },
  {
    path: '/educont-registration',
    name: 'educont-registration',
    component: () => import('@/pages/auth/EducontRegistration.vue'),
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import('@/pages/auth/ConfirmEmail.vue'),
  },
  {
    path: '/educont/oauth',
    name: 'educont-oauth',
    component: () => import('@/pages/auth/EducontAuth.vue'),
  },
  {
    path: '/educont/login',
    name: 'educont-login',
    component: () => import('@/pages/auth/EducontLogin.vue'),
  },
]

authRoutes.forEach((route) => {
  const routesWithoutAuth = [
    'confirm-email',
    'password-create',
  ]

  if (!routesWithoutAuth.includes(route.name)) {
    route.beforeEnter = checkAuth
  }
})
