import ApiSpecialStatementsClient from 'ApiRest/Api/Account/Client/SpecialStatement/List'
import ApiSpecialStatementsStudent from 'ApiRest/Api/Account/Student/SpecialStatement/List'

/**
 * @param {'client' | 'student'} user
 * @returns {any}
 */
export default (user) => {
  if (user === 'client') {
    return {
      async fetchSpecialStatements({ commit }, studentId) {
        const { data } = await ApiSpecialStatementsClient.get(studentId)

        commit('setSpecialStatements', data)
      },
    }
  }

  return {
    async fetchSpecialStatements({ commit }) {
      const { data } = await ApiSpecialStatementsStudent.get()

      commit('setSpecialStatements', data)
    },
  }
}
