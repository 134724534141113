<template>
  <a :href="href" :target="targetLocal" :rel="relLocal">
    <slot>
      {{ label }}
    </slot>

    <img
      v-if="isRendered.iconExternal"
      class="ml-1"
      :src="icons.linkExternal"
      alt="external link"
    />
  </a>
</template>

<script>
import iconLinkExternalBlue from '@/static/images/common-lk/icons/link-external-blue.svg'

export default {
  name: 'ElLink',

  props: {
    href: {
      type: String,
      default: '#',
    },

    label: {
      type: String,
      default: '',
    },

    target: {
      type: String,
      default: '',
    },

    rel: {
      type: String,
      default: '',
    },

    isExternal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons: {
        linkExternal: iconLinkExternalBlue,
      },
    }
  },

  computed: {
    isRendered() {
      return {
        iconExternal: this.isExternal,
      }
    },

    targetLocal() {
      if (this.isExternal) {
        return '_blank'
      }

      return this.target
    },

    relLocal() {
      if (this.isExternal) {
        return 'noopener noreferrer'
      }

      return this.rel
    },
  },
}
</script>
