import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Деталка события
   * @param {number} studentId
   * @param {number} eventId
   * @returns {Promise}
   */
  get(studentId, eventId) {
    return this._GET(
      `/account/client/students/${studentId}/simple-events/${eventId}`,
    )
  }
})()
