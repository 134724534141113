<template>
  <div
    class="form-group"
    :class="[{ 'is-invalid': invalid, 'is-loading': isLoading }, wrapperClass]"
  >
    <div v-if="label || hint" class="d-flex align-items-center">
      <label v-if="label" class="form-label">
        {{ label }}&nbsp;<i v-if="required" class="text-pink">*</i>
      </label>

      <i
        v-if="hint"
        v-tippy="{ placement: 'right', arrow: true }"
        class="form-hint fas fa-question-circle"
        :content="hint"
      />
    </div>

    <div class="position-relative">
      <ImaskInput
        v-if="isMasked"
        v-bind="$attrs"
        ref="input"
        v-model:value="value"
        :class="cssClassInput"
        :disabled="disabled"
        :mask="mask"
        :type="type"
      />

      <input
        v-else
        v-bind="$attrs"
        ref="input"
        v-model="value"
        :class="cssClassInput"
        :disabled="disabled"
        :type="type"
        @blur="onBlur"
        @focus="onFocus"
      />

      <span
        v-if="isLoading"
        aria-hidden="true"
        role="status"
        class="spinner-border spinner-border-sm"
      ></span>

      <div
        v-if="hasAppendIcon"
        :class="cssClassInputAppendIcon"
        @click="onClickAppendIcon"
      >
        <slot name="appendIcon">
          <img :src="icons.search" alt="icon search" />
        </slot>
      </div>
    </div>

    <div v-if="invalid" class="form-invalid mt-1">
      <slot name="invalid">
        <template v-if="hasErrors">
          <div v-for="error in errors" :key="error.$uid" class="mb-1">
            {{ error.$message }}
          </div>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import iconSearch from '@/static/images/common-lk/icons/search_blue.svg'
import { isNull } from 'lodash'
import { defineComponent } from 'vue'
import { IMaskComponent } from 'vue-imask'

export default defineComponent({
  name: 'ElInput',

  components: {
    ImaskInput: IMaskComponent,
  },

  inheritAttrs: false,

  props: {
    modelValue: {
      type: [
        String,
        Number,
      ],

      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    hint: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    focus: {
      type: Boolean,
      default: false,
    },

    mask: {
      type: null,
      default: null,
    },

    wrapperClass: {
      type: [
        String,
        Array,
      ],

      default: undefined,
    },
  },

  emits: [
    'update:modelValue',
    'click:append-icon',
    'blur',
    'focus',
  ],

  data() {
    return {
      icons: {
        search: iconSearch,
      },
    }
  },

  computed: {
    value: {
      get() {
        return isNull(this.modelValue) ? '' : String(this.modelValue)
      },

      set(value) {
        this.$emit('update:modelValue', value)
      },
    },

    isMasked() {
      return this.mask !== null
    },

    isSearch() {
      return this.type === 'search'
    },

    hasAppendIcon() {
      return this.$slots.appendIcon || this.isSearch
    },

    hasAppendIconClickListener() {
      return Boolean(this.$attrs['click:append-icon'])
    },

    hasErrors() {
      return this.errors.length > 0
    },

    cssClassInputAppendIcon() {
      return [
        'append-icon',
        {
          'cursor-pointer': this.hasAppendIconClickListener,
        },
      ]
    },

    cssClassInput() {
      return [
        'form-control',
        {
          'with-append-icon': this.hasAppendIcon,
        },
      ]
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    async init() {
      if (this.focus) {
        await this.$nextTick()
        this.$refs.input.focus()
      }
    },

    onClickAppendIcon() {
      this.$emit('click:append-icon')
    },

    onBlur() {
      this.$emit('blur')
    },

    onFocus() {
      this.$emit('focus')
    },
  },
})
</script>
