import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setPromoProducts(state, payload) {
    state.promoProducts = payload
  },

  setPromoCampaign(state, payload) {
    state.promoCampaign = payload
  },

  setPromoCtps(state, payload) {
    state.promoCtps = payload
  },
}
