import ApiBase from 'ApiRest/ApiBase'

export const ApiClientSudentsIncomeDiagmoctics = new (class extends ApiBase {
  /**
   *
   * @param {number} studentId
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(`/account/client/students/${studentId}/diagnostics`)
  }
})()
