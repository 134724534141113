import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class UserSettings extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params = {}) {
    const urlParams = UrlHelper.param(params)
    const url = `/user-settings?${urlParams}`

    return this._GET(url)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  put(data) {
    return this._PUT(`/user-settings`, data)
  }
})()
