import ApiBase from 'ApiRest/ApiBase'

export default new (class DemoRegistration extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/account/client/students/register-demo-student`, data)
  }
})()
