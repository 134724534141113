import { isAfter, isEqual, parseISO } from 'date-fns'
import { isNil } from 'lodash'

export default {
  subscriptionsSortedByDate(state) {
    return [
      ...state.subscriptions,
    ].sort((a, b) => {
      if (isNil(a.startDate) && isNil(b.startDate)) {
        return 0
      }

      if (isNil(a.startDate)) {
        return 1
      }

      if (isNil(b.startDate)) {
        return -1
      }

      const aStartDate = parseISO(a.startDate)
      const bStartDate = parseISO(b.startDate)

      if (isEqual(aStartDate, bStartDate)) {
        return a.id - b.id
      }

      if (isAfter(aStartDate, bStartDate)) {
        return -1
      }

      return 0
    })
  },
}
