<template>
  <aside v-if="hasLinks" :class="{ narrow: isHidden }" class="s-aside">
    <ElButton class="s-aside__toggle" @click="toggle">
      <img :src="icons.arrowLeft" alt="arrow-left" />
    </ElButton>

    <nav class="s-aside__menu">
      <TheAsideLink v-for="link in links" :key="link.link.name" :link="link" />
    </nav>
  </aside>
</template>

<script>
import TheAsideLink from '@/layouts/common/TheAsideLink.vue'
import iconArrowLeft from '@/static/images/common-lk/icons/arrow-left.svg'
import ElButton from '@/ui/ElButton.vue'

export default {
  name: 'TheAside',

  components: {
    TheAsideLink,
    ElButton,
  },

  props: {
    links: {
      type: Array,
      required: true,
    },

    userSettings: {
      type: Object,
      default: null,
    },
  },

  emits: [
    'toggle-aside',
  ],

  data() {
    return {
      isHidden: false,

      icons: {
        arrowLeft: iconArrowLeft,
      },
    }
  },

  computed: {
    hasLinks() {
      return this.links.length > 0
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.isHidden = this.userSettings?.data?.isAsideOpen ?? false
    },

    toggle() {
      this.isHidden = !this.isHidden

      this.$emit('toggle-aside', this.isHidden)
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/variables';

.s-aside__menu {
  .m-notifications {
    @media (min-width: $lg) {
      display: none;
    }
  }
}
</style>
