export const studentSupportRoutes = [
  {
    path: 'questions',
    name: 'student-support-questions',

    component: () => import('@/pages/student/support/Questions.vue'),
  },
  {
    path: 'questions/:questionId(\\d+)',
    name: 'student-support-the-question',
    component: () => import('@/pages/student/support/TheQuestion.vue'),
  },
  {
    path: 'ideas',
    name: 'student-support-ideas',

    component: () => import('@/pages/student/support/Ideas.vue'),
  },
  {
    path: 'ideas/:ideaId(\\d+)',
    name: 'student-support-the-idea',
    component: () => import('@/pages/student/support/TheIdea.vue'),
  },
  {
    path: 'faq',
    name: 'student-support-faq',

    component: () => import('@/pages/student/support/FAQ.vue'),
  },
  {
    path: 'contacts',
    name: 'student-support-contacts',

    component: () => import('@/pages/student/support/Contacts.vue'),
  },
]
