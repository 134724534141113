import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiCalendarItems from 'ApiRest/Api/CalendarItems'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} context.rootState
   * @param {Object} payload
   * @param {null|string} payload.start
   * @param {null|string} payload.end
   * @returns {Promise}
   */
  async fetchItems({ commit, rootState }, { start, end = null }) {
    const studentId = rootState.client.students.currentStudent.id

    const { data, request } = await ApiCalendarItems.get({
      start,
      end,
      studentId,
    })

    commit('setItems', request.status === HttpStatusCodes.NoContent ? [] : data)
  },
}
