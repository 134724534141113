import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiDemoFeSimpleRequestCall = new (class extends ApiBase {
  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  put(params = {}) {
    const urlParams = UrlHelper.param(params)
    const url = `/auth/demo-fe-student-request-call?${urlParams}`

    return this._PUT(url)
  }
})()
