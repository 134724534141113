import ApiBase from 'ApiRest/ApiBase'

export default new (class SubDomainList extends ApiBase {
  /**
   * @param {string} domain
   * @returns {Promise}
   */
  get(domain) {
    return this._GET(`/schools/union/sub-domains/${domain}`)
  }
})()
