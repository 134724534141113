import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesPurchaseNotificationDocuments =
  new (class extends ApiBase {
    /**
     * @returns {Promise}
     */
    get() {
      const url = `/courses/purchases/notifications/documents`

      return this._GET(url)
    }
  })()
