export default () => ({
  /** @type {ShowcaseBanner[]} */
  banners: [],

  /** @type {ShowcaseProduct[]} */
  productsLatest: [],

  /** @type {ShowcaseProduct[]} */
  productsPopular: [],

  seo: {
    /** @type {ShowcaseSeo} */
    main: {
      title: '',
      description: '',
      keywords: '',
      projectDescription: '',
    },

    /** @type {ShowcaseSeo} */
    catalog: {
      title: '',
      description: '',
      keywords: '',
    },
  },

  /** @type {ShowcaseNavigationBlock} */
  navigationBanner: null,
})
