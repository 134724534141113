import { uniq } from 'lodash'

export default {
  localesFallback(state) {
    return uniq([
      state.lang,
      'ru',
    ])
  },

  localeName(state) {
    return state.institutionId
      ? `${state.lang}-${state.institutionId}`
      : state.lang
  },

  isLoadedLocale(state, getters) {
    return state.localesLoaded.includes(getters.localeName)
  },
}
