import invoices from '@/store/showcase/client/invoices/store'
import main from '@/store/showcase/client/main/store'
import notifications from '@/store/showcase/client/notifications/store'
import profile from '@/store/showcase/client/profile/store'
import subscription from '@/store/showcase/client/subscription/store'

export default {
  namespaced: true,
  modules: {
    invoices,
    main,
    notifications,
    profile,
    subscription,
  },
}
