import { ApiSupportContactList } from 'ApiRest/Api/Support/Contact/List'

export default {
  async fetchContacts({ commit, rootGetters }) {
    const { data } = await ApiSupportContactList.get(
      rootGetters['common/support/common/defaultParamForClient'],
    )

    commit('setContact', data)
  },
}
