export const previewTestRoutes = [
  {
    path: '/test/preview/:testId(\\d+)/:variantNumber(\\d+)/start',
    name: 'test-preview-start',
    component: () => import('@/pages/student/test/TestPreviewStart.vue'),
  },
  {
    path: '/test/results/preview/:testId(\\d+)/:attemptId(\\d+)',
    name: 'test-results-preview',
    component: () => import('@/pages/student/test/TestPreviewResults.vue'),
  },
  {
    path: '/test/preview/:testId(\\d+)/:variantNumber(\\d+)',
    name: 'test-preview-hello',
    component: () => import('@/pages/student/test/TestPreviewHello.vue'),
  },
  {
    path: '/test/question/preview/:questionId(\\d+)',
    name: 'test-question-preview',
    component: () => import('@/pages/student/test/TestQuestionPreview.vue'),
  },
]
