import locale from './locale/store'
import login from './login/store'
import passwordCreate from './passwordCreate/store'
import passwordReset from './passwordReset/store'
import registration from './registration/store'

export default {
  namespaced: true,

  modules: {
    locale,
    login,
    passwordCreate,
    passwordReset,
    registration,
  },
}
