import ApiBase from 'ApiRest/ApiBase'

export const ApiTwoFactorAuth = new (class extends ApiBase {
  /**
   * Валидация смс кода
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload) {
    return this._POST('/auth/two-factor-auth', payload)
  }

  /**
   * Повторная отправка смс кода
   * @param {Object} payload
   * @returns {Promise}
   */
  put(payload) {
    return this._PUT('/auth/two-factor-auth', payload)
  }
})()
