import ApiBase from 'ApiRest/ApiBase'

export default new (class ClientTheInvoice extends ApiBase {
  /**
   * @param {string} invoiceId
   * @returns {Promise}
   */
  get(invoiceId) {
    return this._GET(`/account/invoices/${invoiceId}`)
  }

  /**
   * @param {number} invoiceId
   * @param {Object} data
   * @returns {Promise}
   */
  put(invoiceId, data) {
    return this._PUT(`/account/invoices/${invoiceId}`, data)
  }
})()
