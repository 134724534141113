import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountStudentProfileLanguage = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {Object} payload.language
   * @param {number} payload.language.id
   * @returns {Promise}
   */
  put(payload) {
    return this._PUT('/account/student/profile/language', payload)
  }
})()
