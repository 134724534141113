import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLessonJoin = new (class extends ApiBase {
  /**
   * Получить ссылку для входа в конференцию
   * @param {number} onlineLessonId
   * @returns {string}
   */
  get(onlineLessonId) {
    return `${window.domains.apiUrl}/rest/showcase/account/online-lessons/${onlineLessonId}/join`
  }
})()
