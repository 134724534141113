import ApiBase from 'ApiRest/ApiBase'

export const ApiAuthorize = new (class extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    const url = '/educont/oauth/authorize'

    return this._POST(url, data)
  }
})()
