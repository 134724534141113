import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountProductAdModalData = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/product-ad-modal`)
  }
})()
