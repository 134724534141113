import familyEducationMark from '@/helpers/const/familyEducationMark'
import { getActualCertification } from '@/helpers/getActualCertification'
import Constants from 'Constants'

export default {
  getCurrentCertification: (state) => (id) => {
    if (id) {
      return state.certifications.find(
        (certification) => Number(certification.requestId) === Number(id),
      )
    }

    const defaultTab = getActualCertification(state.certifications)

    if (defaultTab) {
      return defaultTab
    }

    if (state.certifications[0]) {
      return state.certifications[0]
    }

    return undefined
  },

  getCurrentCertificationStatus: (state, getters) => (id) => {
    const { tests } = getters.getCurrentCertification(id)
    const { COMPLETED } = Constants.testingStatus

    const isTestCompleted = (test) =>
      test.mark > familyEducationMark.MIN_MARK &&
      test.testingStatus?.id === COMPLETED

    const isTestFailed = (test) =>
      test.mark <= familyEducationMark.MIN_MARK &&
      test.testingStatus?.id === COMPLETED

    const isAllTestsCompleted = tests.every((test) => isTestCompleted(test))

    if (isAllTestsCompleted) {
      return {
        isCompleted: true,
      }
    }

    const testsTotal = tests.length
    let testsFailed = 0
    let testsNotPassed = 0
    let testsPassed = 0

    tests.forEach((test) => {
      if (isTestCompleted(test)) {
        testsPassed += 1
      } else if (isTestFailed(test)) {
        testsFailed += 1
      } else {
        testsNotPassed += 1
      }
    })

    return {
      isCompleted: false,
      testsPassed,
      testsFailed,
      testsNotPassed,
      testsTotal,
    }
  },
}
