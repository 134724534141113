import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Получить все Прохождения Теста у Очного КТП-Теста
   * @param {number} fteCtpTestId
   * @returns {Promise}
   */
  get(fteCtpTestId) {
    return this._GET(
      `/account/student/test/lesson-tests/${fteCtpTestId}/testings`,
    )
  }
})()
