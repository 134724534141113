import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentProfileLastLesson = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/student/profile/last-lesson')
  }
})()
