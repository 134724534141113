import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesMain = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {number} payload.cardsMax
   * @param {number} payload.studentId
   * @returns {Promise}
   */
  get(payload) {
    const url = `/courses/main?${this.getUrlParams(payload)}`

    return this._GET(url)
  }
})()
