import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setStudents(state, students) {
    state.students = students
  },

  setSchool(state, payload) {
    state.school = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setAnalytics(state, payload) {
    state.analytics = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setAnalyticsAcademicYear(state, payload) {
    state.analyticsAcademicYear = payload
  },

  setTheStudent(state, id) {
    state.currentStudent = state.students.find(
      (student) => Number(student.id) === Number(id),
    )
  },

  setIsLoadingSwitchStudent(state, payload) {
    state.isLoadingSwitchStudent = payload
  },

  setActiveSubjects(state, payload) {
    state.activeSubjects = payload
  },

  setStudentToken(state, payload) {
    state.studentToken = payload
  },
}
