import ApiBase from 'ApiRest/ApiBase'

export const ApiDiagnosticsOfferDoc = new (class extends ApiBase {
  /**
   * @param {number} diagnosticsOfferId
   * @returns {Promise}
   */
  get(diagnosticsOfferId) {
    return this._GET(
      `/account/diagnostics/document/${diagnosticsOfferId}/pdf`,
      {
        responseType: 'blob',
      },
    )
  }
})()
