const LOCAL_DOMAINS = [
  '127.0.0.1',
  'localhost',
  '::1',
  '[::]',
  '',
]

export const isLocalNetwork = function (hostname = window.location.hostname) {
  return (
    LOCAL_DOMAINS.includes(hostname) ||
    hostname.startsWith('192.168.') ||
    hostname.startsWith('10.') ||
    hostname.endsWith('.loc') ||
    hostname.endsWith('.local')
  )
}
