import Constants from 'Constants'

export default () => ({
  events: [],

  theEvent: {
    id: null,
    dateTime: null,
    name: null,
    announce: null,
    chatUrl: null,
    categories: [],
    type: null,
    image: null,
  },

  pagination: {
    currentPage: null,
    pagesTotal: null,
  },

  filter: {
    eventType: {
      options: [
        {
          id: 0,
          name: 'Все',
        },
        {
          id: Constants.eventType.EDUCATIONAL,
          name: 'Учебные',
        },
        {
          id: Constants.eventType.NOT_EDUCATIONAL,
          name: 'Внеучебные',
        },
      ],

      value: {
        id: 0,
        name: 'Все',
      },
    },

    category: {
      options: [],
      value: [],
    },
  },
})
