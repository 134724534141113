import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/client/theContract/state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setKinshipDegrees(state, payload) {
    state.kinshipDegrees = payload
  },

  setCitizenships(state, payload) {
    state.citizenships = payload
  },

  setCountries(state, payload) {
    state.countries = payload
  },

  setContractSubmissionTypes(state, payload) {
    state.submissionTypes = payload
  },

  setSubmissionType(state, payload) {
    state.contractSimplified.submissionType = payload
  },

  setMoscowRegistrationTypes(state, payload) {
    state.moscowRegistrationTypes = payload
  },

  setGrades(state, payload) {
    state.grades = payload
  },

  setKinshipDegree(state, payload) {
    if (!state.contractSimplified.kinship) {
      state.contractSimplified.kinship = {}
    }

    state.contractSimplified.kinship.kinshipDegree = payload
  },

  setGrade(state, payload) {
    state.contractSimplified.grade = payload
  },

  setExistingStudent(state, payload) {
    state.existingStudent = payload
  },

  setClientEmail(state, payload) {
    state.contractSimplified.client.email = payload
  },

  setClientFirstName(state, payload) {
    state.contractSimplified.client.firstName = payload
  },

  setClientLastName(state, payload) {
    state.contractSimplified.client.lastName = payload
  },

  setClientMiddleName(state, payload) {
    state.contractSimplified.client.middleName = payload
  },

  setClientPhone(state, payload) {
    state.contractSimplified.client.phone = payload
  },

  setClientCitizenship(state, payload) {
    state.contractSimplified.client.citizenship = payload
  },

  setClientPassportSeries(state, payload) {
    if (!state.contractSimplified.client.passport) {
      state.contractSimplified.client.passport = {}
    }

    state.contractSimplified.client.series = payload
  },

  setClientPassportNumber(state, payload) {
    if (!state.contractSimplified.client.passport) {
      state.contractSimplified.client.passport = {}
    }

    state.contractSimplified.client.number = payload
  },

  setClientPassportIssuedBy(state, payload) {
    if (!state.contractSimplified.client.passport) {
      state.contractSimplified.client.passport = {}
    }

    state.contractSimplified.client.passport.issuedBy = payload
  },

  setClientPassportIssuedDate(state, payload) {
    if (!state.contractSimplified.client.passport) {
      state.contractSimplified.client.passport = {}
    }

    state.contractSimplified.client.passport.issueDate = payload
  },

  setClientPassportDepartmentCode(state, payload) {
    if (!state.contractSimplified.client.passport) {
      state.contractSimplified.client.passport = {}
    }

    state.contractSimplified.client.passport.departmentCode = payload
  },

  setClientPassportCountry(state, payload) {
    if (!state.contractSimplified.client.passport) {
      state.contractSimplified.client.passport = {}
    }

    if (!state.contractSimplified.client.passport.country) {
      state.contractSimplified.client.passport.country = {}
    }

    state.contractSimplified.client.passport.country = payload
  },

  setClientAgreedWithPolitics(state, payload) {
    state.contractSimplified.agreedWithPolitics = payload
  },

  setClientAgreedWithProcessingPDExtramural(state, payload) {
    state.contractSimplified.agreedWithProcessingPDExtramural = payload
  },

  setClientMoscowRegistrationType(state, payload) {
    if (!state.contractSimplified.client.moscowRegistration) {
      state.contractSimplified.client.moscowRegistration = {}
    }

    state.contractSimplified.client.moscowRegistration.type = payload
  },

  setClientPassportValidUntil(state, payload) {
    if (!state.contractSimplified.client.passport) {
      state.contractSimplified.client.passport = {}
    }

    state.contractSimplified.client.passport.validUntil = payload
  },

  setClientAddressPermanent(state, payload) {
    if (!state.contractSimplified.client.address) {
      state.contractSimplified.client.address = {}
    }

    state.contractSimplified.client.address.permanent = payload
  },

  setClientAddressResidence(state, payload) {
    if (!state.contractSimplified.client.address) {
      state.contractSimplified.client.address = {}
    }

    state.contractSimplified.client.address.residence = payload
  },

  setClientAddressTemporary(state, payload) {
    if (!state.contractSimplified.client.address) {
      state.contractSimplified.client.address = {}
    }

    state.contractSimplified.client.address.temporary = payload
  },

  setClientAddressPermanentIndex(state, payload) {
    if (!state.contractSimplified.client.address) {
      state.contractSimplified.client.address = {}
    }

    state.contractSimplified.client.address.permanentIndex = payload
  },

  setClientAddressResidenceIndex(state, payload) {
    if (!state.contractSimplified.client.address) {
      state.contractSimplified.client.address = {}
    }

    state.contractSimplified.client.address.residenceIndex = payload
  },

  setClientAddressTemporaryIndex(state, payload) {
    if (!state.contractSimplified.client.address) {
      state.contractSimplified.client.address = {}
    }

    state.contractSimplified.client.address.temporaryIndex = payload
  },

  setClientAddressResidenceMatchesPermanent(state, payload) {
    if (!state.contractSimplified.client.address) {
      state.contractSimplified.client.address = {}
    }

    state.contractSimplified.client.address.residenceMatchesPermanent = payload
  },

  setClientAddressResidenceMatchesTemporary(state, payload) {
    if (!state.contractSimplified.client.address) {
      state.contractSimplified.client.address = {}
    }

    state.contractSimplified.client.address.residenceMatchesTemporary = payload
  },

  // Данные ученика

  setStudentFillDataType(state, payload) {
    state.contractSimplified.studentFillDataType = payload
  },

  // Заполнение полей студента при выборе в селекте "Выбор существующего ученика"
  setContractStudent(state, payload) {
    state.contractSimplified.student = payload
  },

  setStudentLastName(state, payload) {
    state.contractSimplified.student.lastName = payload
  },

  setStudentFirstName(state, payload) {
    state.contractSimplified.student.firstName = payload
  },

  setStudentMiddleName(state, payload) {
    state.contractSimplified.student.middleName = payload
  },

  setStudentEmail(state, payload) {
    state.contractSimplified.student.email = payload
  },

  setStudentPhone(state, payload) {
    state.contractSimplified.student.phone = payload
  },

  setStudentBirthDate(state, payload) {
    state.contractSimplified.student.birthDate = payload
  },

  setStudentId(state, payload) {
    state.contractSimplified.student.id = payload
  },
}
