import { ApiStudentOnlineSchoolCtpStatementAcademicYears } from 'ApiRest/Api/Account/Student/StatementOnlineSchool/AcademicYears'
import { ApiStudentOnlineSchoolCtpStatement } from 'ApiRest/Api/Account/Student/StatementOnlineSchool/Statement'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchStatements({ commit, state }) {
    const { data } = await ApiStudentOnlineSchoolCtpStatement.get({
      academicYearPreset: {
        id: state.academicYear?.id,
      },
    })

    commit('setStatements', data)
  },

  async fetchAcademicYears({ commit }) {
    const { data } = await ApiStudentOnlineSchoolCtpStatementAcademicYears.get()

    commit('setAcademicYears', data)
  },
}
