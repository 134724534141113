import MimeHelper from '@/helpers/MimeHelper'
import HttpHeaders from 'Api/const/HttpHeaders'

export default class {
  static downloadBlob(response, fileName = null) {
    const blob = new Blob(
      [
        response.data,
      ],
      {
        type: response.headers[HttpHeaders.CONTENT_TYPE],
      },
    )
    const blobUrl = URL.createObjectURL(blob)
    const headerContentDisposition =
      response.headers[HttpHeaders.CONTENT_DISPOSITION]

    let fileNameDownload = null

    if (fileName !== null) {
      fileNameDownload = fileName
    } else {
      if (headerContentDisposition?.includes('attachment;')) {
        if (headerContentDisposition.includes("filename*=utf-8''")) {
          fileNameDownload = decodeURI(
            headerContentDisposition
              .split("filename*=utf-8''")[1]
              ?.split(';')[0],
          )
        } else {
          if (headerContentDisposition.includes('filename')) {
            fileNameDownload = headerContentDisposition
              .split('filename=')[1]
              ?.split(';')[0]
              .replace(/['"]+/g, '')
          }
        }
      }
    }

    if (fileNameDownload === null) {
      fileNameDownload = 'file'
      Object.keys(MimeHelper).forEach((mimeType) => {
        const extension = MimeHelper[mimeType]

        if (blob.type.includes(mimeType)) {
          fileNameDownload = `file.${extension}`
        }
      })
    }

    const link = document.createElement('a')

    link.href = blobUrl
    link.download = fileNameDownload
    link.click()

    URL.revokeObjectURL(link.href)
  }
}
