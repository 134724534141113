import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class Tags extends ApiBase {
  /**
   * Список тегов
   * @param {number} studentId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(studentId, parameters = {}) {
    return this._GET(
      `/account/client/students/${studentId}/news/tags?${UrlHelper.param(parameters)}`,
    )
  }
})()
