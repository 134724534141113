import dateFormat from '@/helpers/const/dateFormat'
import { getDate } from '@/helpers/dateFormatHelper'
import { ApiClientDemoFeSimpleProfile } from 'ApiRest/Api/Account/Client/DemoFeSimple/Profile'
import { parse } from 'date-fns'

export default {
  async fetchForm({ commit }, student) {
    const { data } = await ApiClientDemoFeSimpleProfile.get(student.id)

    commit('setForm', data)
  },

  async updateForm({ state, commit }, student) {
    const studentBirthDate = getDate(
      parse(
        state.form.studentBirthDate,
        dateFormat.DATE_FORMAT_FRONTEND,
        new Date(),
      ),
      dateFormat.DATE_FORMAT_BACKEND,
    )

    const { data } = await ApiClientDemoFeSimpleProfile.patch(student.id, {
      ...state.form,
      studentBirthDate,
    })

    commit('student/profile/setProfile', data, {
      root: true,
    })
  },
}
