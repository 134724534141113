import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseSupportAskQuestionContent = new (class extends ApiBase {
  /**
   * @param {number} contentId
   * @param {Object} payload
   * @returns {Promise}
   */
  post(contentId, payload) {
    return this._POST(`/showcase/ask-question/content/${contentId}`, payload)
  }
})()
