import ApiBase from 'ApiRest/ApiBase'

export default new (class Event extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/account/student/simple-events/${id}`)
  }
})()
