import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountStudentEducontStream = new (class extends ApiBase {
  /**
   * @param {number} gradeId
   * @returns {Promise}
   */
  patch(gradeId) {
    const url = `/account/student/educont-stream`
    const data = {
      grade: {
        id: gradeId,
      },
    }

    return this._PATCH(url, data)
  }
})()
