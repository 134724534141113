import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/courses/common/cart/state'

const sortAddFiledsProducts = (products = []) => {
  products.sort((a, b) => a.acquiringId - b.acquiringId)

  return products.map((product, idx) => {
    const isVariousAcquiring =
      products[idx + 1]?.acquiringId &&
      products[idx + 1].acquiringId !== product.acquiringId

    if (isVariousAcquiring) {
      product.isAcquiring = true
    }

    return {
      ...product,
      isChecked: true,
      hasError: false,
    }
  })
}

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProductsCount(state, productsCount) {
    state.productsCount = productsCount
  },

  setProducts(state, products) {
    state.products = sortAddFiledsProducts(products)
  },

  setProduct(state, { product, index }) {
    state.products[index] = product
  },

  checkProduct(state, product) {
    product.isChecked = true
  },

  uncheckProduct(state, product) {
    product.isChecked = false
  },

  checkAllProducts(state) {
    state.products.forEach((product) => {
      product.isChecked = true
    })
  },

  uncheckAllProducts(state) {
    state.products.forEach((product) => {
      product.isChecked = false
    })
  },

  setPurchase(state, payload) {
    state.purchase = payload
  },

  setInvoices(state, payload) {
    state.invoices = payload
  },

  canCheckedProductsPayWithMaternityCapital(state) {
    state.products.forEach((product) => {
      product.hasError =
        product.isChecked &&
        product.price !== 0 &&
        !product.canBePaidWithMaternityCapital
    })
  },
}
