import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Создать Прохождение Теста для Очного КТП-Теста
   * @param {number} fteCtpTestId
   * @param {number} ctpId
   * @returns {Promise}
   */
  post(fteCtpTestId, ctpId) {
    return this._POST(
      `/account/student/test/lesson-tests/${fteCtpTestId}/${ctpId}/testings`,
    )
  }
})()
