export default () => ({
  profile: {},
  passwords: {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  },
  userSettings: {},
  schoolPlatform: {},
  schoolDocuments: {},
  notifications: {},
  events: {
    telegramBot: {
      isLinked: false,
      link: null,
    },
  },
  subDomains: [],

  options: {
    timezones: [],
  },
})
