<template>
  <div
    :class="$style.controls"
    class="d-flex justify-content-between align-items-center"
  >
    <button class="btn-prev" @click="onClickPrev">
      <img class="icon" :src="icons.prev" alt="icon prev" />
    </button>

    <div class="d-flex justify-content-lg-center px-lg-2">
      <span
        v-for="(_, i) in dots"
        :key="i"
        class="dot"
        :class="cssClassBtnDot(i)"
        @click="() => onClickDot(i)"
      ></span>
    </div>

    <button class="btn-next" @click="onClickNext">
      <img class="icon" :src="icons.next" alt="icon next" />
    </button>
  </div>
</template>

<script>
import iconNext from '@/static/images/common-lk/icons/swiper-next.svg'
import iconPrev from '@/static/images/common-lk/icons/swiper-prev.svg'
import { range } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Controls',

  props: {
    slideIndexCurrent: {
      type: Number,
      required: true,
    },

    slidesCount: {
      type: Number,
      required: true,
    },
  },

  emits: [
    'click:dot',
    'click:prev',
    'click:next',
  ],

  computed: {
    icons() {
      return {
        prev: iconPrev,
        next: iconNext,
      }
    },

    dots() {
      return range(1, this.slidesCount + 1)
    },
  },

  methods: {
    /**
     * @param {number} index
     * @returns {Object}
     */
    cssClassBtnDot(index) {
      return {
        active: this.slideIndexCurrent === index,
      }
    },

    /**
     * @param {number} index
     */
    onClickDot(index) {
      this.$emit('click:dot', index)
    },

    onClickPrev() {
      this.$emit('click:prev')
    },

    onClickNext() {
      this.$emit('click:next')
    },
  },
})
</script>

<style lang="scss" module>
@import '~@/scss/variables';

.controls {
  padding: 0 16px;

  @media (min-width: $md) {
    padding: 0;
  }

  [class~='dot'] {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    cursor: pointer;
    background: $black;
    border-radius: 50%;
    opacity: 0.08;
  }

  [class~='dot'][class~='active'] {
    background: $blue-1;
    opacity: 1;
  }

  [class~='btn-prev'],
  [class~='btn-next'] {
    $size: 24px;

    display: flex;
    width: $size;
    height: $size;
    padding: 0;
    background: none;
    border: 0;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;

    [class~='icon'] {
      width: 9px;
    }
  }

  [class~='btn-prev'] {
    padding-right: 2px;
  }

  [class~='btn-next'] {
    padding-left: 2px;
  }
}
</style>
