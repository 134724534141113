import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Расписание уроков
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/student/timetable`)
  }
})()
