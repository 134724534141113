import actions from '@/store/showcase/common/category/actions'
import getters from '@/store/showcase/common/category/getters'
import mutations from '@/store/showcase/common/category/mutations'
import state from '@/store/showcase/common/category/state'

export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,
}
