import { ApiAccountStudentPromoCtps } from 'Api/rest/Api/Account/Promo/Student/Ctps'
import { ApiAccountStudentPromoProducts } from 'Api/rest/Api/Account/Promo/Student/Products'

export default {
  async fetchPromoProducts({ commit, rootState }) {
    const { data } = await ApiAccountStudentPromoProducts.get(
      rootState.client.students.currentStudent.id,
    )

    commit('setPromoProducts', data)
  },

  async fetchPromoCtps({ commit, rootState }) {
    const { data } = await ApiAccountStudentPromoCtps.get(
      rootState.client.students.currentStudent.id,
    )

    commit('setPromoCtps', data)
  },

  fetchPromoCampaigns({ dispatch }) {
    return Promise.all([
      dispatch('fetchPromoCtps'),
      dispatch('fetchPromoProducts'),
    ])
  },
}
