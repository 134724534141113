import ApiBase from 'ApiRest/ApiBase'

export default new (class CertificationList extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/student/certifications')
  }
})()
