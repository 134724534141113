export default () => ({
  journal: {
    dates: [],
  },

  datesRange: {
    startDate: null,
    endDate: null,
  },
})
