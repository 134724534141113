/**
 * @typedef {Object} IncomeDiagnostics
 * @property {string} comment
 * @property {boolean} isDone
 * @property {number} mark
 * @property {string} subjectName
 * @property {string} testName
 * @property {number} testAvailabilityTime
 */

export default () => ({
  testingResults: {
    /**
     * @type {IncomeDiagnostics[]}
     */
    extramuralDiagnostics: [],

    /**
     * @type {IncomeDiagnostics[]}
     */
    intramuralDiagnostics: [],

    retakes: [],
    testAvailableTo: '',
  },
})
