import ApiBase from 'ApiRest/ApiBase'

export default new (class GoalAchieved extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  put(id) {
    return this._PUT(`/account/student/goals/${id}/achieved`)
  }
})()
