import ApiBase from 'ApiRest/ApiBase'

export default new (class SubjectActive extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/student/active-subjects')
  }
})()
