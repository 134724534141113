<template>
  <ElModal :name="name" @before-open="onBeforeOpen">
    <ContentWithPreloader :is-loading="isLoading.form">
      <!-- TODO: i18n -->
      <h2 class="h2">Успешная оплата, укажите данные</h2>

      <!-- TODO: i18n -->
      <div class="mb-3 text-gray">
        Для обучения необходимо подтвердить данные
      </div>

      <section>
        <div class="mb-3">
          <!-- TODO: i18n -->
          <h3 class="h3">Данные клиента</h3>

          <div class="row">
            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="clientLastName"
                :invalid="v$.clientLastName.$error"
                :errors="v$.clientLastName.$errors"
                label="Фамилия"
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="clientFirstName"
                :invalid="v$.clientFirstName.$error"
                :errors="v$.clientFirstName.$errors"
                label="Имя"
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="clientMiddleName"
                :invalid="v$.clientMiddleName.$error"
                :errors="v$.clientMiddleName.$errors"
                label="Отчество"
                hint='Если нет, то введите "-"'
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="clientPhone"
                :invalid="v$.clientPhone.$error"
                :errors="v$.clientPhone.$errors"
                label="Телефон"
                required
              />
            </div>
          </div>
        </div>

        <!-- TODO: i18n -->
        <div
          v-if="isRendered.clientCredentialsMessage"
          class="mb-3 text-pink font-size-16"
        >
          Данные для входа в личный кабинет клиента отправлены на электронную
          почту
        </div>

        <div class="mb-3">
          <!-- TODO: i18n -->
          <h3 class="h3">Данные ученика</h3>

          <div class="row">
            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="studentLastName"
                :invalid="v$.studentLastName.$error"
                :errors="v$.studentLastName.$errors"
                label="Фамилия"
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="studentFirstName"
                :invalid="v$.studentFirstName.$error"
                :errors="v$.studentFirstName.$errors"
                label="Имя"
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElInput
                v-model="studentMiddleName"
                :invalid="v$.studentMiddleName.$error"
                :errors="v$.studentMiddleName.$errors"
                label="Отчество"
                hint='Если нет, то введите "-"'
                required
              />
            </div>

            <div class="col-lg-6">
              <!-- TODO: i18n -->
              <ElDatePicker
                v-model="studentBirthDate"
                :invalid="v$.studentBirthDate.$error"
                :errors="v$.studentBirthDate.$errors"
                label="Дата рождения"
                required
              />
            </div>
          </div>
        </div>
      </section>

      <!-- TODO: i18n -->
      <ElButton class="btn-primary" label="Сохранить" @click="onClickBtnSave" />
    </ContentWithPreloader>
  </ElModal>
</template>

<script>
import {
  checkForErrorsMessage,
  nameErrorMessage,
  validateDate,
  validateName,
  validatePhone,
} from '@/helpers/ValidationHelper'
import dateFormat from '@/helpers/const/dateFormat'
import { getDate } from '@/helpers/dateFormatHelper'
import { required } from '@/helpers/i18n-validators'
import { showErrors, showToast } from '@/helpers/toast'
import ContentWithPreloader from '@/ui/ContentWithPreloader.vue'
import ElButton from '@/ui/ElButton.vue'
import ElDatePicker from '@/ui/ElDatePicker.vue'
import ElInput from '@/ui/ElInput.vue'
import ElModal from '@/ui/ElModal.vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
import { defineComponent } from 'vue'
import { useVfm } from 'vue-final-modal'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ModalProfileUpdate',

  components: {
    ContentWithPreloader,
    ElInput,
    ElDatePicker,
    ElModal,
    ElButton,
  },

  props: {
    student: {
      type: Object,
      default: null,
    },

    form: {
      type: Object,
      default: null,
    },

    setFormProp: {
      type: Function,
      default: () => {},
    },

    fetchForm: {
      type: Function,
      default: () => {},
    },

    updateForm: {
      type: Function,
      default: () => {},
    },
  },

  emits: [
    'success',
  ],

  setup() {
    const vfm = useVfm()

    return {
      v$: useVuelidate({
        $stopPropagation: true,
      }),

      vfm,
    }
  },

  validations() {
    return {
      clientLastName: {
        required,
        validateName: helpers.withMessage(nameErrorMessage, validateName),
      },

      clientFirstName: {
        required,
        validateName: helpers.withMessage(nameErrorMessage, validateName),
      },

      clientMiddleName: {
        validateName: helpers.withMessage(nameErrorMessage, validateName),
      },

      clientPhone: {
        required,
        validatePhone: helpers.withMessage(
          'Телефон должен содержать не менее 10 цифр',
          validatePhone,
        ),
      },

      studentLastName: {
        required,
        validateName: helpers.withMessage(nameErrorMessage, validateName),
      },

      studentFirstName: {
        required,
        validateName: helpers.withMessage(nameErrorMessage, validateName),
      },

      studentMiddleName: {
        validateName: helpers.withMessage(nameErrorMessage, validateName),
      },

      studentBirthDate: {
        required,
        validateDate,
      },
    }
  },

  data() {
    return {
      name: 'modalProfileUpdate',

      isLoading: {
        form: false,
        btnSave: false,
      },
    }
  },

  computed: {
    ...mapGetters('common/user', [
      'isStudent',
    ]),

    isRendered() {
      return {
        clientCredentialsMessage: this.isStudent,
      }
    },

    clientLastName: {
      get() {
        return this.form?.clientLastName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'clientLastName',
          value,
        })
      },
    },

    clientFirstName: {
      get() {
        return this.form?.clientFirstName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'clientFirstName',
          value,
        })
      },
    },

    clientMiddleName: {
      get() {
        return this.form?.clientMiddleName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'clientMiddleName',
          value,
        })
      },
    },

    clientPhone: {
      get() {
        return this.form?.clientPhone ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'clientPhone',
          value,
        })
      },
    },

    studentLastName: {
      get() {
        return this.form?.studentLastName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'studentLastName',
          value,
        })
      },
    },

    studentFirstName: {
      get() {
        return this.form?.studentFirstName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'studentFirstName',
          value,
        })
      },
    },

    studentMiddleName: {
      get() {
        return this.form?.studentMiddleName ?? ''
      },

      set(value) {
        this.setFormProp({
          key: 'studentMiddleName',
          value,
        })
      },
    },

    studentBirthDate: {
      get() {
        return this.form?.studentBirthDate ?? null
      },

      set(value) {
        this.setFormProp({
          key: 'studentBirthDate',
          value: getDate(value, dateFormat.DATE_FORMAT_FRONTEND),
        })
      },
    },
  },

  methods: {
    async save() {
      try {
        await this.updateForm(this.student)

        // TODO: i18n
        showToast('Данные сохранены')

        this.$emit('success')
        this.hide()
      } catch (error) {
        showErrors(error)
      }
    },

    async onBeforeOpen() {
      this.isLoading.form = true

      try {
        await this.fetchForm(this.student)
      } catch (error) {
        showErrors(error)
      }

      this.isLoading.form = false
    },

    async onClickBtnSave() {
      if (!(await this.v$.$validate())) {
        showToast(checkForErrorsMessage, 'error')

        return false
      }

      this.isLoading.btnSave = true

      await this.save()

      this.isLoading.btnSave = false

      return true
    },

    /**
     * @public
     */
    show() {
      this.vfm.open(this.name)
    },

    /**
     * @public
     */
    hide() {
      this.vfm.close(this.name)
    },
  },
})
</script>
