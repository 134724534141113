import { getCategoriesFlatten } from '@/helpers/showcase/getCategoriesFlatten'

export default {
  categoriesById(state) {
    return getCategoriesFlatten(state.categories).reduce(
      (categoriesById, category) => ({
        ...categoriesById,
        [category.id]: category,
      }),
      {},
    )
  },
}
