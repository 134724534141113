import ApiBase from 'ApiRest/ApiBase'

export default new (class Lesson extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/account/student/ctp/lessons/${id}`)
  }
})()
