import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Отдельная ведомость по предмету по ученику очной формы обучения
   * @param {number} groupId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(groupId, parameters = {}) {
    return this._GET(
      `/account/student/full-time-ctp-statement/group/${groupId}?${UrlHelper.param(parameters)}`,
    )
  }
})()
