// TODO: remove
export const tempCollection = {
  id: 1,
  name: 'Сборник теорий игр',
  costFull: '18 500 ₽',
  cost: '15 000 ₽',
  breadcrumbsCategory: [
    {
      id: 1,
      name: 'Дополнительное образование',
    },
    {
      id: 2,
      name: 'Подкатегория',
    },
  ],
  products: [
    {
      id: 1,
      title: 'Разработчик игр на Unity с нуля до Middle',
      date: '6 месяцев, с 12 сентября',
      image: {
        id: 1,
        url: 'https://picsum.photos/254/169',
      },
      category: {
        id: 1,
        name: 'Курс',
      },
      description: null,
      isCollection: false,
    },
    {
      id: 2,
      title: 'Историческая география',
      date: null,
      image: {
        id: 2,
        url: 'https://picsum.photos/254/170',
      },
      category: {
        id: 2,
        name: 'Студии',
      },
      description: null,
      isCollection: false,
    },
    {
      id: 3,
      title: 'Элементарная музыкальная теория и грамота',
      date: '8 месяцев, с 2 августа',
      image: {
        id: 3,
        url: 'https://picsum.photos/254/171',
      },
      category: {
        id: 3,
        name: 'Очные события',
      },
      description: null,
      isCollection: false,
    },
    {
      id: 4,
      title: 'Информатика',
      date: '2 месяца, с 14 июня',
      image: {
        id: 4,
        url: 'https://picsum.photos/254/172',
      },
      category: {
        id: 4,
        name: 'Клуб',
      },
      description: null,
      isCollection: false,
    },
  ],
}
