export const scrollBehavior = (to, from, savedPosition) => {
  if (
    to.matched.some((route) => route.meta.isDisabledScroll) &&
    from.path === to.path
  ) {
    return {}
  }

  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    return {
      el: to.hash,
      behavior: 'smooth',
      left: 0,
      top: 80,
    }
  }

  return {
    left: 0,
    top: 0,
  }
}
