import ApiBase from 'ApiRest/ApiBase'

export const ApiClientShowcaseCtpStatementAcademicYears =
  new (class extends ApiBase {
    /**
     * Список учебных годов для ведомостей КТП, приобретенных через внутреннюю/внешнюю витрину
     * @param {number} studentId
     * @param {Object} parameters
     * @returns {Promise}
     */
    get(studentId, parameters = {}) {
      return this._GET(
        `/account/client/students/${studentId}/showcase-ctp-statement/academic-years?${this.getUrlParams(
          parameters,
        )}`,
      )
    }
  })()
