import { RouterUser } from '@/RouterUser'
import { showToast } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiChangePassword from 'ApiRest/Api/User/ChangePassword'
import ApiPasswordRecoveryConfirm from 'ApiRest/Api/User/PasswordRecoveryConfirm'
import ApiPasswordRecoveryToken from 'ApiRest/Api/User/PasswordRecoveryToken'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  setStage({ commit }, stage) {
    commit('setStage', stage)
  },

  async resetStage1({ state, commit }) {
    if (state.isLoading) {
      return false
    }

    commit('setIsLoading', true)

    const payload = {
      email: state.email,
    }

    try {
      const { status } = await ApiPasswordRecoveryToken.patch(payload)

      if (status === HttpStatusCodes.Ok) {
        commit('setStage', 2)
      }
    } catch (error) {
      const status = error?.response?.status
      const message = error?.response?.data?.message

      if (status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.global.t(
            'store.auth.toasted_message.success.mail_was_not_found',
          ),
          'error',
        )
      }

      if (message) {
        showToast(message, 'error')
      }
    }

    commit('setIsLoading', false)

    return true
  },

  async resetStage3CheckToken({ state, commit }) {
    if (state.isLoading) {
      return false
    }

    commit('setIsLoading', true)

    try {
      await ApiPasswordRecoveryConfirm.patch({
        code: state.code,
      })
    } catch (error) {
      const status = error?.response?.status

      if (status === HttpStatusCodes.NotFound) {
        commit('setInputValue', {
          name: 'code',
        })

        showToast(
          i18n.global.t('store.auth.toasted_message.success.link_is_outdated'),
          'error',
        )

        RouterUser.router.push({
          name: 'password-reset',
        })
      }
    }

    commit('setIsLoading', false)

    return true
  },

  async resetStage3({ state, commit }) {
    if (state.isLoading) {
      return false
    }

    commit('setIsLoading', true)

    const payload = {
      code: state.code,
      newPassword: state.password,
      confirmPassword: state.passwordRepeat,
    }

    try {
      await ApiChangePassword.patch(payload)

      showToast(
        i18n.global.t(
          'store.auth.toasted_message.success.password_successfully_changed',
        ),
      )

      RouterUser.router.push({
        name: 'login',
      })
    } catch (error) {
      const message = error?.response?.data?.message

      if (message) {
        showToast(message, 'error')
      }
    }

    commit('setIsLoading', false)

    return true
  },
}
