import { ApiSupportFAQList } from 'ApiRest/Api/Support/FAQ/List'

export default {
  async fetchFAQItems({ commit, rootGetters }) {
    const parameters = {
      expand: 'items',
      ...rootGetters['common/support/common/defaultParamForClient'],
    }

    const { data } = await ApiSupportFAQList.get(parameters)

    commit('setFAQItems', data)
  },
}
