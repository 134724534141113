import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseCartConsumer = new (class extends ApiBase {
  /**
   * Обновление получателя офера корзины
   * @param {Object} payload
   * @param {number} payload.id
   * @param {Object} payload.consumer
   * @param {number} payload.consumer.id
   * @returns {Promise}
   */
  patch(payload) {
    const url = '/cart/consumer'

    return this._PATCH(url, payload)
  }
})()
