import ApiCoursesPurchaseUploadDoc from 'ApiRest/Api/Courses/Purchase/UploadDoc'
import Constants from 'Constants'
import { isEmpty, isNull } from 'lodash'

export default {
  clientDocumentUploadUrl(state) {
    return ApiCoursesPurchaseUploadDoc.getFileUploadUrl(state.purchaseId)
  },

  clientHasCitizenship(state) {
    return !isNull(state.customer.citizenship)
  },

  passport(state) {
    return state.customer?.passport ?? null
  },

  clientHasPassport(state) {
    return !isNull(state.customer.passport)
  },

  countriesFiltered(state) {
    return state.options.countryList.filter(
      (country) => country.id !== Constants.country.RUSSIA,
    )
  },

  hasPurchases(state) {
    return !isEmpty(state.purchases)
  },

  hasProductsExtracurricular(state, getters) {
    return !isEmpty(getters.productsExtracurricular)
  },

  hasProductsCommon(state, getters) {
    return !isEmpty(getters.productsCommon)
  },

  hasSignedDocuments(state) {
    return !isEmpty(state.purchase.signedDocuments)
  },

  hasAddress(state) {
    return !isEmpty(state.customer.address)
  },

  hasAgreements(state) {
    return !isEmpty(state.purchase.agreements)
  },

  hasClientDocuments(state) {
    return !isEmpty(state.purchase.clientDocuments)
  },

  hasSomeCustomerRelationshipTypeContract(state) {
    return state.purchase.items.some(
      (item) =>
        item.customerRelationshipType.id ===
        Constants.customerRelationshipType.CONTRACT,
    )
  },

  hasEveryCustomerRelationshipTypeOffer(state) {
    return state.purchase.items.every(
      (item) =>
        item.customerRelationshipType.id ===
        Constants.customerRelationshipType.OFFER,
    )
  },

  hasProductDocuments(state, getters) {
    return !isEmpty(getters.productDocuments)
  },

  hasProductsWithDocumentsUploadRequired(state) {
    return state.purchase.items.some(
      ({ isDocumentsUploadRequired }) => isDocumentsUploadRequired,
    )
  },

  hasRequiredDocuments(state, getters) {
    return !isEmpty(getters.requiredDocumentsList)
  },

  isAwaitingDocuments(state) {
    return state.purchase.items.some((item) => item.isAwaitingDocuments)
  },

  isClientCitizenshipRussia(state) {
    return (
      state.customer.citizenship.id === Constants.citizenship.RUSSIAN_FEDERATION
    )
  },

  isClientCitizenshipOtherCountry(state) {
    return state.customer.citizenship.id === Constants.citizenship.OTHER_COUNTRY
  },

  isPurchaseAwaitingPayment(state) {
    return state.purchase.status.id === Constants.orderStatus.AWAITING_PAYMENT
  },

  isPurchaseClearanceRequired(state) {
    return state.purchase.status.id === Constants.orderStatus.CLEARANCE_REQUIRED
  },

  isPurchasePaidOrConcluded(state) {
    const statusIds = [
      Constants.orderStatus.PAID,
      Constants.orderStatus.PARTIALLY_PAID,
      Constants.orderStatus.CONCLUDED,
    ]

    return statusIds.includes(state.purchase.status.id)
  },

  isPurchasePending(state) {
    const statusIds = [
      Constants.orderStatus.CLEARANCE_REQUIRED,
      Constants.orderStatus.PARTIALLY_PAID,
      Constants.orderStatus.AWAITING_PAYMENT,
    ]

    return statusIds.includes(state.purchase.status.id)
  },

  productsCommon(state) {
    return state.purchase.items.filter(
      ({ isExtracurricular }) => !isExtracurricular,
    )
  },

  productDocuments(state) {
    return state.purchase.items
      .filter(({ documents }) => !isEmpty(documents))
      .flatMap((item) =>
        item.documents.map((document) => ({
          document,
          productName: item.name,
          itemId: item.id,
        })),
      )
  },

  productsExtracurricular(state) {
    return state.purchase.items.filter(
      ({ isExtracurricular }) => isExtracurricular,
    )
  },

  requiredDocumentsList(state) {
    return state.purchase.items
      .filter((item) => item.requiredDocumentsList)
      .map((item) => ({
        itemName: item.name,
        requiredDocumentsList: item.requiredDocumentsList,
      }))
  },

  isAgreeWithConditions(state) {
    return state.purchase?.agreements?.every(({ checked }) => checked) ?? true
  },

  isEveryItemFree(state) {
    return state.purchase.items.every((item) => item.price === 0)
  },
}
