import ApiBase from 'ApiRest/ApiBase'

export const ApiEducontGrades = new (class extends ApiBase {
  /**
   * @returns {Promise<Object>}
   */
  get() {
    const url = `/account/student/educont-grades`

    return this._GET(url)
  }
})()
