export default {
  fullTimeDiagnostics(state) {
    return state.testingResults.intramuralDiagnostics ?? []
  },

  extramuralDiagnostics(state) {
    return state.testingResults.extramuralDiagnostics ?? []
  },

  retakes(state) {
    return state.testingResults.retakes ?? []
  },
}
