import {
  types as calendarItemTypes,
  formatItem as formatCalendarItem,
  formatCalendarItemDates,
  getLessonStatus,
} from '@/helpers/Calendar'
import { cloneDeep, merge } from 'lodash'

export default {
  /**
   * Онлайн-уроки без темы не должны отображаться в ЛК ученика
   * @param {{
   *   items: CalendarItemI[]
   * }} state
   * @param {Object} getters
   * @param {Object} rootState
   * @param {Object} rootGetters
   * @returns {CalendarItemI[]}
   */
  events(state, getters, rootState, rootGetters) {
    const timezone = rootGetters['helper/timezone']

    return cloneDeep(state.items)
      .filter(
        (item) =>
          !(
            Number(item?.type?.id) === calendarItemTypes.ONLINE_LESSON &&
            item?.name === ''
          ) &&
          // TODO: Временно скрыты контрольные работы (item.type.id === 1) в рамках задачи https://redmine.ibls.tech/issues/8720
          Number(item?.type?.id) !== 1,
      )
      .map((item) => formatCalendarItemDates(item, timezone))
      .map((item) => formatCalendarItem(item))
  },

  /**
   * Онлайн-уроки с дополнительными данными
   * Уроки без темы не должны отображаться в ЛК ученика
   * @param {Object} state
   * @param {Object} getters
   * @param {Object} rootState
   * @param {Object} rootGetters
   * @returns {{extraData: {Object}, id: number, type: {id: number, name: string}}[]}
   */
  calendarEvents(state, getters, rootState, rootGetters) {
    const timezone = rootGetters['helper/timezone']
    const serverDateToZonedTime = rootGetters['helper/serverDateToZonedTime']

    return cloneDeep(state.calendarItems)
      .filter(
        (item) =>
          !(
            Number(item?.type?.id) === calendarItemTypes.ONLINE_LESSON &&
            item?.name === ''
          ) &&
          // TODO: Временно скрыты контрольные работы (item.type.id === 1) в рамках задачи https://redmine.ibls.tech/issues/8720
          Number(item?.type?.id) !== 1,
      )
      .map((item) => formatCalendarItemDates(item, timezone))
      .map((item) => {
        if (Number(item?.type?.id) === calendarItemTypes.ONLINE_LESSON) {
          return merge(formatCalendarItem(item), {
            extraData: {
              status: getLessonStatus(item, serverDateToZonedTime),
            },
          })
        }

        return formatCalendarItem(item)
      })
  },
}
