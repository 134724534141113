import ApiBase from 'ApiRest/ApiBase'

export const ApiActionLog = new (class extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/actionLog/log/post', data)
  }
})()
