import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiLibraryClientItems } from 'ApiRest/Api/Account/Client/LibraryItems'

export default {
  /**
   * @param {Object} args
   * @param {Function} args.commit
   * @param {Object} args.rootGetters
   * @returns {Promise}
   */
  async fetchItems({ commit, rootGetters }) {
    const studentId = rootGetters['client/students/currentStudentId']

    const { data, request } = await ApiLibraryClientItems.get({
      studentId,
    })

    commit('setItems', request.status === HttpStatusCodes.NoContent ? [] : data)
  },

  /**
   * @param {Object} args
   * @param {Function} args.commit
   * @returns {Promise}
   */
  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setDateServer', data)
  },
}
