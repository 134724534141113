import ApiBase from 'ApiRest/ApiBase'

export const ApiCreateClientStudent = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  post() {
    const url = `/account/client/create-client-student`

    return this._POST(url)
  }
})()
