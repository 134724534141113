import ApiBase from 'ApiRest/ApiBase'

export const ApiUserRedirect = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/profile/get-redirect`)
  }
})()
