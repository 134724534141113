import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseSupportQuestion = new (class extends ApiBase {
  /**
   * @param {number} questionId
   * @returns {Promise}
   */
  get(questionId) {
    const url = `/showcase/questions/${questionId}`

    return this._GET(url)
  }

  /**
   * @param {number} questionId
   * @param {Object} payload
   * @returns {Promise}
   */
  patch(questionId, payload) {
    const url = `/showcase/questions/${questionId}`

    return this._PATCH(url, payload)
  }

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload) {
    const url = '/showcase/question'

    return this._POST(url, payload)
  }
})()
