export default () => ({
  students: [],
  currentStudent: {},
  school: {},
  isLoadingSwitchStudent: false,
  activeSubjects: [],
  analytics: {},
  analyticsAcademicYear: null,
  studentToken: null,
})
