import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountProductAdLog = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {number} payload.productAdId
   * @param {number} payload.actionTypeId
   * @returns {Promise}
   */
  post(payload) {
    return this._POST(`/account/product-ad/log`, payload)
  }
})()
