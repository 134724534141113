export default () => ({
  lessons: [],
  detailLessons: {},
  isShownCalendar: true,
  isRunningLessons: {},
  url: '',
  timestamp: 0,
  promoLessons: [],
  recording: null,
})
