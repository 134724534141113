import ApiPasswordChange from 'ApiRest/Api/Account/Client/PasswordChange'
import ApiProfile from 'ApiRest/Api/Account/Client/Profile'
import { ApiProfilePhoto } from 'ApiRest/Api/Account/Profile/Photo'
import ApiOption from 'ApiRest/Api/Option'
import UserSettings from 'ApiRest/Api/UserSettings'

export default {
  async fetchProfile({ commit }) {
    const { data } = await ApiProfile.get()

    commit('setProfile', data)

    commit(
      'common/user/setUserValue',
      {
        key: 'email',
        value: data.email,
      },
      {
        root: true,
      },
    )
  },

  updateProfile({ state }) {
    return ApiProfile.patch(state.profile)
  },

  updateProfilePassword({ state }) {
    return ApiPasswordChange.patch(state.passwords)
  },

  async fetchUserSettings({ commit }) {
    const { data } = await UserSettings.get({
      name: 'commonSettings',
    })

    if (data !== null) {
      commit('setUserSettings', data)
    }
  },

  async updateUserSettings({ state, commit }, payload) {
    const { data } = await UserSettings.put({
      name: 'commonSettings',
      data: {
        ...state.userSettings?.data,
        ...payload,
      },
    })

    commit('setUserSettings', data)
  },

  async uploadProfilePhoto({ commit }, payload) {
    const { data } = await ApiProfilePhoto.post(payload)

    commit('setProfileValue', {
      key: 'photo',
      value: data,
    })
  },

  async removeProfilePhoto({ commit }) {
    await ApiProfilePhoto.delete()

    commit('setProfileValue', {
      key: 'photo',
      value: null,
    })
  },

  async fetchOptionTimezoneList({ commit }) {
    const { data } = await ApiOption.getTimezones()

    commit('setOptionValue', {
      path: 'timezones',
      value: data,
    })
  },
}
