import ApiBase from 'ApiRest/ApiBase'

export default new (class Goal extends ApiBase {
  /**
   * @param {Object} data
   * @property {string} startDateTime
   * @property {string} title
   * @property {string} endDateTime
   * @property {boolean} isDaylong
   * @property {string} description
   * @property {Object} subject
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/account/student/goals', data)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    return this._DELETE(`/account/student/goals/${id}`)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/account/student/goals/${id}`, data)
  }
})()
