import cart from '@/store/courses/student/cart/store'
import product from '@/store/courses/student/product/store'

export default {
  namespaced: true,

  modules: {
    cart,
    product,
  },
}
