import { encode } from '@/helpers/userSettings/coding'
import { isArray, isObject } from 'lodash'

export default {
  categoriesById(state, getters, rootState, rootGetters) {
    return rootGetters['courses/common/categoriesById']
  },

  category(state, getters) {
    const { categoryId } = state

    return categoryId ? getters.categoriesById[categoryId] : null
  },

  parentCategory(state, getters) {
    const parentCategoryId = getters.category?.parentCategoryId

    return parentCategoryId ? getters.categoriesById[parentCategoryId] : null
  },

  hasProducts(state) {
    return state.products.length > 0
  },

  settings(state) {
    const settings = {
      sort: state.sort.value.value,
      page: state.pagination.page,
      rowsPerPage: state.pagination.rowsPerPage,

      filters: state.filters.reduce((filterValuesByCode, filter) => {
        let { value } = filter

        if (isArray(value)) {
          value = value.map((option) => option.id)
        } else if (isObject(value)) {
          value = value.id
        }

        return {
          ...filterValuesByCode,
          [filter.code]: value,
        }
      }, {}),
    }

    if (state.categoryId) {
      settings.category = {
        id: state.categoryId,
      }
    }

    if (state.tag) {
      settings.tag = state.tag
    }

    if (state.search) {
      settings.search = state.search
    }

    return settings
  },

  getQuery(state, getters) {
    /**
     * @param {Object} settings
     * @param {string} settings.search
     * @param {string} settings.tag
     * @param {Object} settings.category
     * @param {number} settings.category.id
     * @param {Object} settings.sort
     * @returns {string}
     */
    return (settings = {}) => ({
      settings: encode(
        JSON.stringify({
          ...getters.settings,
          ...settings,
        }),
      ),
    })
  },

  query(state, getters) {
    return getters.getQuery()
  },
}
