import ApiBase from 'ApiRest/ApiBase'

export const ApiSubscriptionRemoveCard = new (class extends ApiBase {
  /**
   *
   * @param {number} subscriptionId
   * @returns {Promise}
   */
  put(subscriptionId) {
    return this._PUT(
      `/account/client/user-subscriptions/${subscriptionId}/remove-payment-method`,
    )
  }
})()
