import { educationTypeIds } from '@/helpers/onlineSchool/educationTypeIds'

export default () => ({
  tariffs: [
    {
      id: 1,
      name: 'Заочный',
      cost: 'от 7 494 ₽ / месяц',
      discount: null,
      promoLabel: null,
      description: [
        'Зачисление ребенка в контингент школы на весь период обучения',
        '<span class="font-weight-bold">Потоковые онлайн-занятия с преподавателями по всем предметам</span>',
        'Широкий выбор внеурочной деятельности',
        'Предоставление учебников с возможностью доставки по всей стране',
      ],
      btnLabel: 'Выбрать тариф',
      secondaryText:
        'Стоимость указана за календарный год обучения, включая каникулы',
      position: 10,
      isActive: false,
    },
    {
      id: 3,
      name: 'IBLS+',
      cost: 'от 3 494 ₽ / месяц',
      discount: null,
      promoLabel: null,
      description: [
        'Можно учиться без зачисления в школу',
        'Онлайн-занятия по школьным предметам в группе, численностью до 20 человек',
        '<span class="font-weight-bold">Проверка домашних заданий, поддержка тьютора и доступ к записям прошедших уроков</span>',
        'Выбор только необходимых или всех предметов школьной программы',
        '<span class="font-weight-bold">Профильная подготовка к ОГЭ / ЕГЭ</span>',
      ],
      btnLabel: 'Собрать тариф',
      position: 20,
      isActive: true,
    },
    {
      id: 2,
      name: 'Семейный',
      cost: 'от 1 067 ₽ / месяц',
      description: [
        'Зачисление в школу происходит на время прохождения аттестации',
        'Доступ к образовательному контенту: видеоуроки, тесты и демо-версии контрольных работ',
        'Удобные периоды промежуточной аттестации',
        '<span class="font-weight-bold">Старт обучения в любой период календарного года</span>',
        'Обучение в индивидуальном ритме ребенка',
      ],
      btnLabel: 'Выбрать тариф',
      secondaryText:
        'Стоимость указана за календарный год обучения, включая каникулы',
      position: 30,
      isActive: false,
    },
  ],
  educationTypeId: educationTypeIds.ONLINE_SCHOOL,
})
