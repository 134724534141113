export const guestShowcaseRoutes = [
  {
    path: '',
    component: () => import('@/layouts/showcase/Showcase.vue'),

    children: [
      {
        path: '',
        name: 'guest-showcase',
        component: () => import('@/pages/showcase/common/Home.vue'),
      },

      {
        path: 'catalog',
        name: 'guest-showcase-catalog',
        component: () => import('@/pages/showcase/common/Catalog.vue'),
      },

      {
        path: 'category/:category',
        name: 'guest-showcase-category',
        component: () => import('@/pages/showcase/common/Category.vue'),
      },

      {
        path: 'category/:category/:product',
        name: 'guest-showcase-product',
        component: () => import('@/pages/showcase/common/Product.vue'),
      },

      {
        path: 'collections/:collectionId(\\d+)',
        name: 'guest-showcase-collection',
        component: () => import('@/pages/showcase/common/Collection.vue'),
      },

      {
        path: 'cart',
        name: 'guest-showcase-cart',
        component: () => import('@/pages/showcase/common/Cart.vue'),
      },

      {
        path: '/cart/:offerId(\\d+)',
        name: 'guest-showcase-product-link',
        component: () => import('@/pages/showcase/common/ProductLink.vue'),
      },

      {
        path: '/product-access-link/category/:category/:product',
        name: 'guest-showcase-product-access-link',
        component: () => import('@/pages/showcase/common/Product.vue'),
      },
    ],
  },
]
