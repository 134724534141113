import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setFirstName(state, payload) {
    state.firstName = payload
  },

  setLastName(state, payload) {
    state.lastName = payload
  },

  setMiddleName(state, payload) {
    state.middleName = payload
  },

  setEmail(state, payload) {
    state.email = payload
  },

  setGrade(state, payload) {
    state.grade = payload
  },

  setFormEducationType(state, payload) {
    state.educationType = payload
  },

  setDemoGrades(state, payload) {
    state.demoGrades = payload
  },

  setDemoFormEducation(state, payload) {
    state.demoFormEducationTypes = payload
  },
}
