/**
 * @param {number} number
 * @returns {string}
 */
export const numberToCurrency = (number) =>
  new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
  }).format(number)
