import { chartPeriodConstants } from '@/helpers/const/chartPeriod'
import { getDate, getEndOfWeek } from '@/helpers/dateFormatHelper'
import { datasetOptions } from '@/helpers/lineChartDefaultOptions'
import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

const allSubject = {
  id: 0,
  // TODO: i18n
  name: 'Все предметы',
  color: 'ff0000',
}

const getAllSubjectsMarks = (marksTotal) => ({
  subject: allSubject,
  periods: marksTotal.periods || [],
})

const generateDataset = (averageMark) => ({
  label: averageMark.subject.name,
  borderColor: `#${averageMark.subject.color}`,
  data: averageMark.periods.map((period) => period.value),
  ...datasetOptions,
})

/**
 * @param {Object} args
 * @param {Array} args.yearPeriods
 * @returns {Array}
 */
const getYearPeriodBorders = ({ yearPeriods }) => {
  const yearPeriodsRenderData = []

  yearPeriods.forEach((yearPeriod) => {
    yearPeriod.periods.slice(0, -1).forEach((period, index) => {
      yearPeriodsRenderData.push({
        name: `${yearPeriod.type.periodName} ${index + 1}`,
        date: period.endDate,
      })
    })
  })

  return yearPeriodsRenderData
}

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setAverageMarksMonthly(state, { marks, marksTotal }) {
    state.averageMarksMonthly = [
      getAllSubjectsMarks(marksTotal),
      ...marks,
    ]
  },

  setAverageMarksWeekly(state, { marks, marksTotal }) {
    state.averageMarksWeekly = [
      getAllSubjectsMarks(marksTotal),
      ...marks,
    ]
  },

  setSubjects(state) {
    state.subjects = state.averageMarksMonthly.map(
      (averageMark) => averageMark.subject,
    )
  },

  composeChartData(state) {
    let labels = []
    const datasets = []
    let periodBorders = []

    const enabledYearTypes = state.statements.map(
      (statement) => statement.academicYearType.id,
    )

    const yearPeriods = state.academicYears
      .find((academicYear) => academicYear.id === state.academicYear.id)
      .periods.filter((period) =>
        enabledYearTypes.some(
          (enabledYearType) => period.type.id === enabledYearType,
        ),
      )

    if (state.chartPeriod.id === chartPeriodConstants.MONTH) {
      const chartPeriods = state.averageMarksMonthly[0]?.periods.map(
        (period) => period.period,
      )

      labels = chartPeriods.map((period) => getDate(period, 'LLL'))

      state.averageMarksMonthly.forEach((averageMark) => {
        const dataset = generateDataset(averageMark)

        datasets.push(dataset)
      })

      periodBorders = getYearPeriodBorders({
        yearPeriods,
      })
    }

    if (state.chartPeriod.id === chartPeriodConstants.WEEK) {
      const chartPeriods = state.averageMarksWeekly[0]?.periods.map(
        (period) => period.period,
      )

      labels = chartPeriods.map((i) => getDate(getEndOfWeek(new Date(i))))

      state.averageMarksWeekly.forEach((averageMark) => {
        const dataset = generateDataset(averageMark)

        datasets.push(dataset)
      })

      periodBorders = getYearPeriodBorders({
        yearPeriods,
      })
    }

    state.chartData = {
      labels,
      datasets,
      periodBorders,
    }
  },

  setChartPeriod(state, payload) {
    if (payload) {
      state.chartPeriod = payload
    } else {
      state.chartPeriod = state.chartPeriods[0]
    }
  },

  setAcademicYear(state, payload) {
    state.academicYear = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setStatements(state, payload) {
    state.statements = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setAcademicYears(state, payload) {
    state.academicYears = payload
  },

  setSubjectsSelected(state, payload) {
    state.subjectsSelected = payload
  },
}
