import ApiBase from 'ApiRest/ApiBase'

export const ApiSubscriptionList = new (class extends ApiBase {
  /**
   *
   * @returns {Promise}
   */
  get() {
    return this._GET('/account/client/user-subscriptions')
  }
})()
