import Constants from 'Constants'

const allowedStatuses = [
  Constants.supportIdeaStatus.IMPLEMENTED,
  Constants.supportIdeaStatus.POPULAR,
  Constants.supportIdeaStatus.REJECTED,
]

export default {
  ideaId(state) {
    return state.theIdea.id
  },

  authorId(state) {
    return state.theIdea.author.id
  },

  ideaStatusesFiltered(state) {
    return state.options.statuses.filter((status) =>
      allowedStatuses.includes(status.id),
    )
  },

  isIdeaStatusPopular: () => (idea) =>
    idea.status.id === Constants.supportIdeaStatus.POPULAR,
}
