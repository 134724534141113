import ApiBase from 'ApiRest/ApiBase'

export const ApiClientDemoFeSimpleProfile = new (class extends ApiBase {
  /**
   * @param {number} studentId
   * @returns {Promise}
   */
  get(studentId) {
    const url = `/account/client/students/${studentId}/simple-demo-fe-profile`

    return this._GET(url)
  }

  /**
   * @param {number} studentId
   * @param {Object} data
   * @returns {Promise}
   */
  patch(studentId, data) {
    const url = `/account/client/students/${studentId}/simple-demo-fe-profile`

    return this._PATCH(url, data)
  }
})()
