import { eventBus } from '@/pubsub/bus'
import ApiDownload from 'Api/Download'
import { ApiTimestamp } from 'Api/system/Timestamp'
import ApiClientTheInvoice from 'ApiRest/Api/Account/Client/Invoice'
import ApiCancelDiscount from 'ApiRest/Api/Account/Client/Invoice/CancelDiscount'
import { ApiCustomerReceipt } from 'ApiRest/Api/Account/Client/Invoice/CustomerReceipt'
import { ApiClientInvoices } from 'ApiRest/Api/Account/Client/Invoice/List'
import { ApiOfferDocList } from 'ApiRest/Api/Account/Client/Invoice/OfferDocList'
import ApiPaymentUrl from 'ApiRest/Api/Account/Client/Invoice/PaymentUrl'
import { ApiAccountInvoiceReceive } from 'ApiRest/Api/Account/Client/Invoice/Receive'
import { ApiShowcasePurchaseDeleteInvoiceUnavailableItems } from 'ApiRest/Api/Showcase/Invoice/DeleteUnavailableItems'
import { ApiShowcasePurchasedProducts } from 'ApiRest/Api/Showcase/Invoice/PurchasedProduct'
import { ApiShowcasePurchase } from 'ApiRest/Api/Showcase/Purchase'
import { ApiShowcasePurchaseItemDocument } from 'ApiRest/Api/Showcase/Purchase/Item/document/PurchaseItemDocument'
import { ApiShowcasePurchaseMarkWithMaternityCapital } from 'ApiRest/Api/Showcase/Purchase/MarkWithMaternityCapital'
import Constants from 'Constants'
import { millisecondsInSecond } from 'date-fns/constants'

export default {
  async fetchInvoices({ commit }) {
    const params = {
      showcase: 1,
    }

    const { data } = await ApiClientInvoices.get(params)

    commit('setInvoices', data)
  },

  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setServerTimestamp', data * millisecondsInSecond)
  },

  async fetchTheInvoice({ commit }, invoiceId) {
    const { data } = await ApiClientTheInvoice.get(invoiceId)

    commit('setTheInvoice', data)
  },

  async fetchPurchasedProduct({ commit }, invoiceId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await ApiShowcasePurchasedProducts.get(invoiceId)

      eventBus.$emit('frontend.showcase.product.productPurchased', {
        data,
      })

      commit('setPurchasedProduct', data)
    } catch (error) {
      throw error
    }
  },

  async fetchPaymentUrl({ commit }, { invoiceId, parameters }) {
    const { data } = await ApiPaymentUrl.get(invoiceId, parameters)

    commit('setTheInvoicePaymentUrl', data)
  },

  /**
   * Ссылка на url счета
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoicePaymentUrl({ dispatch }, { invoiceId, isRecurrent }) {
    await dispatch('fetchPaymentUrl', {
      invoiceId,
      parameters: {
        driver: Constants.invoicePaymentDriver.TINKOFF,
        recurrent: isRecurrent,
      },
    })
  },

  /**
   * Ссылка на url счета Долями
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoiceDolyameUrl({ dispatch }, invoiceId) {
    await dispatch('fetchPaymentUrl', {
      invoiceId,
      parameters: {
        driver: Constants.invoicePaymentDriver.DOLYAME,
      },
    })
  },

  /**
   * Применить промокод
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.invoiceId
   * @param {Object} payload.data
   * @returns {Promise<void>}
   */
  async applyPromocode(context, { invoiceId, data }) {
    await ApiClientTheInvoice.put(invoiceId, data)
  },

  /**
   * Отменить промокод
   * @param {{}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async discardPromocode(context, invoiceId) {
    await ApiCancelDiscount.put(invoiceId)
  },

  receiveFreeProduct(content, invoiceId) {
    return ApiAccountInvoiceReceive.post(invoiceId)
  },

  fetchCustomerReceipt(context, invoiceId) {
    return ApiCustomerReceipt.get(invoiceId)
  },

  async markPurchaseWithMaternityCapital(context, purchaseId) {
    await ApiShowcasePurchaseMarkWithMaternityCapital.put(purchaseId)
  },

  async fetchOfferDocs({ commit }, invoiceId) {
    const { data } = await ApiOfferDocList.get(invoiceId)

    commit('setOfferDocs', data)
  },

  async fetchOfferDoc(
    { commit },
    { id: documentTemplateId, purchaseId, purchaseItemId },
  ) {
    const params = {
      purchaseId,
      purchaseItemId,
      documentTemplateId,
    }

    const { data } = await ApiShowcasePurchaseItemDocument.getDocx(params)

    commit('setOfferDocBlob', {
      id: documentTemplateId,
      payload: data,
    })
  },

  async fetchRecurrentPaymentsDoc({ commit }, documentId) {
    const { data } = await ApiDownload.getFileBlob(documentId)

    commit('setRecurrentPaymentsDocBlob', {
      id: documentId,
      payload: data,
    })
  },

  async fetchPurchase(context, purchaseId) {
    const { data } = await ApiShowcasePurchase.get(purchaseId)

    return data
  },

  async deleteUnavailableItems({ commit, state }) {
    const { data } = await ApiShowcasePurchaseDeleteInvoiceUnavailableItems.put(
      state.invoice.id,
    )

    commit('setTheInvoice', data)
  },
}
