import ApiBase from 'ApiRest/ApiBase'

export default new (class Requests extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/account/client/requests/${id}`)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/account/client/requests`, data)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/account/client/requests/${id}`, data)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  put(id, data) {
    return this._PUT(`/account/client/requests/${id}/pending`, data)
  }
})()
