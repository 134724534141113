import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountProductAdBanners = new (class extends ApiBase {
  /**
   * @param {number} studentId
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(`/account/product-ad-banners/${studentId}`)
  }
})()
