<template>
  <ElModal :name="name">
    <div class="vm--modal-content">
      <div>
        <!-- TODO: i18n -->
        <h3 class="h3 text-dark">
          Для перехода в личный кабинет клиента введите пароль пользователя
          <a :href="hrefClientEmail">{{ profile.clientEmail }}</a>
        </h3>

        <form @submit.prevent="onSubmitForm">
          <!-- For autocomplete -->
          <ElInput
            :model-value="profile.clientEmail"
            autocomplete="username"
            wrapper-class="d-none"
          />

          <ElInputPassword
            v-model="formClientPassword"
            :invalid="v$.formClientPassword.$error"
            :errors="v$.formClientPassword.$errors"
            required
            autocomplete="current-password"
            autofocus
          />

          <div class="d-flex justify-content-between">
            <!-- TODO: i18n -->
            <ElButton
              :is-loading="isLoading.btnGo"
              type="submit"
              label="Перейти"
              class="btn-primary"
            />

            <!-- TODO: i18n -->
            <ElButton
              label="Отменить"
              class="btn-danger"
              @click="onClickBtnCancel"
            />
          </div>
        </form>
      </div>
    </div>
  </ElModal>
</template>

<script>
import { checkForErrorsMessage } from '@/helpers/ValidationHelper'
import { authUrl } from '@/helpers/const/authUrl'
import { required } from '@/helpers/i18n-validators'
import { showErrors, showToast } from '@/helpers/toast'
import ElButton from '@/ui/ElButton.vue'
import ElInput from '@/ui/ElInput.vue'
import ElInputPassword from '@/ui/ElInputPassword.vue'
import ElModal from '@/ui/ElModal.vue'
import { useVuelidate } from '@vuelidate/core'
import { defineComponent } from 'vue'
import { useVfm } from 'vue-final-modal'
import { mapActions, mapMutations, mapState } from 'vuex'

export default defineComponent({
  name: 'ModalClientPassword',

  components: {
    ElInput,
    ElModal,
    ElButton,
    ElInputPassword,
  },

  setup() {
    const vfm = useVfm()

    return {
      v$: useVuelidate({
        $stopPropagation: true,
        $autoDirty: true,
      }),

      vfm,
    }
  },

  validations() {
    return {
      formClientPassword: {
        required,
      },
    }
  },

  data() {
    return {
      name: 'modalClientPassword',

      isLoading: {
        btnGo: false,
      },
    }
  },

  computed: {
    ...mapState('student/profile', [
      'profile',
      'clientPassword',
      'clientToken',
    ]),

    hrefClientEmail() {
      return `mailto: ${this.profile.clientEmail}`
    },

    hrefClient() {
      const url = new URL(authUrl.userSwitch)

      if (this.clientToken) {
        url.searchParams.append('token', this.clientToken)
      }

      return url.href
    },

    formClientPassword: {
      get() {
        return this.clientPassword
      },

      set(value) {
        this.setClientPassword(value)
      },
    },
  },

  methods: {
    ...mapMutations('student/profile', [
      'setClientPassword',
    ]),

    ...mapActions('student/profile', [
      'fetchClientToken',
    ]),

    async switchToClient() {
      try {
        await this.fetchClientToken()

        window.location.href = this.hrefClient
      } catch (error) {
        showErrors(error)
      }
    },

    async onSubmitForm() {
      if (!(await this.v$.$validate())) {
        showToast(checkForErrorsMessage, 'error')

        return false
      }

      this.isLoading.btnGo = true
      await this.switchToClient()
      this.isLoading.btnGo = false

      return true
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.vfm.open(this.name)
    },

    hide() {
      this.vfm.close(this.name)
    },
  },
})
</script>
