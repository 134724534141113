import { ApiTimestamp } from 'Api/system/Timestamp'
import { millisecondsInSecond } from 'date-fns/constants'

export default {
  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setServerTimestamp', data * millisecondsInSecond)
  },
}
