import { lessonsGetters } from '@/helpers/store/getters'

export default {
  ...lessonsGetters,

  getSubjectsNames(state) {
    const subjectNames = new Set()

    state.lessons.forEach((lesson) => {
      if (lesson.subject?.name) {
        subjectNames.add(lesson.subject.name)
      }
    })

    return [
      ...subjectNames,
    ]
  },
}
