export const studentMainRoutes = [
  {
    path: 'profile',
    name: 'student-profile',
    component: () => import('@/pages/showcase/student/Profile.vue'),
  },

  {
    path: 'notifications',
    name: 'student-notifications',
    component: () => import('@/pages/showcase/common/Notifications.vue'),
  },

  {
    path: 'online-lessons',
    name: 'student-showcase-online-lessons',
    component: () => import('@/pages/showcase/common/LessonsOnline.vue'),
  },

  {
    path: 'online-lessons/:lessonId(\\d+)',
    name: 'student-the-online-lesson',
    component: () => import('@/pages/showcase/common/LessonsOnline.vue'),
  },
]
