import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/student/journal/state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setLessons(state, lessons) {
    state.lessons = lessons
  },

  setLessonsPreview(state, lessons) {
    state.lessonsPreview = lessons
  },

  setTheLesson(state, theLesson) {
    state.currentLesson = theLesson
  },

  setTheLessonIsPassed(state, { lessonId, isPassed }) {
    const lesson = state.lessons.find((i) => i.id === lessonId)

    if (lesson) {
      lesson.isPassed = isPassed
    }
  },

  setTheContent(state, content) {
    state.currentContent = content
  },

  setTheContentIsPassed(state, { contentId, isPassed }) {
    const { topics } = state.currentLesson

    topics.forEach((topic) => {
      const content = topic.contents.find((i) => i.id === contentId)

      if (content) {
        content.isPassed = isPassed
      }
    })
  },

  setTheMiniTestIsPassed(state, { miniTestId, isPassed }) {
    state.currentLesson.topics.forEach((topic) => {
      const miniTest = topic.miniTests.find((i) => i.id === miniTestId)

      if (miniTest) {
        miniTest.isPassed = isPassed
      }
    })
  },

  updateLessonHomework(state, { checked, taskId }) {
    let homework = null

    if (state.currentLesson.homeworkSolution?.id === taskId) {
      homework = state.currentLesson.homeworkSolution
    }

    if (state.currentLesson.personalHomeworkSolution?.id === taskId) {
      homework = state.currentLesson.personalHomeworkSolution
    }

    if (homework) {
      homework.isChecked = checked
    }
  },
}
