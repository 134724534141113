import ApiBase from 'ApiRest/ApiBase'

export const ApiRedirectUrl = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  post() {
    return this._POST(`/educont/oauth/get-redirect-url`)
  }
})()
