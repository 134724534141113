import { ApiSubscriptionCancel } from 'ApiRest/Api/Account/Client/Subscription/CancelSubscription'
import { ApiSubscriptionList } from 'ApiRest/Api/Account/Client/Subscription/List'
import { ApiSubscriptionRemoveCard } from 'ApiRest/Api/Account/Client/Subscription/RemoveCard'

export default {
  async fetchSubscriptions({ commit }) {
    const { data } = await ApiSubscriptionList.get()

    commit('setSubscriptions', data)
  },

  async cancelSubscription({ commit }, subscriptionId) {
    const { data } = await ApiSubscriptionCancel.put(subscriptionId)

    commit('updateSubscription', data)
  },

  async removeCard({ commit }, subscriptionId) {
    const { data } = await ApiSubscriptionRemoveCard.put(subscriptionId)

    commit('updateSubscription', data)
  },
}
