import auth from '@/store/showcase/auth/store'
import client from '@/store/showcase/client/store'
import common from '@/store/showcase/common/store'
import helper from '@/store/showcase/helper/store'
import locale from '@/store/showcase/locale/store'
import student from '@/store/showcase/student/store'
import { keys } from 'lodash'
import { createStore } from 'vuex'

export const StoreUserShowcase = new (class {
  constructor() {
    this.store = null
  }

  /**
   * @returns {Store}
   */
  init() {
    this.store = createStore({
      strict: process.iblsEnv.IS_DEVELOPMENT,

      modules: {
        auth,
        common,
        locale,
        client,
        student,
        helper,
      },

      state: {
        isGlobalLoading: false,
      },

      mutations: {
        setIsGlobalLoading(state, payload = false) {
          state.isGlobalLoading = payload
        },
      },

      actions: {
        resetGlobalState() {
          keys(this._mutations)
            .filter((mutation) => mutation.includes('resetState'))
            .forEach((mutation) => {
              this.commit(mutation, null, {
                root: true,
              })
            })
        },
      },
    })

    return this.store
  }
})()
