import ApiBase from 'ApiRest/ApiBase'

export const ApiQuestionPreview = new (class extends ApiBase {
  /**
   * @param {number} questionId
   * @returns {Promise}
   */
  get(questionId) {
    return this._GET(`/library/questions/${questionId}/preview`)
  }
})()
