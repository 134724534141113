import ApiBase from 'ApiRest/ApiBase'

export const ApiAcountClientStudentsExpelled = new (class extends ApiBase {
  /**
   * Изменение продукта
   * @param {number} studentId
   * @returns {Promise}
   */
  put(studentId) {
    return this._PUT(`/account/client/students/${studentId}/expelled`)
  }
})()
