import ApiTheFullTimeCtpStatement from 'ApiRest/Api/Account/Student/FullTimeCtpStatement'
import ApiAcademicYears from 'ApiRest/Api/Account/Student/FullTimeCtpStatement/AcademicYear/List'
import ApiFullTimeCtpStatements from 'ApiRest/Api/Account/Student/FullTimeCtpStatement/List'

export default {
  async fetchFullTimeCtpStatements({ commit, state, rootGetters }) {
    const params = {
      academicYearPreset: {
        id: state.theAcademicYear?.id,
      },
    }

    if (
      rootGetters['common/module/hasModules'](
        'intramural-student-extended-statement',
      )
    ) {
      params.includeExtraData = 1
    }

    const { data } = await ApiFullTimeCtpStatements.get(params)

    commit('setFullTimeCtpStatements', data)
  },

  async fetchTheFullTimeCtpStatement({ commit }, groupId) {
    const parameters = {
      statementTests: true,
    }

    const { data } = await ApiTheFullTimeCtpStatement.get(groupId, parameters)

    commit('setTheFullTimeCtpStatement', data)
  },

  async fetchAcademicYears({ commit }) {
    const { data } = await ApiAcademicYears.get()

    commit('setAcademicYears', data)
  },
}
