import ApiBase from 'ApiRest/ApiBase'

export default new (class PasswordChange extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  patch(data) {
    return this._PATCH(`/account/student/password-change`, data)
  }
})()
