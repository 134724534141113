import ApiLessonTest from 'ApiRest/Api/Account/Student/LessonTest'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchLessonTests({ commit }) {
    const { data } = await ApiLessonTest.get()

    commit('setLessonTests', data)
  },
}
