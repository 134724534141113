import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * TODO: заменить на put, когда с бэка исправится
   */

  /**
   * Пометить уведомление как прочитанное.
   * @param {number} notificationId
   * @returns {Promise}
   */
  patch(notificationId) {
    return this._PATCH(`/notification/system/${notificationId}`)
  }
})()
