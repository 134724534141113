import ApiBase from 'ApiRest/ApiBase'

export default new (class List extends ApiBase {
  /**
   * Получить все Прохождения Теста у КТП-Теста
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/account/student/test/ctp-tests/${id}/testings`)
  }
})()
