import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseStudent = new (class extends ApiBase {
  /**
   * Обновление данных студента в заказе
   * @param {Object} obj
   * @param {number} obj.purchaseId
   * @param {number} obj.studentId
   * @param {Object} obj.payload
   * @returns {Promise}
   */
  patch({ purchaseId, studentId, payload }) {
    const url = `/purchase/${purchaseId}/students/${studentId}`

    return this._PATCH(url, payload)
  }
})()
