import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseItem = new (class extends ApiBase {
  /**
   * @param {number} purchaseId
   * @param {number} itemId
   * @returns {Promise}
   */
  get(purchaseId, itemId) {
    const url = `/purchase/${purchaseId}/item/${itemId}`

    return this._GET(url)
  }
})()
