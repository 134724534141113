export const isWindowWidthDesktop = () => {
  const breakpointCssVariable = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--breakpoint-lg')

  const breakpointWidth = parseInt(breakpointCssVariable, 10)
  const windowWidth = window.innerWidth

  return windowWidth > breakpointWidth
}
