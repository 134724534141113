import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setFullTimeCtpStatements(state, payload) {
    state.statements = payload
  },

  setTheFullTimeCtpStatement(state, payload) {
    state.theStatement = payload
  },

  setAcademicYears(state, payload) {
    state.academicYears = payload
  },

  setTheAcademicYear(state, payload) {
    state.theAcademicYear = payload
  },

  setItemsFilter(state, payload) {
    state.itemsFilter = payload
  },
}
