// TODO: i18n
export const sortOptions = [
  {
    value: '-popularity',
    label: 'По популярности',
  },
  {
    value: 'startDate',
    label: 'По дате начала',
  },
  {
    value: 'price',
    label: 'По возрастанию цены',
  },
  {
    value: '-price',
    label: 'По убыванию цены',
  },
  {
    value: '-creationDate',
    label: 'По дате публикации',
  },
]
