import ApiBase from 'ApiRest/ApiBase'

export default new (class TheCertificationIsAvailable extends ApiBase {
  /**
   * @param {number} studentId
   * @returns {Promise}
   */
  get(studentId) {
    return this._GET(
      `/account/client/students/${studentId}/fe-certification-is-available`,
    )
  }
})()
