import ApiCertificationList from 'Api/rest/Api/Account/Client/Students/FamilyCertification/List'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} studentId
   * @returns {Promise<*>}
   */
  async fetchCertifications({ commit }, studentId) {
    const { data } = await ApiCertificationList.get(studentId)

    commit('setCertifications', data)
  },
}
