import ApiBase from 'ApiRest/ApiBase'

export default new (class Draft extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/account/client/requests/drafts/${id}`)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/account/client/requests/drafts`, data)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/account/client/requests/drafts/${id}`, data)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    return this._DELETE(`/account/client/requests/drafts/${id}`)
  }
})()
