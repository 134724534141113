import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiUserAction = new (class extends ApiBase {
  /**
   * @param {number} userId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(userId, parameters = {}) {
    const urlParams = UrlHelper.param(parameters)
    const url = `/user-actions/${userId}?${urlParams}`

    return this._GET(url)
  }

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload) {
    return this._POST(`/user-action`, payload)
  }
})()
