import { RequestCounter } from '@/helpers/RequestCounter'
import isRequestSilent from '@/helpers/isRequestSilent'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import axios from 'axios'

import { i18n } from '@/i18n/setup'
import { entries } from 'lodash'

export default class AxiosHelper {
  /**
   * @param {boolean} withCredentials
   * @param {Function} createXHeaders - кастомные заголовки
   * @param {Function} onResponseInterceptorFulfilled
   * @param {Function} onResponseInterceptorRejected
   */
  constructor(
    withCredentials = true,
    createXHeaders = () => ({}),
    onResponseInterceptorFulfilled = (res) => res,
    onResponseInterceptorRejected = (err) => {
      throw err
    },
  ) {
    this.axios = axios.create({
      withCredentials,
    })

    // Middleware для request
    this.axios.interceptors.request.use(
      (config) => {
        if (!isRequestSilent(config.url)) {
          RequestCounter.increase()
        }

        entries(createXHeaders()).forEach(
          ([
            key,
            value,
          ]) => {
            config.headers[key] = value
          },
        )

        return config
      },
      (err) => Promise.reject(err),
    )

    // Middleware для response
    this.axios.interceptors.response.use(
      (res) => {
        if (!isRequestSilent(res.config.url)) {
          RequestCounter.decrease()
        }

        return onResponseInterceptorFulfilled(res)
      },
      (err) => {
        if (!isRequestSilent(err.config.url)) {
          RequestCounter.decrease()
        }

        return onResponseInterceptorRejected(err)
      },
    )
  }

  /**
   * @param {Object} error
   * @param {Function} showErrorHandler
   * @param {Function} onErrorHandler
   * @throws {Error}
   */
  static processError(
    error,
    showErrorHandler = function () {},
    onErrorHandler = function () {},
  ) {
    const showError = (message) => showErrorHandler(message)

    if (!error.response) {
      showError(
        i18n.global.t('helpers.axios_helper.toasted_message.operation_failed'),
      )
      throw error
    }

    const {
      response: { data, status },
    } = error

    if (
      status === HttpStatusCodes.UnprocessableEntity ||
      status === HttpStatusCodes.Conflict ||
      status === HttpStatusCodes.BadRequest ||
      status === HttpStatusCodes.IblsDebug
    ) {
      if (!data) {
        showError(
          i18n.global.t(
            'helpers.axios_helper.toasted_message.data_validation_error',
          ),
        )

        throw error
      }

      if (status === HttpStatusCodes.UnprocessableEntity) {
        onErrorHandler(data)
      }

      showError(
        data.message
          ? data.message
          : i18n.global.t('helpers.axios_helper.toasted_message.check_data'),
      )

      throw error
    }

    showError(
      i18n.global.t('helpers.axios_helper.toasted_message.unknown_error'),
    )

    throw error
  }

  /**
   * @param {string} url
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  GET(url, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(url, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  POST(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(url, data, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  PUT(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .put(url, data, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} data
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  PATCH(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .patch(url, data, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  DELETE(url, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .delete(url, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * @param {string} url
   * @param {Object} config - Axios config
   * @returns {Promise}
   * @public
   */
  HEAD(url, config = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .head(url, config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
