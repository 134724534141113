import ApiJournal from 'ApiRest/Api/Account/Student/Journal/List'

export default {
  async fetchJournal({ commit }, datesRange) {
    commit('setDatesRange', datesRange)

    const { data } = await ApiJournal.get(datesRange)

    commit('setJournal', data)
  },
}
