<template>
  <div :class="[$style.btn, { [$style.visible]: visible }]" @click="upHandler">
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.39607 7.70978L8 2L13.6569 7.65685"
        stroke="#332F3E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
import { scrollToTop } from '@/helpers/smoothScroll'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BtnUp',

  emits: [
    'visibility-changed',
  ],

  data() {
    return {
      visible: false,
      screenHeight: 0,
    }
  },

  watch: {
    visible(newValue) {
      this.$emit('visibility-changed', newValue)
    },
  },

  mounted() {
    this.screenHeight = document.documentElement.clientHeight
    this.onScrollHandler()
    window.addEventListener('scroll', this.onScrollHandler)
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScrollHandler)
  },

  methods: {
    upHandler() {
      scrollToTop()
    },

    onScrollHandler() {
      this.visible = window.scrollY >= this.screenHeight
    },
  },
})
</script>

<style lang="scss" module>
.btn {
  position: fixed;
  right: 32px;
  bottom: -72px;
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 24px rgba(51, 47, 62, 0.08);
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}

.visible {
  bottom: 20px !important;
}
</style>
