import ApiBase from 'ApiRest/ApiBase'

export const ApiSendConfirmEmail = new (class extends ApiBase {
  /**
   * @param {Object} [payload]
   * @returns {Promise}
   */
  post(payload) {
    return this._POST('/user/send-confirm-email', payload)
  }
})()
