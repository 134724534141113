export default () => ({
  /** @type {TablePurchase[]} */
  purchases: [],

  purchaseId: null,
  purchase: null,

  customer: null,

  students: [],

  invoices: [],

  options: {
    citizenshipList: [],
    countryList: [],
    kinshipList: [],
  },
})
