import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setStatements(state, payload) {
    state.statements = payload
  },

  setAcademicYears(state, payload) {
    state.academicYears = payload
  },

  setAcademicYear(state, payload) {
    state.academicYear = payload
  },

  setStatementExtramuralPromoCtps(state, payload) {
    const promoCtps = payload.map((item) => ({
      ...item,
      isActual: true,
      isPromo: true,
    }))

    state.statements.extramural[0].items.push(...promoCtps)
  },

  setStatementIntramuralItemsFilter(state, payload) {
    state.statementIntramural.itemsFilter = payload
  },
}
