import locale from '@/store/common/locale/store'
import messages from '@/store/common/messages/store'
import module from '@/store/common/module/store'
import notifications from '@/store/common/notifications/store'
import productAd from '@/store/common/productAd/store'
import promoCampaign from '@/store/common/promoCampaign/store'
import support from '@/store/common/support/store'
import user from '@/store/common/user/store'

export default {
  namespaced: true,

  modules: {
    locale,
    module,
    notifications,
    messages,
    promoCampaign,
    support,
    user,
    productAd,
  },
}
