import mitt from 'mitt'

export const RequestCounter = new (class {
  constructor() {
    this.counter = 0
    this.bus = mitt()
  }

  /**
   * @public
   */
  increase() {
    this.counter += 1
    this.emit()
  }

  /**
   * @public
   */
  decrease() {
    if (this.counter >= 1) {
      this.counter -= 1
      this.emit()
    }
  }

  /**
   * @private
   */
  emit() {
    this.bus.emit('update', this.counter)
  }

  /**
   * @param {Function} handler
   * @returns {Function}
   * @public
   */
  onUpdate(handler = (total) => total) {
    return this.bus.on('update', handler)
  }
})()
