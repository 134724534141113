import ApiBase from 'ApiRest/ApiBase'

export const ApiInvoicePeriodTypesOptions = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/option/invoice-period-types`)
  }
})()
