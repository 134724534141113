import { ApiCoursesCategories } from 'ApiRest/Api/Courses/Categories'
import { ApiCoursesUpperCategories } from 'ApiRest/Api/Courses/UpperCategories'

export default {
  async fetchCategories({ commit }) {
    const { data } = await ApiCoursesCategories.get()

    commit('setCategories', data)
  },

  async fetchUpperCategories({ commit }) {
    const { data } = await ApiCoursesUpperCategories.get()

    commit('setUpperCategories', data)
  },
}
