import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseSupportAskQuestion = new (class extends ApiBase {
  /**
   * @param {number} testQuestionId
   * @param {Object} payload
   * @returns {Promise}
   */
  post(testQuestionId, payload) {
    return this._POST(
      `/showcase/ask-question/question/${testQuestionId}`,
      payload,
    )
  }
})()
