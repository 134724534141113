import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Получить уведомления.
   * @returns {Promise}
   */
  get() {
    return this._GET('/notification/system')
  }
})()
