import { WebAnalyticsHelper } from '@/helpers/WebAnalyticsHelper'

export const product = {
  /**
   * Клиент нажал кнопку "Добавить в корзину"
   * @param {Object} data
   * @param {Object} data.product
   * @param {Object} data.offer
   */
  addButtonClicked(data) {
    WebAnalyticsHelper.reachGoalYandexMetrikaEcommerceAction({
      action: 'add',
      author: data.product.author,
      id: data.product.id,
      name: data.product.name,
      price: data.offer.price,
      category: data.product.mainCategory.name,
    })

    WebAnalyticsHelper.goalAddProductInCart()
  },

  /**
   * Товар удален из корзины
   * @param {Object} data
   * @param {Object} data.product
   */
  productRemoved(data) {
    WebAnalyticsHelper.reachGoalYandexMetrikaEcommerceAction({
      action: 'remove',
      id: data.product.productId,
      name: data.product.offer.name,
      price: data.product.price,
    })

    WebAnalyticsHelper.goalRemoveProductFromCart()
  },

  /**
   * Просмотр детальной страницы продукта
   * @param {Object} data
   * @param {Object} data.product
   * @param {Array} data.offers
   */
  productDetailPageVisited(data) {
    WebAnalyticsHelper.reachGoalYandexMetrikaEcommerceAction({
      action: 'detail',
      id: data.product.id,
      author: data.product.author,
      name: data.product.name,
      category: data.product.mainCategory.name,
      price: data.offers[0].price,
    })

    WebAnalyticsHelper.goalViewDetailProduct()
  },

  /**
   * @param {{
   *   id: number,
   *   name: string,
   *   price: number,
   *   category: Object
   * }[]} data
   */
  productPurchased({ data }) {
    const products = data.map((item) => ({
      ...item,
      category: item.category.name,
    }))

    WebAnalyticsHelper.reachGoalYandexMetrikaEcommercePurchase(products)

    WebAnalyticsHelper.goalPurchasedProduct()
  },
}
