import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountProductAdOrder = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {number} payload.productAdId
   * @param {{id:number}[]} payload.orders
   * @returns {Promise}
   */
  post(payload) {
    const url = `/account/product-ad/order`

    return this._POST(url, payload)
  }
})()
