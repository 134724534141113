export default {
  totalPrice(state, getters) {
    return getters.checkedProducts.reduce(
      (totalPrice, product) => totalPrice + product.price,
      0,
    )
  },

  checkedProducts(state) {
    return state.products.filter((product) => product.isChecked)
  },

  hasProducts(state) {
    return state.products.length > 0
  },

  hasCheckedProducts(state, getters) {
    return getters.checkedProducts.length > 0
  },

  isCheckedEachProduct(state, getters) {
    return getters.checkedProducts.length === state.products.length
  },

  hasBothCheckedAndUncheckedProducts(state, getters) {
    return getters.hasCheckedProducts && !getters.isCheckedEachProduct
  },

  hasCheckedProductsWithMaternityCapital(state, getters) {
    return getters.checkedProducts.some(
      (product) => product.canBePaidWithMaternityCapital,
    )
  },

  hasProductsError(state) {
    return !state.products.some((product) => product.hasError)
  },

  isCheckedProductsFreeOnly(state, getters) {
    return getters.checkedProducts.every((product) => product.price === 0)
  },

  canCheckedProductsSkipCheckoutPage(state, getters) {
    return getters.checkedProducts.every(
      (product) => product.canSkipCheckoutPage,
    )
  },
}
