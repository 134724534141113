import createActions from './createActions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

/**
 * @param {'client' | 'student'} user
 * @returns {any}
 */
export default (user) => ({
  namespaced: true,
  state,
  getters,
  mutations,
  actions: createActions(user),
})
