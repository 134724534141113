export const clientMainRoutes = [
  {
    path: '',
    name: 'client-main',
    component: () => import('@/pages/client/PageMain.vue'),
  },
  {
    path: 'contracts',
    name: 'client-contracts',
    component: () => import('@/pages/client/Contracts.vue'),
  },
  {
    path: 'demo-access',
    name: 'client-demo-access',
    component: () => import('@/pages/client/DemoAccess.vue'),
  },
  {
    path: 'contracts/:id(\\d+)',
    name: 'client-contract',
    component: () => import('@/pages/client/TheContract.vue'),
  },
  {
    path: 'requests/new',
    name: 'client-request-new',
    component: () => import('@/pages/client/TheRequestDraft.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'requests-simplified/new',
    name: 'client-request-simplified',
    component: () => import('@/pages/client/TheRequestSimplified.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'requests/drafts/:id(\\d+)',
    name: 'client-request-draft',
    component: () => import('@/pages/client/TheRequestDraft.vue'),
  },
  {
    path: 'requests/:id(\\d+)',
    name: 'client-request',
    component: () => import('@/pages/client/TheRequest.vue'),
  },
  {
    path: 'profile',
    name: 'client-profile',
    component: () => import('@/pages/client/Profile.vue'),
  },
  {
    path: 'calendar',
    name: 'client-calendar',
    component: () => import('@/pages/client/Calendar.vue'),
  },
  {
    path: 'calendar/:day([\\d\\-]{10})',
    name: 'client-calendar-day',
    component: () => import('@/pages/client/Calendar.vue'),
  },
  {
    path: 'events',
    name: 'client-events',
    component: () => import('@/pages/client/Events.vue'),
  },
  {
    path: 'events/:eventId(\\d+)',
    name: 'client-event',
    component: () => import('@/pages/client/TheEvent.vue'),
  },
  {
    path: 'goals',
    name: 'client-goals',
    component: () => import('@/pages/client/Goals.vue'),
  },
  {
    path: 'lessons',
    name: 'client-lessons',
    component: () => import('@/pages/client/Lessons.vue'),
  },
  {
    path: 'news',
    name: 'client-news',
    component: () => import('@/pages/client/News.vue'),
  },
  {
    path: 'news/:newsArticleId(\\d+)',
    name: 'client-news-article',
    component: () => import('@/pages/client/TheNewsArticle.vue'),
  },
  {
    path: 'statements',
    name: 'client-statements',
    component: () => import('@/pages/client/Statements.vue'),
  },
  {
    path: 'statements/:groupId(\\d+)',
    name: 'client-statement',
    component: () => import('@/pages/client/TheStatement.vue'),
  },
  {
    path: 'certification',
    name: 'client-certification',
    component: () => import('@/pages/client/Certification.vue'),
  },
  {
    path: 'request/family-certification/new',
    name: 'client-the-family-certification-request-new',
    props: {
      isCreate: true,
    },
    component: () => import('@/pages/client/TheFamilyCertificationRequest.vue'),
  },
  {
    path: 'request/family-certification/edit',
    name: 'client-the-family-certification-request-edit',
    component: () => import('@/pages/client/TheFamilyCertificationRequest.vue'),
  },
  {
    path: 'request/family-certification/docs',
    name: 'client-the-family-certification-request-docs',
    component: () =>
      import('@/pages/client/TheFamilyCertificationRequestDocs.vue'),
  },
  {
    path: 'notifications',
    name: 'client-notifications',
    component: () => import('@/pages/common/Notifications.vue'),
  },
  {
    path: 'messages',
    name: 'client-messages',
    component: () => import('@/pages/common/Messages.vue'),
  },
  {
    path: 'payment/success',
    name: 'client-payment-success',
    component: () => import('@/pages/client/TheInvoice.vue'),
    props: {
      isModalOpenSuccess: true,
    },
  },
  {
    path: 'payment/error',
    name: 'client-payment-error',
    component: () => import('@/pages/client/TheInvoice.vue'),
    props: {
      isModalOpenError: true,
    },
  },
  {
    path: 'schedule-lessons',
    name: 'client-schedule-lessons',
    component: () => import('@/pages/client/ScheduleLessons.vue'),
  },
  {
    path: 'income-diagnostics',
    name: 'client-income-diagnostics',
    component: () => import('@/pages/client/IncomeDiagnostics.vue'),
  },
  {
    path: 'library',
    name: 'client-library',
    component: () => import('@/pages/client/Library.vue'),
  },
  {
    path: 'tariffs',
    name: 'client-tariffs',
    component: () => import('@/pages/client/Tariffs.vue'),
  },
]
