import ApiBase from 'ApiRest/ApiBase'

export default new (class CtpTest extends ApiBase {
  /**
   * Получить КТП-Тест по ID
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/account/student/test/ctp-tests/${id}`)
  }

  /**
   * @param {number} testId
   * @param {number} fileId
   * @returns {Promise}
   */
  getFileUrl(testId, fileId) {
    return testId && fileId
      ? `${this.BASE_URL}/account/student/test/ctp-tests/${testId}/comment-attachment/${fileId}`
      : null
  }
})()
