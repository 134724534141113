import actions from '@/store/courses/common/collection/actions'
import getters from '@/store/courses/common/collection/getters'
import mutations from '@/store/courses/common/collection/mutations'
import state from '@/store/courses/common/collection/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
