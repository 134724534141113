import ApiBase from 'ApiRest/ApiBase'

export default new (class Test extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/testing/check/tests/${id}`)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/testing/check/tests/${id}`, data)
  }
})()
