import { WebAnalyticsHelper } from '@/helpers/WebAnalyticsHelper'

export const family = {
  /**
   * Клиент нажал кнопку "Дальше"
   * в заявке на семейное обучение
   * на шаге "Данные для договора"
   */
  stepContractDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataContractFamily()
  },

  /**
   * Клиент нажал кнопку "Дальше"
   * в заявке на семейное обучение
   * на шаге "Данные клиента"
   */
  stepClientDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataClientFamily()
  },

  /**
   * Клиент нажал кнопку "Дальше"
   * в заявке на семейное обучение
   * на шаге "Данные ученика"
   */
  stepStudentDataButtonNextClicked() {
    WebAnalyticsHelper.goalDataStudentFamily()
  },

  /**
   * Клиент нажал кнопку "Принимаю"
   * в заявке на семейное обучение
   * на шаге "Оферта"
   */
  stepOfferButtonAgreeClicked() {
    WebAnalyticsHelper.goalDataCheckFamily()
  },

  /**
   * Клиент нажал кнопку "Перейти к оплате"
   * в заявке на семейное обучение
   * на шаге "Счет"
   */
  stepInvoiceButtonGoToPayment() {
    WebAnalyticsHelper.goalGoToPayment()
  },

  /**
   * Демо-ученик нажал кнопку "Перейти к оплате"
   * в заявке на семейное обучение
   * на шаге "Оплата"
   */
  stepPaymentButtonGoToPayment() {
    WebAnalyticsHelper.goalGoToPayment()
  },

  /**
   * Клиент нажал кнопку "Перейти к оплате"
   * в заявке на семейное обучение
   * на шаге "Заявка"
   */
  stepApplicationButtonGoToPayment() {
    WebAnalyticsHelper.goalGoToPayment()
  },
}
