import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

const createOfferDoc = (offerDoc) => ({
  ...offerDoc,
  blob: '',
  isChecked: false,
})

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOfferDocs(state, payload) {
    state.offerDocs = payload.map((offer) => createOfferDoc(offer))
  },

  setOfferDocChecked(state, { offerDoc, isChecked }) {
    const offerDocFound = state.offerDocs.find((doc) => doc.id === offerDoc.id)

    if (offerDocFound) {
      offerDocFound.isChecked = isChecked
    }
  },

  setOfferDocBlob(state, { id, payload }) {
    const doc = state.offerDocs.find((offerDoc) => offerDoc.id === id)

    doc.blob = payload
  },
}
