export default {
  /**
   * @param {Object} state
   * @returns {boolean}
   */
  isClient(state) {
    return state.role === 'client'
  },

  /**
   * @param {Object} state
   * @returns {boolean}
   */
  isStudent(state) {
    return state.role === 'student'
  },

  /**
   * @param {Object} state
   * @returns {boolean}
   */
  isManager(state) {
    return state.role === 'manager'
  },

  /**
   * @param {Object} state
   * @returns {boolean}
   */
  isAnonymous(state) {
    return state.role === 'anonymous'
  },

  /**
   * @param {Object} state
   * @returns {boolean}
   */
  isAuthorized(state) {
    return state.role !== ''
  },

  /**
   * @param {Object} state
   * @returns {boolean}
   */
  isDemoUser(state) {
    return (
      process.iblsEnv.IBLS_DEMO_USER_EMAILS?.split(',').includes(state.email) ||
      state.isDemo
    )
  },

  /**
   * @param {Object} state
   * @param {Object} getters
   * @returns {string}
   */
  getRouteNameOnUser(state, getters) {
    /**
     * @param {string} routeName
     * @returns {string}
     */
    return (routeName) => {
      const { isClient } = getters
      const { isStudent } = getters
      const { isAnonymous } = getters

      if (isClient) {
        return `client-${routeName}`
      }

      if (isStudent) {
        return `student-${routeName}`
      }

      if (isAnonymous) {
        return `guest-${routeName}`
      }

      return ''
    }
  },
}
