export const filterNavLinks = (navLinks, comparator) =>
  navLinks.reduce((accumulator, currentValue) => {
    let result = currentValue

    if (Array.isArray(result.children)) {
      const children = result.children.filter(comparator)

      if (children.length > 0) {
        result = {
          ...result,
          children,
        }
      } else {
        return [
          ...accumulator,
        ]
      }
    }

    result = [
      result,
    ].filter(comparator)

    return [
      ...accumulator,
      ...result,
    ]
  }, [])
