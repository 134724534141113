import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentProfileIndividualConsultation =
  new (class extends ApiBase {
    /**
     * @returns {Promise}
     */
    get() {
      return this._GET('/account/student/profile/individual-consultation')
    }
  })()
