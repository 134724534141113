<template>
  <div
    class="subject__progress"
    :style="{ backgroundColor: `#${color}${opacity}` }"
  >
    <div
      class="progress-bar"
      :style="{
        backgroundColor: `#${color}`,
        width: `${progress}%`,
      }"
    ></div>

    <div class="checkpoint" :style="{ width: `${checkpoint}%` }"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',

  props: {
    color: {
      type: String,
      default: '777777',
    },

    progress: {
      type: Number,
      default: 0,
    },

    checkpoint: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    opacity: 29,
  }),
}
</script>
