import { RESET_STATE } from '@/helpers/ResetState'
import { fromUnixTime } from 'date-fns'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setItems(state, payload) {
    state.items = payload
  },

  /**
   * @param {Object} state
   * @param {number} payload
   */
  setDateServer(state, payload) {
    state.dateServer = fromUnixTime(payload)
  },
}
