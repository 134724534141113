import { formatToIso } from '@/helpers/dateFormatHelper'
import { addWeeks, format, isSameDay, isSameWeek, parseISO } from 'date-fns'
import { cloneDeep } from 'lodash'

const today = new Date()

/**
 * Общие геттеры целей
 * @param {Object[]} goals
 * @returns {Object[]}
 */
const sortedGoalsPreview = (goals) => {
  const now = Date.now()

  return cloneDeep(goals)
    .map((goal) => {
      goal.comparingDate = Math.abs(
        now - parseISO(formatToIso(goal.startDateTime)).getTime(),
      )

      return goal
    })
    .sort((a, b) => {
      if (a.comparingDate > b.comparingDate) {
        return 1
      }

      if (a.comparingDate < b.comparingDate) {
        return -1
      }

      return 0
    })
}

export const goalsGetters = {
  getGoalsNotCompleted(state) {
    return state.goals.filter((goal) => !goal.isReached)
  },

  getGoalsCompleted(state) {
    return state.goals.filter((goal) => goal.isReached)
  },

  getGoalsCompletedPreview(state, getters) {
    const sortedGoals = sortedGoalsPreview(getters.getGoalsCompleted)

    return sortedGoals.slice(0, 3).filter(Boolean)
  },

  getGoalsNotCompletedPreview(state, getters) {
    const sortedGoals = sortedGoalsPreview(getters.getGoalsNotCompleted)

    return sortedGoals.slice(0, 3).filter(Boolean)
  },
}

/**
 * @description общие геттеры уроков
 */
export const lessonsGetters = {
  getLessonsSortedByDay() {
    return (lessons) => {
      const result = {}

      lessons.forEach((lesson) => {
        if (lesson.date) {
          const formattedDate = format(
            parseISO(formatToIso(lesson.date)),
            'yyyy-MM-dd',
          )

          if (result[formattedDate]) {
            result[formattedDate].lessons.push(lesson)
          } else {
            result[formattedDate] = {
              date: lesson.date,
              lessons: [
                lesson,
              ],
            }
          }
        }
      })

      return result
    }
  },

  getLessonsToday(state, getters) {
    return getters.getLessonsSortedByDay(
      state.lessons.filter((lesson) =>
        lesson.date
          ? isSameDay(parseISO(formatToIso(lesson.date)), today)
          : false,
      ),
    )
  },

  getLessonsThisWeek(state, getters) {
    return getters.getLessonsSortedByDay(
      state.lessons.filter((lesson) =>
        lesson.date
          ? !isSameDay(parseISO(formatToIso(lesson.date)), today, {
              weekStartsOn: 1,
            }) &&
            isSameWeek(parseISO(formatToIso(lesson.date)), today, {
              weekStartsOn: 1,
            })
          : false,
      ),
    )
  },

  getLessonsNextWeek(state, getters) {
    return getters.getLessonsSortedByDay(
      state.lessons.filter((lesson) =>
        lesson.date
          ? isSameWeek(parseISO(formatToIso(lesson.date)), addWeeks(today, 1), {
              weekStartsOn: 1,
            })
          : false,
      ),
    )
  },

  getLessonsNotPassed(state, getters) {
    return getters.getLessonsSortedByDay(state.lessonsUnpassedOnly)
  },

  getLessonsAll(state, getters) {
    return getters.getLessonsSortedByDay(state.lessons)
  },

  getLessonsTests(state, getters) {
    return getters.getLessonsSortedByDay(state.lessonsTestOnly)
  },

  getLessonsByType(state, getters) {
    return (type) => {
      let result = {}

      if (type === 'unpassedOnly') {
        result = cloneDeep(getters.getLessonsNotPassed)
      } else if (type === 'testOnly') {
        result = cloneDeep(getters.getLessonsTests)
      } else {
        result = cloneDeep(getters.getLessonsAll)
      }

      return result
    }
  },
}

export default {
  goalsGetters,
  lessonsGetters,
}
