import { RESET_STATE } from '@/helpers/ResetState'
import { set } from 'lodash'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setNotificationValue(state, { path, value }) {
    set(state.notifications, path, value)
  },
}
