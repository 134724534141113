import { ApiDemoFeSimpleRegistration } from 'ApiRest/Api/Auth/DemoFeSimple/Registration'
import { ApiDemoFeSimpleRequestCall } from 'ApiRest/Api/Auth/DemoFeSimple/RequestCall'
import { ApiDemoFeSimpleSelectGrade } from 'ApiRest/Api/Auth/DemoFeSimple/SelectGrade'
import { pick } from 'lodash'

export default {
  async register({ state }) {
    await ApiDemoFeSimpleRegistration.post(
      pick(state.form, [
        'firstName',
        'email',
        'phone',
      ]),
    )
  },

  async sendGrade({ state, commit }) {
    const { data } = await ApiDemoFeSimpleSelectGrade.put(
      pick(state.form, [
        'grade',
      ]),
    )

    commit(
      'student/profile/setProfileValue',
      {
        key: 'grade',
        value: data.grade,
      },
      {
        root: true,
      },
    )
  },

  async requestCall() {
    await ApiDemoFeSimpleRequestCall.put({
      source: 'grade-select',
    })
  },
}
