import ApiBase from 'ApiRest/ApiBase'

export default new (class OnCheck extends ApiBase {
  /**
   * Перевести Прохождение Теста на проверку (завершить тест)
   * @param {number} id
   * @returns {Promise}
   */
  put(id) {
    return this._PUT(`/account/student/test/testings/${id}/on-check`)
  }
})()
