import { RouterUser } from '@/RouterUser'
import { types as calendarTypes } from '@/helpers/Calendar'
import { dateToDay, getWeekLater } from '@/helpers/dateFormatHelper'
import { showToast } from '@/helpers/toast'
import { i18n } from '@/i18n/setup'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiLesson from 'ApiRest/Api/Account/Student/Lesson'
import ApiAskQuestion from 'ApiRest/Api/Account/Student/Lesson/AskQuestion'
import ApiContentNotPass from 'ApiRest/Api/Account/Student/Lesson/ContentNotPass'
import ApiContentPass from 'ApiRest/Api/Account/Student/Lesson/ContentPass'
import ApiFamilyListPreview from 'ApiRest/Api/Account/Student/Lesson/FamilyListPreview'
import ApiLessonPassNoMaterials from 'ApiRest/Api/Account/Student/Lesson/LessonPassNoMaterials'
import ApiLessonList from 'ApiRest/Api/Account/Student/Lesson/List'
import ApiCalendarItems from 'ApiRest/Api/CalendarItems'
import { ApiEducontLessonFeedback } from 'ApiRest/Api/Educont/Lesson/Feedback'

export default {
  async fetchLessons({ commit }, { page, mode = 'all', subject = null }) {
    const filters = {
      page,
    }

    if (subject !== null) {
      filters.subject = subject
    }

    if (mode === 'testOnly') {
      filters.testOnly = true
    } else if (mode === 'unpassedOnly') {
      filters.unpassedOnly = true
    }

    const { data, headers } = await ApiLessonList.get(filters)

    commit('setLessonsPagination', {
      mode,
      headers,
    })

    commit('setLessons', {
      mode,
      data,
    })
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<*>}
   */
  async fetchLessonsPreview({ commit }) {
    const { data } = await ApiFamilyListPreview.get()

    commit('setLessonsPreview', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} lessonId
   * @returns {Promise<*>}
   */
  async fetchTheLesson({ commit }, lessonId) {
    try {
      const { data } = await ApiLesson.get(lessonId)

      commit('setTheLesson', data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        showToast(
          i18n.global.t('store.student.toasted_message.lesson_was_not_found'),
          'error',
        )

        RouterUser.router.push({
          name: 'not-found',
        })
      }

      throw error
    }
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {number} payload.lessonId
   * @param {number} payload.contentId
   * @param {boolean} payload.isPassed
   * @returns {Promise<void>}
   */
  async updateTheContentIsPassed(
    { commit },
    { lessonId, contentId, isPassed },
  ) {
    const apiMethod = isPassed ? ApiContentPass : ApiContentNotPass

    await apiMethod.put(lessonId, contentId)
    commit('setTheContentIsPassed', {
      contentId,
      isPassed,
    })
  },

  async updateTheLessonNoMaterials(context, lessonId) {
    await ApiLessonPassNoMaterials.put(lessonId)
  },

  /**
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.contentId
   * @param {string} payload.question
   * @param {number} payload.lessonId
   * @returns {Promise<void>}
   */
  async askQuestion(context, { contentId, question, lessonId }) {
    const data = {
      content: {
        id: contentId,
      },
      lesson: {
        id: lessonId,
      },
      questionBody: question,
    }

    await ApiAskQuestion.post(contentId, data)
  },

  async sendEducontFeedback(context, { ctpId, contentId, message }) {
    await ApiEducontLessonFeedback.post(ctpId, contentId, message)
  },

  async fetchClosestLessons({ commit }, payload) {
    const dates = {
      start: payload?.start ?? dateToDay(),
      end: payload?.end ?? getWeekLater(dateToDay()),
    }

    commit('setClosestLessonsPeriod', dates)

    let [
      { data: onlineLessons },
      { data: lessons },
    ] = await Promise.all([
      ApiCalendarItems.get({
        start: dates.start,
        end: dates.end,
        typeIds: [
          calendarTypes.ONLINE_LESSON,
        ],
      }),
      ApiLessonList.get({
        date: {
          startDate: dates.start,
          endDate: dates.end,
        },
      }),
    ])

    onlineLessons = onlineLessons.map((item) => ({
      ...item,
      $type: 'onlineLesson',
    }))

    lessons = lessons.map((lesson) => ({
      ...lesson,
      $type: 'lesson',
    }))

    commit('setClosestLessons', [
      ...lessons,
      ...onlineLessons,
    ])
  },
}
