import { eventBus } from '@/pubsub/bus'
import { ApiShowcaseCart } from 'ApiRest/Api/Showcase/Cart'
import { ApiShowcaseCartCheckout } from 'ApiRest/Api/Showcase/Cart/Checkout'
import { ApiShowcaseCartConsumer } from 'ApiRest/Api/Showcase/Cart/Consumer'
import { ApiShowcaseCartProductsCount } from 'ApiRest/Api/Showcase/Cart/ProductsCount'
import { ApiShowcasePurchaseGenerateInvoices } from 'ApiRest/Api/Showcase/Purchase/GenerateInvoices'
import { pick } from 'lodash'

const globalMessageOnProductRemoved = function ({ product }) {
  eventBus.$emit('frontend.showcase.product.productRemoved', {
    product,
  })
}

export default {
  async fetchProductsCount({ commit }) {
    const { data } = await ApiShowcaseCartProductsCount.get()

    commit('setProductsCount', data)
  },

  async fetchProducts({ commit }) {
    const { data } = await ApiShowcaseCart.get()

    commit('setProducts', data)
  },

  addProduct({ rootState }) {
    const { user, offer, routeParams } = rootState.common.product

    return ApiShowcaseCart.post({
      offer: {
        id: offer.id,
      },
      consumer: {
        id: user.id,
      },
      category: {
        prettyLink: routeParams.category,
      },
    })
  },

  async removeProduct({ commit }, product) {
    const consumer = product.consumer?.id
      ? {
          id: product.consumer?.id,
        }
      : null

    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await ApiShowcaseCart.delete({
        offer: {
          id: product.offer.id,
        },
        consumer,
      })

      globalMessageOnProductRemoved({
        product,
      })

      commit('setProducts', data)
    } catch (error) {
      throw error
    }
  },

  async checkout({ getters, commit }, withMaternityCapital) {
    const { data } = await ApiShowcaseCartCheckout.post({
      items: getters.checkedProducts.map((product) => ({
        offer: {
          id: product.offer.id,
        },
        consumer: {
          id: product.consumer.id,
        },
      })),
      withMaternityCapital,
    })

    commit('setPurchase', data)
  },

  async addProductByLink(context, offerId) {
    await ApiShowcaseCart.post({
      offer: {
        id: offerId,
      },
    })
  },

  async createInvoice({ state, commit }) {
    if (state.purchase.invoices?.length > 0) {
      commit('setInvoices', state.purchase.invoices)

      return
    }

    const { data } = await ApiShowcasePurchaseGenerateInvoices.put(
      state.purchase.id,
    )

    commit('setInvoices', data)
  },

  async updateConsumer({ state, commit }, payload) {
    const index = state.products.findIndex(
      (product) => product.id === payload.id,
    )

    const productStateFields = pick(state.products[index], [
      'isAcquiring',
      'isChecked',
      'hasError',
    ])

    const { data } = await ApiShowcaseCartConsumer.patch(payload)

    commit('setProduct', {
      product: {
        ...productStateFields,
        ...data,
      },
      index,
    })
  },
}
