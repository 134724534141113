import actions from '@/store/showcase/client/invoices/actions'
import getters from '@/store/showcase/client/invoices/getters'
import mutations from '@/store/showcase/client/invoices/mutations'
import state from '@/store/showcase/client/invoices/state'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
