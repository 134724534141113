const schoolIds = process.iblsEnv.IBLS_CHAT_SCHOOL_LIST
  ? JSON.parse(process.iblsEnv.IBLS_CHAT_SCHOOL_LIST)
  : null

const isEnabledGlobally = process.iblsEnv.IBLS_CHAT_ENABLED === '1'
const isEnabledForEachSchool = schoolIds === null

export default {
  isEnabled(state, getters, rootState, rootGetters) {
    const isManager = rootGetters['common/user/isManager']
    const isClient = rootGetters['common/user/isClient']
    const isStudent = rootGetters['common/user/isStudent']

    if (!isEnabledGlobally) {
      return false
    }

    if (isEnabledForEachSchool) {
      return true
    }

    let schoolId

    switch (true) {
      case isManager:
        return true

      case isClient:
        schoolId = rootState.client.students?.school?.id
        break

      case isStudent:
        schoolId = rootState.student.profile?.profile?.school?.id
        break

      default:
    }

    if (schoolId) {
      return schoolIds.includes(schoolId)
    }

    return false
  },
}
