import { ApiCoursesPurchaseNotificationDocuments } from 'ApiRest/Api/Courses/Purchase/Notification/Documents'

export default {
  async fetchNotificationDocuments({ commit }) {
    const { data } = await ApiCoursesPurchaseNotificationDocuments.get()

    commit('setNotificationValue', {
      path: 'documents',
      value: data,
    })
  },
}
