import ApiBase from 'ApiRest/ApiBase'

export default new (class TestPreview extends ApiBase {
  /**
   * @param {number} testId
   * @returns {Promise}
   */
  get(testId) {
    return this._GET(`/testing/source-preview-tests/${testId}`)
  }
})()
