export default {
  hasBanners(state) {
    return state.banners.length > 0
  },

  hasPromoBanners(state) {
    return state.navigationBanner.banners.length > 0
  },

  promoBannersSorted(state) {
    return [
      ...state.navigationBanner.banners,
    ].sort((a, b) => b.format.id - a.format.id)
  },
}
