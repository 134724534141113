import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonSignUp = new (class extends ApiBase {
  /**
   * Записаться на онлайн-урок
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  put(onlineLessonId) {
    return this._PUT(
      `/account/student/online-lessons/${onlineLessonId}/signed-up`,
    )
  }
})()
