export default class Download {
  /**
   * @param {Object} obj
   * @param {string} obj.message
   * @param {number} obj.status
   */
  constructor({ message, status }) {
    this.message = message
    this.status = status
    this.name = 'download'
  }
}
