import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseNavigationBannerBlock = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/showcase/navigation-banner/block`)
  }
})()
