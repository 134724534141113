import ApiBase from 'ApiRest/ApiBase'
import { cloneDeep } from 'lodash'

export default new (class Student extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/account/student/profile`)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  patch(data) {
    const isDataEmpty = Object.keys(data).length === 0
    let dataNormalized = {}

    if (!isDataEmpty) {
      dataNormalized = cloneDeep(data)

      if (dataNormalized.citizenship) {
        dataNormalized.passport = ApiBase.normalizePassport(
          dataNormalized.passport,
          dataNormalized.citizenship.id,
        )
      }
    }

    return this._PATCH(
      `/account/student/profile`,
      !isDataEmpty ? dataNormalized : data,
    )
  }
})()
