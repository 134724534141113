import { ApiCoursesCartIsAvailableForStudentAccess } from 'ApiRest/Api/Courses/Product/IsAvailableForStudentAccess'

export default {
  async fetchIsAvailableForStudentOrder({ commit, rootState }) {
    const { data } = await ApiCoursesCartIsAvailableForStudentAccess.get(
      rootState.courses.common.product.productId,
    )

    commit('setIsAvailableForStudentAccess', data)
  },
}
