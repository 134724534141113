export default () => ({
  /** @type {TablePurchase[]} */
  purchases: [],

  purchaseId: null,
  purchase: null,

  customer: null,

  invoices: [],

  options: {
    citizenshipList: [],
    countryList: [],
  },
})
