import actions from '@/store/courses/common/actions'
import cart from '@/store/courses/common/cart/store'
import catalog from '@/store/courses/common/catalog/store'
import collection from '@/store/courses/common/collection/store'
import getters from '@/store/courses/common/getters'
import home from '@/store/courses/common/home/store'
import mutations from '@/store/courses/common/mutations'
import product from '@/store/courses/common/product/store'
import purchase from '@/store/courses/common/purchase/store'
import state from '@/store/courses/common/state'

export default {
  namespaced: true,

  modules: {
    cart,
    catalog,
    collection,
    home,
    product,
    purchase,
  },

  state,
  getters,
  mutations,
  actions,
}
