import { Pagination } from '@/helpers/CompositePagination'
import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/courses/common/catalog/state'
import { isArray, isBoolean, isNumber } from 'lodash'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProducts(state, payload) {
    state.products = payload
  },

  setProductsPopular(state, payload) {
    state.productsPopular = payload
  },

  setCategoryId(state, payload) {
    state.categoryId = payload
  },

  unsetCategoryId(state) {
    state.categoryId = null
  },

  setSearch(state, payload) {
    state.search = payload
  },

  unsetSearch(state) {
    state.search = ''
  },

  setTag(state, payload) {
    state.tag = payload
  },

  unsetTag(state) {
    state.tag = ''
  },

  setSort(state, payload) {
    state.sort.value = payload
  },

  unsetSort(state) {
    state.sort.value = null
  },

  updatePagination(state, payload) {
    state.pagination.updateFromHeaders(payload)
  },

  setPage(state, page) {
    state.pagination.setPage(page)
  },

  setRowsPerPage(state, payload) {
    state.pagination.setRowsPerPage(payload)
  },

  setFilters(state, payload) {
    state.filters = payload
  },

  setFilterValue(state, payload) {
    const { code, value } = payload
    const filter = state.filters.find((f) => f.code === code)

    if (filter) {
      if (filter.options) {
        if (filter.multiple) {
          if (isArray(value) && value.every(isNumber)) {
            const optionValues = filter.options.filter((option) =>
              value.includes(option.id),
            )

            filter.value = optionValues
          }
        } else {
          if (isNumber(value)) {
            const optionValue =
              filter.options.find((option) => value === option.id) ?? null

            filter.value = optionValue
          }
        }
      } else {
        if (isBoolean(value)) {
          filter.value = value
        }
      }
    }
  },

  resetFilterOptionsDisplayedByCode(state) {
    state.filtersOptionsFullyDisplayedByCode = {}
  },

  toggleOptionFilterValue(state, payload) {
    const { filter, option } = payload

    if (filter.value.includes(option)) {
      const value = filter.value.filter((o) => o !== option)

      filter.value = value
    } else {
      const value = [
        ...filter.value,
        option,
      ]

      filter.value = value
    }
  },

  toggleBooleanFilterValue(state, payload) {
    const { filter } = payload

    filter.value = !filter.value
  },

  toggleDisplayedOptionsCount(state, payload) {
    const value = Boolean(
      state.filtersOptionsFullyDisplayedByCode[payload.code],
    )

    state.filtersOptionsFullyDisplayedByCode = {
      ...state.filtersOptionsFullyDisplayedByCode,
      [payload.code]: !value,
    }
  },

  setMobilePagination(state) {
    state.pagination = new Pagination({
      rowsPerPage: 6,
      rowsPerPageOptions: [
        6,
        12,
        18,
      ],
    })
  },
}
