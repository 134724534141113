import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcasePurchaseMarkWithMaternityCapital =
  new (class extends ApiBase {
    /**
     * Оплата маткапиталом
     * @param {number} purchaseId
     * @returns {Promise}
     */
    put(purchaseId) {
      const url = `/purchase/${purchaseId}/mark-with-maternity-capital`

      return this._PUT(url)
    }
  })()
