import Constants from 'Constants'

// TODO: i18n
export const studentFillDataTypes = [
  {
    id: Constants.studentFillDataTypes.STUDENT_NEW,
    name: 'Новый ученик',
  },
  {
    id: Constants.studentFillDataTypes.STUDENT_EXISTING,
    name: 'Выбрать существующего',
  },
]

export const studentFillDataTypeInitial = studentFillDataTypes[0]
